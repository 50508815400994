//import {base, html, log} from '@mesh./m.helper';
//import html from '../@mesh./m.common/source/m.html.mjs';
// import base from '../@mesh./m.common/source/base.mjs';

class MeshClass {

    constructor() {

        this.start();
        this.class = m.schema.getClass(this);

        m.log.recognize.report("meshClass:", this);

        this.schema = {
            class: true
        }

    }

    toJson() {
        let outElement = m.schema.objectToJsonMapping(this);
        return outElement;
    }

    toMarkup() {
        let outElement = m.html.toDomElement("",this.class);
        return outElement;
    }

    start() {
        this.start = Date.now();
    }

    end() {
        this.end = Date.now();
        this.duration = (this.end - this.start) / 1000;
        m.log.recognize.report(this.duration + " seconds to construct the " + this.class, this, "Process", this.start);
    }

};

//module.exports = MeshClass;
export default MeshClass

//import { pathToFileURL } from "url";
import { jsonpatch, JSONPointer } from "jsonpatch";
// if ('function' === typeof require) {
//   const jsonpatch = require('../lib/jsonpatch');
// }

export default class Bridge {
  constructor() {
    this.counter = 0; // global sequence
    this.source = mesh.env.channel;
    mesh.queries = mesh.queries || {};

    switch (mesh.env.channel) {
      case "main":
        this.defaultTarget = "lab";
        break;
      case "lab":
        this.defaultTarget = "main";
        break;
      case "host":
        this.defaultTarget = "main";
        break;
    }
    mesh.hub = this.hub
    mesh.cast = this.cast

    mesh.pub("onBridging");
  }

  cast(name, results, target, actName) {
    let eventName = actName || name.toEventName()
    let destinations = target ? [target] : mesh.env.receiver;
    mesh.pub(eventName, results)
    destinations.forEach(destination => {      
      mesh.pub(eventName, results, destination).then(() => mesh.log.exchange.success(`${mesh.env.channel} -> mesh.pub@${destination} '${eventName}'`))
    })
  }

  hub(name, target, actName) {
    let eventName = actName || name.toEventName()
    let destinations = target ? [target] : mesh.env.receiver;
    mesh.sub(eventName).then(result => {
      destinations.forEach(destination => mesh.pub(eventName, result, destination))
    });
  }

  setup(target, listener = g, poster = g) {
    mesh.bridge.listen(target, listener);
    mesh.bridge.post(target, poster);
  }

  listen(forTarget, listener = g) {
    // mesh.bridge.listen(mesh.env.listen[target], target);
    listener.addEventListener("message", mesh.bridge.receive, false);
    mesh.env.listen[forTarget] = listener; //g.frames.mesh;
  }

  post(toTarget, poster = g) {
    mesh.env.post[toTarget] = poster; //g.document.getElementById("app").contentWindow
  }

  check(target, id) {
    let phase = "listen";
    let source = mesh.env.channel;
    let sourceCap = source.capitalize();
    let targetCap = target.capitalize();
    let phaseCap = phase.capitalize();
    id = id || mesh.id.getRandomCode();

    return mesh.bridge
      .triggerEvent("from" + sourceCap + "To" + targetCap + phaseCap, target, id)
      .then((message) => {
        mesh.log.bridge.success("[" + id + "] bridge.check -> '" + message.source.channel + "' to '" + message.target.channel + "'");
        return message;
      })
      .catch((err) => {
        debugger;
        mesh.log.bridge.error("[" + id + "] bridge.check -> Error: ", err);
      });
  }

  signal(target = "host", phase = "ready", id) {
    let source = mesh.env.channel;
    let sourceCap = source.capitalize();
    let targetCap = target.capitalize();
    let phaseCap = phase.capitalize();
    id = id || mesh.id.getRandomCode();
    let eveString = "from" + sourceCap + phaseCap;
    let backString = "on" + targetCap + phaseCap;
    mesh.log.stage.trigger("[" + id + "] bridge.signal -> '" + eveString + "' @" + target)
    return mesh.bridge
      .triggerEvent(eveString, target, id)
      .then((message) => {
        mesh.log.bridge.success("[" + id + "] bridge.signal.callback -> '" + message.source.channel + "' to '" + message.target.channel + "'");
        mesh.log.stage.trigger("[" + id + "] bridge.signal.callback -> '" + backString + "' @" + mesh.env.channel)
        mesh.pub(backString);
        return message;
      })
      .catch((err) => {
        debugger;
        mesh.log.bridge.error("[" + id + "] bridge.signal -> Error: ", err);
      });
  }

  send(json, target = mesh.bridge.defaultTarget, id) {
    function prom(resolve, reject) {
      try {
        //let isResponse = id ? true : false;
        //json.isResponse = isResponse;

        json.mesh = true;
        // json.target.channel = target;
        json.id = id || mesh.id.getRandomCode();
        json.requestTimestamp = Date.now();
        json.source = { channel: mesh.env.channel };
        json.target = { channel: target };
        if (mesh.env.doc) json.source.href = mesh.env.doc.location.href;
        //const message = JSON.serialize(json);
        //if (!isResponse) return this.request(json, mesh.env[target]);
        //if (isResponse) return this.response(message, mesh.env[target]);

        let targetListen = mesh.env.listen[json.target.channel]; //? mesh.env.listen[json.target.channel] : g;
        let targetPost = mesh.env.post[json.target.channel]; //? mesh.env.listen[json.target.channel] : g;
        let sourceListen = mesh.env.listen[json.source.channel]; //? mesh.env.post[json.source.channel] : g;
        let sourcePost = mesh.env.post[json.source.channel]; //? mesh.env.post[json.source.channel] : g;

        if (targetPost && targetListen) {
          let seq = ++mesh.bridge.counter;

          function resolveMessage(e) {
            let eString = mesh.bridge.eString(e)
            try {
              if (e && typeof e !== "undefined" && typeof e.ModalNotReady === "undefined") {
                if (e.data && typeof e.data !== "undefined") {
                  if (e.data.id && e.data.mesh) {
                    if (e.data.id == json.id) {
                      //console.group("resolve: " + e.data.target.channel);
                      if (e.data.source.channel === mesh.env.channel) {
                        //if (e.data.type === "query") debugger;
                        if (e.data.response === true) {
                          targetListen.removeEventListener("message", eventRef);
                          // if (e.data.source.channel === "host") debugger
                          mesh.log.bridge.reponse(eString + " [" + e.data.id + "] bridge.send.resolveMessage ->  from '" + e.data.source.channel + "' to '" + e.data.target.channel + "': ", e);
                          //console.groupEnd();
                          resolve(e.data);
                        }
                      }
                    }
                  }
                }
              }
            } catch (err) {
              mesh.bridge.logError(err, e, " bridge.send.resolveMessage")
              reject(err);
            }
            //console.groupEnd();
          }

          let eventRef = targetListen.addEventListener("message", resolveMessage, false);

          let text = { ...json, counter: seq };
          try {
            if (json.source.channel === "lab" || json.target.channel === "lab") {
              targetPost.postMessage(text); //text, "*"   // {type: text, action: {}}
            } else {
              // if (json.target.channel === "host") debugger
              targetPost.postMessage(text, "*");
            }
          } catch (err) {
            mesh.bridge.logError(err, e, " bridge.send.postMessage")
            reject(err);
          }
          mesh.log.bridge.wait("[" + json.id + "] bridge.send.postMessage -> from '" + json.source.channel + "' to '" + json.target.channel + "': ", json);
        }
      } catch (err) {
        mesh.log.bridge.error("bridge.send.prom -> Error: ", err);
        reject(err);
      }
    }

    return new Promise(prom);
  }

  sendQuery(path, target, id) {
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    console.group("[" + json.id + "] sendQuery");
    json.type = "query";
    json.path = path;
    //let id = mesh.id.getHash(path);
    //mesh.queries[id] = callback;
    mesh.log.bridge.trigger("[" + id + "] bridge.sendQuery -> " + path);
    let res = mesh.bridge.send(json, target, id);
    console.groupEnd();
    return res;
  }

  state(key, value, target, id) {
    //console.group(path);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "state";
    json.key = key;
    json.value = value;
    mesh.log.bridge.trigger("[" + id + "] bridge.state -> " + key);
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  triggerEvent(eventName, target, data = {}, id) {
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    //console.group("[" + json.id + "] triggerEvent");
    json.type = "triggerEvent";
    json.event = eventName;
    json.data = data;
    mesh.log.bridge.trigger("[" + json.id + "] bridge.triggerEvent -> " + eventName);
    let res = mesh.bridge.send(json, target, id);
    //console.groupEnd();
    return res;
  }

  handover(data, name, target, id) {
    //console.group(path);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "handover";
    json.name = name;
    json.data = data;
    mesh.log.bridge.trigger("[" + id + "] bridge.handover -> " + name);
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendReplace(data, path, target, id) {
    //console.group(path);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "replace";
    json.path = path;
    json.data = data;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendMerge(data, path, target, id) {
    //console.group(path);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "merge";
    json.path = path;
    json.data = data;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendInfo(text, reason = "message", target, id) {
    //console.group(text);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "info";
    json.reason = reason;
    json.text = text;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendLog(text, reason = "message", target, id) {
    //console.group(text);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "log";
    json.reason = reason;
    json.text = text;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendEval(jsString, target, id) {
    //console.group(jsString);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "eval";
    json.js = jsString;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendQuery(path, target, id) {
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    //console.group("[" + json.id + "] sendQuery");
    json.type = "query";
    json.path = path;
    mesh.log.bridge.trigger("[" + json.id + "] bridge.sendQuery: " + path);
    let res = mesh.bridge.send(json, target, id);
    //console.groupEnd();
    return res;
  }

  sendCall(methodName, params, target, id) {
    //console.group(methodName);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "expect";
    json.method = methodName;
    json.causes = causes;
    json.params = params;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  sendExpect(methodName, causes, params, target, id) {
    //console.group(methodName);
    let json = {};
    json.id = id || mesh.id.getRandomCode();
    json.type = "expect";
    json.method = methodName;
    json.causes = causes;
    json.params = params;
    let res = mesh.bridge.send(json, target, id);
    return res;
  }

  logWarn(e, message) {
    try {
      let eString = mesh.bridge.eString(e)
      let addressing =  e?.data?.target && e?.data?.source ? {target:   e.data.target.channel  , source: e.data.source.channel , actual:  mesh.env.channel } : {};
      let groupTitle = e.data && e.data.id ? "[" + e.data.id + "]" : "";
      groupTitle += eString + " " + message;
      let indent = "     "
      //console.group(groupTitle);
      mesh.log.bridge.warn(indent + groupTitle);
      mesh.log.bridge.warn(indent + "- Addressing: ", addressing);
      mesh.log.bridge.warn(indent + "- Event: ", e);
      if (e.data) mesh.log.bridge.warn(indent + "- Data: ", e.data);
      //console.groupEnd();
      // let result = { error: err };
      // mesh.bridge.respond(result, e);
    } catch (error) {
      debugger;
      console.groupEnd();
    }
  }

  logError(err, e, message) {
    try {
      let eString = mesh.bridge.eString(e)
      let addressing =  e?.data?.target && e?.data?.source ? {target:   e.data.target.channel  , source: e.data.source.channel , actual:  mesh.env.channel } : {};
      let groupTitle = e.data && e.data.id ? " [" + e.data.id + "]" : "";
      groupTitle += eString + " " + message;
      console.group(groupTitle);
      mesh.log.bridge.error("Error: ", err);
      mesh.log.bridge.report("Event: ", e);
      mesh.log.bridge.error("Addressing: ", addressing);
      if (e.data) mesh.log.bridge.report("Data: ", e.data);
      console.groupEnd();
      // let result = { error: err };
      debugger;
      // mesh.bridge.respond(result, e);
    } catch (error) {
      debugger;
      console.groupEnd();
    }
  }

  eString(e) {
    let originString = "origin: '" + e.origin + "'"; 
    let addressing =  e?.data?.target && e?.data?.source ? {target:   e.data.target.channel  , source: e.data.source.channel , actual:  mesh.env.channel } : {};
    let addressString =  e?.data?.target && e?.data?.source ? ", target: '" + e.data.target.channel + "', source: '" + e.data.source.channel + "'" + "', actual: '" + mesh.env.channel + "'" : "";  
    let eString = "{" + originString + addressString +"}";  
    return eString //{eString: eString, addressing: addressing};
  }

  receive(e) { 
    
    let eString = mesh.bridge.eString(e)

    if (e && typeof e !== "undefined" && !e.ModalNotReady) {
      if (e.data && typeof e.data !== "undefined" && e.data.counter && e.data.mesh) {
        function respond(result, resp) {
          let json = { ...resp.data };
          json.response = true;
          json.result = result;
          json.target = { channel: mesh.env.channel };
          json.responseTimestamp = Date.now();
          if (mesh.env.doc) json.target.href = mesh.env.doc.location.href;
          // let res = mesh.bridge.send(json, target, id);

          mesh.log.bridge.reponse(eString + " [" + e.data.id + "] bridge.receive.respond -> '" + json.type + "' respond: ", json.result);

          let text = { ...json };
          // debugger
          let sender = resp.source || resp.srcElement
          if (json.source.channel === "lab" || json.target.channel === "lab") {
            sender.postMessage(text); //text, "*"   // {type: text, action: {}}
          } else {
            //if (json.source.channel === "host") debugger
            sender.postMessage(text, "*");
          }
        }

        if (!e.data.response) {
          if (e.data.target.channel === mesh.env.channel) {
            try {
              const message = e.data; //=== typeof "string" ? message : JSON.serialize(e.data);
              const json = message; //JSON.parse(message);
              let result = { success: true };
              let method = () => {};

              switch (json.type) {
                case "query":
                  result = new JSONPointer(json.path).get(g.m);
                  break;
                case "state":
                  if (json.key) {
                    if ( mesh.store.status)  mesh.store.status[json.key] = json.value;
                  }
                  break;
                case "handover":
                  if (json.data) {
                    if (!g.m.handover) g.m.handover = {};
                    let channel = message.source.channel;
                    g.m.handover[channel] = g.m.handover[channel] ? g.m.handover[channel] : {};
                    let handoverTarget = g.m.handover[channel];
                    handoverTarget[json.name] = json.data;
                  }
                  break;
                case "replace":
                  if (json.data) {
                    let patch = [{ op: "replace", path: json.path, value: json.data }];
                    g.m = jsonpatch.apply_patch(g.m, patch);
                    result = new JSONPointer(json.path).get(g.m);
                  }
                  break;
                case "merge":
                  if (json.data) g.m = new JSONPointer(json.path).add(g.m, json.data);
                  result = new JSONPointer(json.path).get(g.m);
                  break;
                case "expect":
                  method = mesh.methods[json.method];
                  // expect(fn, causes = [], shortName = null, message = null, finalStageName = null, runAsWorker = false)
                  if (method) mesh.expect(method, json.causes, null, null, null, null, json.params);
                  break;
                case "eval":
                  if (json.js) result = mesh.eval(json.js);
                  break;
                case "call":
                  method = mesh.methods[json.method];
                  result = method(json.params);
                  break;
                case "return":
                  result = mesh.eval(json.js);
                  break;
                case "triggerEvent":
                  if (json.data) {
                    if (!g.m.handover) g.m.handover = {};
                    let channel = message.source.channel;
                    g.m.handover[channel] = g.m.handover[channel] ? g.m.handover[channel] : {};
                    let handoverTarget = g.m.handover[channel];
                    handoverTarget[json.name] = json.data;
                    if (json.event) mesh.pub(json.event, json.data);
                  } else {
                    if (json.event) mesh.pub(json.event);
                  }
                  //if (json.event) mesh.pool(json.event, ["onStart"]);
                  break;
                case "info":
                  if (json.text) mesh.info[json.reason](json.text);
                  break;
                case "log":
                  if (json.text) mesh.log[json.reason](json.text);
                  break;
                default:
                  mesh.log.bridge.warn(eString + "[" + e.data.id + "]  bridge.receive -> no type recognized: ", json);
              }
              //const resultJson= JSON.parse(JSON.serialize(result));
              if (result) {
                respond(result, e);
              } else {
                mesh.log.bridge.warn(eString + " [" + e.data.id + "] bridge.receive -> no result");
                // let result = { warning: 'no result' };
                debugger;
                // mesh.bridge.respond(result, e);
              }
            } catch (err) {
              mesh.bridge.logError(err, e, "bridge.receive -> error during type processing")
            }
          } else {
            if (e.data.source.channel !== mesh.env.channel) {
              mesh.bridge.logWarn(e, "bridge.receive -> source does not match the actual channel: '" + e.data.source.channel + "' !== '" + mesh.env.channel + "'");
            } else {
              mesh.bridge.logWarn(e, "bridge.receive -> target does not match the actual channel: '" + e.data.target.channel + "' !== '" + mesh.env.channel + "'");
            } 
          }
        } else {
          if (e.data.source.channel !== mesh.env.channel) {
            mesh.bridge.logWarn(e, "bridge.receive -> is response and source does not match the actual channel: '" + e.data.source.channel + "' !== '" + mesh.env.channel + "'");
          } else if (e.data.target.channel !== mesh.env.channel) {
            //m.bridge.logWarn(e, "bridge.receive -> is response and target does not match the actual channel: '" + e.data.target.channel + "' !== '" + mesh.env.channel + "'");
          } else {
            debugger
          }
        }
      } else {
        mesh.bridge.logWarn(e, "bridge.receive -> source is not mesh");
        debugger;
      }
    } else {
      mesh.bridge.logWarn(e, "bridge.receive -> unknown source");
      debugger;
    }
  }
}

import { adjacencyMatrix } from "../logic/similarity";

// let renderMatrix = function (width, origvalues, { title, format, each, isHighlighted, background, hover, cols, rows } = {}) {
//   values = Array.from(origvalues); //Object.values(JSON.parse(JSON.serialize(values))) //
//   const color = d3.scaleSequential(d3.interpolateBlues).domain([-0.3, 1] || [0, 1.3]);
//   format = format || d3.format(".1f");
//   let div = `<div class=annotated-matrix >
//         ${title ? `<div class=matrix-title>${title}</div>` : ""}
//         ${
//           cols
//             ? `<div class=matrix-header>
//         ${rows ? "<span class=matrix-cell>&nbsp;</span>" : ""}
//         ${cols.map((c) => (c ? `<span class=matrix-head>${c}</span>` : "")).join("")}
//         </div>`
//             : ""
//         }
//         ${rows ? `<div class=matrix-gutter>${rows.map((r) => `<span class=matrix-cell>${r}</span><br>`).join("")}</div>` : ""}
//         <div class=matrix>
//         ${values.reduce((total, v, i) => {
//           total === 0 ? "" : total;
//           total += `<span class=matrix-cell>${v > 0 ? format(v) : ""}</span>${i % width === width - 1 ? "<br>   " : ""}`;
//           return total;
//         })}
//         </div>
//     </div>`;

//   div = div.toDOM();

//   d3.select(div).select(".matrix").selectAll(".matrix-cell").data(values).style("background", apply_dijw(background, width));
//   //.on('mouseover', apply_dijw(hover, width))
//   //.each(apply_dijw(each, width))

//   return div;
// };

// let renderMatrix2 = function (width, values, { title, format, each, background, hover } = {}) {
//   values = Array.from(values);
//   const color = d3.scaleSequential(d3.interpolateBlues).domain([-0.3, 1] || [0, 1.3]);
//   format = format || d3.format(".1f");
//   const div = `<div class=annotated-matrix >
//         <div class=matrix-title>${title}</div>
//         <div class=matrix>
//         ${values.map((v, i) => `<span class=matrix-cell>${format(v)}</span>${i % width === width - 1 ? "<br>" : ""}`)}
//         </div>
//     </div>`.toDOM();

//   d3.select(div).selectAll(".matrix-cell").data(values).style("background", apply_dijw(background, width)).on("mouseover", apply_dijw(hover, width)).each(apply_dijw(each, width));

//   return div;

// };

// Define the renderHeatmap function
let renderHeatmap = (matrix, settings) => {
	// Create the matrix layout with specified size, minus margins for padding
	const matrixLayout = adjacencyMatrix().size([10, 10]);

	// Generate data for the heatmap based on input matrix and settings
	const data = matrixLayout(matrix.columnLabels, matrix.rows || matrix.rowArray(), matrix.rowLabels);

	// Set the domain for the color scale based on the data values
	settings.color.domain([0, d3.max(data, (d) => d.value)]);

	// Create the SVG element
	const svg = d3.create("svg");

	// Append a group element to the SVG and transform it based on the margin
	const graphChart = svg.append("g").attr("transform", `translate(${settings.margin / 2 + 120}, ${settings.margin / 2})`);

	// Append another group element for the heatmap cells
	const innerChart = graphChart.append("g").attr("class", "innerChart");

	let colorHold = mesh.input.settings.colors.heat;
	let colorLText = mesh.input.settings.classifierTexts;

	let invertcolors = 0;

	// Inverting color scale
	if (invertcolors) {
		colorHold.reverse();
	}

	// tooltip
	let tooltip = drawTooltip(matrix.id);

	let colorScale = d3.scaleOrdinal().domain([-3, -2, -1, 1, 2, 3]).range(colorHold);

	const cell = innerChart
		.selectAll("g.cell")
		.data(data)
		.join("g")
		.attr("class", "cell")
		.attr("transform", (d) => `translate(${d.x}, ${d.y})`);

	// Append rectangles to each cell, styled based on the data, only if value meets condition
	cell
		.filter((d) => d.value > 0)
		.append("rect")
		.attr("height", (d) => d.h * 0.95)
		.attr("width", (d) => d.w * 0.95)
		.attr("rx", (d) => d.w / 4) // Rounded corners
		.attr("ry", (d) => d.h / 4)
		.style("fill", (d) => {
			let color = d.normalizedClassifier + 3; //colorScale.domain().indexOf(d.normalizedClassifier) + 3;
			return d.isTop ? mesh.input.settings.colors.heat[color] : mesh.input.settings.colors.cold[color];
		})
		.on("mouseover", function (d) {
			var lPatchWidth = 200;
			d3.select(this).style("stroke", "orange").style("stroke-width", "3px");
			d3.select(".trianglepointer")
				.transition()
				.delay(100)
				.attr("transform", "translate(" + -(lPatchWidth / colorScale.range().length / 2 + colorScale.domain().indexOf(d.topClassifier) * (lPatchWidth / colorScale.range().length)) + ",0)");
			d3.select(".LegText").select("text").text(colorLText[colorScale.domain().indexOf(d.normalizedClassifier)]);
		})
		.on("click", function (d) {
			//openSource(d);
		})
		.on("mouseout", function () {
			d3.select(this).style("stroke", "none");
			tooltip.style("visibility", "hidden");
		})
		.on("mousemove", function (d) {
			tooltip
				.style("visibility", "visible")
				.style("top", d.pageY - 240 + "px")
				.style("left", d.pageX + 40 + "px");
			let tooltipText = getTooltipText(d.srcElement.__data__, matrix);
			tooltip.select("div").html(tooltipText);
		});

	// Find the maximum position from the heatmap data to place column labels below all rows
	const maxY = d3.max(data, (d) => d.y + d.h);
	const maxX = d3.max(data, (d) => d.x + d.w);

	// Append text for the matrix headers (row and column labels)
	graphChart
		.append("g")
		.attr("class", "matrix-head")
		.selectAll("text.source")
		.data(data.filter((d) => d.x == 0))
		.enter()
		.append("text")
		.attr("class", "matrix-gutter")
		.attr("y", (d) => d.y + d.h / 2)
		.attr("x", 0 - settings.margin / 2)
		.text((d, i) => matrix.rowLabels[i].id)
		.attr("text-anchor", "end");

	graphChart
		.append("g")
		.attr("class", "matrix-head")
		.selectAll("text.target")
		.data(data.filter((d) => d.y == 0))
		.enter()
		.append("text")
		.attr("class", "matrix-header")
		.attr("x", (d) => d.x + d.w / 2)
		.attr("y", maxY - settings.margin / 4 + 15) // Place labels below all rows by using maxY + some margin
		//.attr("y", 0 - settings.margin / 4 + 40)
		.text((d, i) => matrix.columnLabels[i].id)
		.attr("text-anchor", "end")
		.attr("transform", (d) => `rotate(-55, ${d.x + d.w / 2}, ${maxY - settings.margin / 4 + 15})`);
	//.attr('transform', (d) => `rotate(-55, ${d.x + d.w / 2}, ${0 - settings.margin / 4 + 40})`);
	//.attr('transform', `rotate(0)`);

	// Update SVG height to accommodate new label position
	svg.attr("height", maxY + 40 + settings.margin); // Include space for labels and some extra margin

	// Dynamically adjust the size of the SVG based on content
	const bounds = graphChart.node().getBBox();
	let w = maxX + 120 + settings.margin;
	let h = maxY + 100 + settings.margin;
	svg.attr("width", w).attr("height", h);

	let result = { svg: svg.node(), w: w, h: h };

	// Return the SVG node for embedding or further manipulation
	return result;
};

// let matVis2 = (matrix, entities, columnLabel) => {
//   return Object.assign(
//     renderMatrix(matrix.ncol, matrix.vals, {
//       cols: entities.concat(columnLabel).map(({ id }) => id),
//       rows: entities.concat(columnLabel).map(({ id }) => id),
//       background: background(val),
//       each: highlightEach(d, i, j, matrix, entities), //this
//     }),
//     { value: matrix }
//   );
// };

// // renderMatrix
// // (width, values, { title, format, each, isHighlighted, background, hover, cols, rows } = {})
// // (width, values, { title, format, each, background, hover } = {})
// let renderedMatrix = (mat0, entities, columnLabel) => {
//   return `<div style='display:inline-block; width:39%; vertical-align:top;' >${renderMatrix(mat0.ncol, mat0.vals, {
//     cols: columnLabel.map(({ id }) => id),
//     rows: entities.map(({ id }) => id),
//     background: background(val),
//   })}</div>`.toDOM();
// };

// let matMultVis = function ({ A, B, C }) {
//   const color = d3.scaleSequential(d3.interpolateWarm).domain([0, A.ncol]);
//   const gradient = function (c) {
//     const c0 = d3.lab(c).brighter(2).toString();
//     const c1 = d3.lab(c).darker(0.5).toString();
//     return `radial-gradient(closest-side circle at center, ${c0} 20%, ${c1} 200%)`;
//   };
//   return `
//         ${renderMatrix(A.ncol, A.vals, {
//           title: tex`\bm{A}_{${A.nrow}×${A.ncol}}`,
//           background: (d, i, j) => (i === inspect[0] ? gradient(color(j)) : ""),
//         })}
//         <span class=operator>${tex`×`}</span>
//         ${renderMatrix(B.ncol, B.vals, {
//           title: tex`\bm{B}_{${B.nrow}×${B.ncol}}`,
//           background: (d, i, j) => (j === inspect[1] ? gradient(color(i)) : ""),
//         })}
//         <span class=operator>${tex`=`}</span>
//         ${renderMatrix(C.ncol, C.vals, {
//           title: tex`\bm{C}_{${C.nrow}×${C.ncol}}`,
//           background: (d, i, j) => (i === inspect[0] && j === inspect[1] ? gradient("#ccc") : ""),
//           hover: (d, i, j) => (i !== inspect[0] || j !== inspect[1] ? (inspect = [i, j]) : null),
//         })}
//         <div class='equation' >${kSumEq(A.ncol, inspect[0], inspect[1])}</div>
//         <div class='equation' >${abSumEq(A.ncol, inspect[0], inspect[1])}</div>
//         <div class='equation' >${valSumEq(A.ncol, inspect[0], inspect[1], A, B, C)}</div>
//     `;
// };

let highlight = (d) => {
	d = d.target.__data__;
	d3.selectAll(".cell")
		.filter((k) => !(k.x == d.x || k.y == d.y))
		.style("opacity", 0.3);
	d3.selectAll(".cell")
		.filter((k) => k.x == d.x || k.y == d.y)
		.style("stroke", "black")
		.style("stroke-width", 1);

	d3.select(".tooltip")
		.select("text")
		.attr("x", d.w * 0.625)
		.attr("y", d.h * 0.625)
		.text(d.value ? d.value : 0);

	d3.select(".tooltip")
		.attr("transform", `translate(${[d.x - d.w / 8, d.y - d.h / 8]})`)
		.style("opacity", 1)

		.select("rect")
		.style("stroke-width", 3)
		.attr("rx", d.w / 8)
		.attr("ry", d.h / 8)
		.style("fill", d.value ? settings.color(d.value) : "white")
		.attr("width", 1.25 * d.w)
		.attr("height", 1.25 * d.w);
};

let fade = (d) => {
	d3.selectAll(".cell, text.source, text.target").style("opacity", 1).style("font-weight", "normal").style("font-size", "100%").style("stroke-width", 0);
	d3.select(".tooltip").style("opacity", 0);
};

let background = (val) => {
	return val === 0 ? "transparent" : d3.interpolateBlues(val * 0.66);
};

let highlightEach = (d, i, j, cell, entities) => {
	d3.select(cell)
		.classed("border-bottom-cell", i === entities.length - 1)
		.classed("border-right-cell", j === entities.length - 1);
};

function drag2(simulation) {
	function dragstarted(event) {
		if (!event.active) simulation.alphaTarget(0.3).restart();
		event.subject.fx = event.subject.x;
		event.subject.fy = event.subject.y;
	}

	function dragged(event) {
		event.subject.fx = event.x;
		event.subject.fy = event.y;
	}

	function dragended(event) {
		if (!event.active) simulation.alphaTarget(0);
		event.subject.fx = null;
		event.subject.fy = null;
	}

	return d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended);
}

export function getAxisTooltipText(d, total, mean, totalMentions) {
	let tooltipText = "";

	tooltipText = "<strong>" + d.title + "</strong><br/> ";
	tooltipText += d.description ? "<small>" + d.description + "</small><br/><br/> " : "";
	tooltipText += d.excerpt ? "<small>" + d.excerpt + "</small><br/><br/> " : "";
	//let significance = !d.significance ? numeral(d.value / 1000).format('0') + " significance score" : numeral(d.value).format('0') + "
	//let significance = numeral(d.share).format('0.00') + " significance score"  + "<br/> ";
	//if (significance !== "0.00 significance score") tooltipText += significance;
	if (d.value && d.value > 0) tooltipText += "value: " + numeral(d.value).format("0,0a") + "<br/> "; //+ " - total "+ numeral(d.total).format('0,0a')
	if (d.bandClassifier !== -3 && d.cagr) tooltipText += "<br/> " + "cagr: " + numeral(d.cagr).format("0.00");
	//tooltipText += "<br/><br/> The significance score ";
	//tooltipText += d.significance ? "significance: " + numeral(d.significance).format('0') + " score<br/>  " : "";
	if (d.bandClassifier !== -3 && d.mentions) tooltipText += d.significance ? "mentions: " + numeral(d.mentions).format("0,0a") + " - total " + numeral(d.total).format("0,0a") + "<br/>  " : "";
	if (d.bandClassifier !== -3 && total) tooltipText += "total: " + numeral(total).format("0,000a") + " - "; //" - total "+ numeral(d.total).format('0,0a')
	if (d.bandClassifier !== -3 && mean) tooltipText += "mean: " + numeral(mean).format("0,000a") + "<br/> ";
	if (d.bandClassifier !== -3 && d.perChange) tooltipText += "per change: " + numeral(d.perChange).format("0.00") + " - class: " + d.bandClassifier + "<br/> ";
	if (d.bandClassifier !== -3 && d.perTotal) tooltipText += "per total: " + numeral(d.perTotal).format("0.00 %") + "<br/> ";
	//if (d) tooltipText +=  "data: " + JSON.stringify(d).replace(',', ',<br/> ')  + "<br/> ";
	tooltipText += "<br/>query: " + d.query + "<br/> ";
	//tooltipText +=  "data: <a href='#' onclick='copyToClipboard(textCSV)'>Download CSV</a><br/>";

	return tooltipText;
}

export function drawTooltip(matrixId) {
	let width = mesh.input.settings?.tooltip.width || 350;
	let height = mesh.input.settings?.tooltip.height || 400;

	let tooltip = d3
		.select("body")
		.append("div")
		.style("width", width + "px")
		.style("height", height + "px")
		.style("background", "#000000")
		.style("color", "#ffffff")
		.style("opacity", "0.9")
		.style("position", "absolute")
		.style("visibility", "hidden")
		.style("box-shadow", "0px 0px 4px 4px #000000")
		.style("padding", "10px")
		.attr("rx", width / 4 + "px") // Rounded corners
		.attr("ry", height / 4 + "px")
		.attr("id", function (d, i) {
			return "tooltip" + matrixId;
		});
	let toolval = tooltip.append("div");

	return tooltip;
}

export function getTooltipText(d, matrix) {
	let tooltipText = "";
	let short = mesh.input.settings?.tooltip.short || false;
	let minClass = -5;
	let topAggregation = d.isTop ? matrix.topAggregation : matrix.nonTopAggregation;
	let aggregation = matrix.aggregation;
	let normalizedAggregation = d.isTop ? matrix.topAggregation : matrix.nonTopAggregation;
	let classifier = d.normalizedClassifier + 3;
	let classifierTexts = (d.isTop ? "Top " + (mesh.input.settings.top || 20) + ": " : "") + classifier + " " + mesh.input.settings.classifierTexts[classifier];

	if (!short && classifier) tooltipText += "<small>" + classifierTexts + "</small><br/> ";

	if (d.cluster) tooltipText += "<small>" + (d.cluster ? d.cluster : "") + "</small><br/> ";
	if (d.number) tooltipText += "<strong>" + numeral(d.number).format("0") + ". </strong>";
	if (d.title) tooltipText += "<strong>" + d.title + "</strong><br/> <br/> ";

	if (d.description) tooltipText += "<small>" + d.description + "</small><br/><br/> ";

	if (d.value && d.value > 0) tooltipText += "value: " + numeral(d.value).format("0.00") + "<br/><br/> "; //.format('0,0a')
	if (d.normalizedValue && d.normalizedValue > 0) tooltipText += " (normalized: " + numeral(d.normalizedValue).format("0.00") + ")<br/> "; //.format('0,0a')

	if (!short && d.topClassifier >= minClass && aggregation.total) tooltipText += "total: " + numeral(aggregation.total).format("0.00") + " - "; //.format('0,000a')
	if (!short && d.topClassifier >= minClass && d.perTotal) tooltipText += "per total: " + numeral(d.perTotal).format("0.00 %") + "<br/> ";

	if (!short && d.topClassifier >= minClass && aggregation.mean) tooltipText += "mean: " + numeral(aggregation.mean).format("0.00") + " - "; //.format('0,000a')
	if (!short && d.topClassifier >= minClass && d.perChange) tooltipText += "per change: " + numeral(d.perChange).format("0.00 %") + "<br/> ";

	if (!short && d.topClassifier >= minClass && topAggregation.mean) tooltipText += "top mean: " + numeral(topAggregation.mean).format("0.00") + " - "; //.format('0,000a')
	if (!short && d.topClassifier >= minClass && d.perChange) tooltipText += "per change: " + numeral(d.perChange).format("0.00 %") + "<br/> ";

	if (!short && d.topClassifier >= minClass && normalizedAggregation.mean) tooltipText += "aggregated mean: " + numeral(normalizedAggregation.mean).format("0.00") + " - "; //.format('0,000a')
	if (!short && d.topClassifier >= minClass && d.perChange) tooltipText += "per change: " + numeral(d.perChange).format("0.00 %") + "<br/> ";

	if (!short && d.topClassifier >= minClass && d.boost && d.boost !== 1) tooltipText += "boost: " + numeral(d.boost).format("0.00") + "<br/> ";

	//if (!short) tooltipText += "<br/>query: " + d.query + "<br/> ";

	return tooltipText;
}

export { renderHeatmap, highlight, fade, background, highlightEach, drag2 };

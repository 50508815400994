import BaseClass from './baseClass.mjs'
import ChildClass from './childClass.mjs'
import MeshClass from './meshClass.mjs'
import MidClass from './midClass.mjs'
import RootClass from './rootClass.mjs'

// export const BaseClass = BaseClassImp;
// export const ChildClass = ChildClassImp;
// export const MeshClassImp = MeshClassImp;
// export const MidClass = MidClassImp;
// export const RootClass = RootClassImp;

export default class ContentClasses {
  constructor() {
    m.mods.addClass(BaseClass, "BaseClass", "run");
    m.mods.addClass(ChildClass, "ChildClass", "run");
    m.mods.addClass(MeshClass, "MeshClass", "run");
    m.mods.addClass(MidClass, "MidClass", "run");
    m.mods.addClass(RootClass, "RootClass", "run");
  }

};

//import { timeHours } from "d3";

export default class Arr {
  constructor() {
    let that = this;

    Object.assign(String.prototype, {

      isArray: () => that.isArray(this),
      mergeArrays: () => that.mergeArrays(this),
      findIndexInNLPArray: () => that.findIndexInNLPArray(this),
      hasValues: () => that.hasValues(this),
      intersectObjects: () => that.intersectObjects(this),
      intersectObjectsAndValues: () => that.intersectObjectsAndValues(this)

    });
  }

  getKeys (obj) {
    let keysArr = [];
    for (var key in obj) {
      keysArr.push(key);
    }
    return keysArr;
  }

  next(arr, current) {
    let currentIndex = arr.indexOf(current);
    let ele = arr [currentIndex + 1]
    return ele
  }

  previous(arr, current) {
    let currentIndex = arr.indexOf(current);
    let ele = arr [currentIndex - 1]
    return ele
  }

  getType (source) {
    if (Array.isArray(source)) {
      if (source.length > 0) {
        return Object.prototype.toString.call(source[0]).match(/^\[object\s(.*)\]$/)[1];
      }
    } else {
      return Object.prototype.toString.call(source).match(/^\[object\s(.*)\]$/)[1];
    }
  }

  push (array, element, content) {
    array.push(element);
    content.index[element.id] = element;
    return array.push(element);
  }

  isPartOf (value, my_array) {
    //To find if a value or element exists in an array
    if (my_array.indexOf(value) != -1)
    {
      return true;
    } else {
      return false;
    }
  }

  isArray(val = this) {
    if (val === null) {
      return false;
    }
    return Array.isArray(val);
  }

  findIndexInNLPArray(NLPArray = this, NLPItem) {
    for (let i = 0; i < NLPArray.length; i++) {
      let text = NLPItem.text();
      if (NLPArray.list[i].text().trim() == text) return i;
      if (NLPArray.list[i].text() == text) return i;
      if (helper.cleanSentence(NLPArray.list[i].text()) == text) return i;
    }
    return null;
  }

  mergeArrays(arr1 = this, arr2) {
    let outArray = [];
    if (!arr1) arr1 = [];
    if (!arr2) return arr1;
    if (this.isArray(arr1)) outArray = arr1;
    arr2.forEach((obj) => {
      outArray = this.mergeArrayWithObject(outArray, obj);
    });

    /*
     if (!arr1) {
     outArray = arr2;
     } else {
     outArray = arr1 && arr1.map(obj => arr2 && arr2.find(p => p.id === obj.id) || obj);
     }
     */

    return outArray;
  }

  arrayToDict(arr = this) {
    var dict = {};
    try {
      arr.forEach((obj) => {
        if (obj.id) {
          dict[obj.id] = obj; //key + '=' + obj[key]
        }
      });

      return dict;
    } catch (e) {
      return e.message;
    }
  }

  intersectObjects(arr1 = this, key1, arr2, key2) {
    return arr1.filter((c) => arr2.findIndex((x) => x[key2] == c[key1]) > -1);
  }

  intersectObjectsAndValues(arr = this, key, keys) {
    return arr.filter((c) => keys.findIndex((x) => x == c[key].toLowerCase()) > -1);
  }

  hasValues(arr = this) {
    if (m.obj.isNull(arr)) {
      m.rep.checkMessage(false, "info", "is null:", arr);
      m.rep.check(false);
      return false;
    } else {
      let isArray = Array.isArray(arr);
      let isObject = typeof arr === "object" && arr !== null;

      if (isArray) {
        let length = arr.length;
        if (length > 0) {
          m.rep.checkMessage(false, "info", "array has items:", arr);
          return true;
        } else {
          m.rep.checkMessage(false, "info", "array has no items:", arr);
          m.rep.check(false);
          return false;
        }
      } else if (isObject) {
        let length = Object.keys(arr).length;
        if (length > 0) {
          m.rep.checkMessage(false, "info", "object has attributes:", arr);
          return true;
        } else {
          m.rep.checkMessage(false, "info", "object has no attributes:", arr);
          m.rep.check(false);
          return false;
        }
      } else {
        m.rep.checkMessage(false, "info", "is no array and no object:", arr);
        m.rep.check(false);
        return false;
      }
    }
  }
}

export default class Config {

  constructor() {
    this.setConfig();
    this.loadSettings()
  }

  setConfig = () => {
    
    let mode = "run";
    if (mesh.mode && mode !== "") mode = mesh.mode;

    // var len = $('script[src="http://localhost:3000/build/m.build.js"]').length;
    // if (len > 0) {
    //   mesh.mode = "build";
    // } else {
    //   mesh.mode = "run";
    // }

    // if (mesh.mode === "build") {
    //   let config = require("./m.common/config/config");
    //   mesh.mods.register(log, "config", "meta");
    //   loadConfig();
    // }

    if (!mesh.data) mesh.data = {};
    if (!mesh.temp) mesh.temp = {};

    mesh.class = {}
    mesh.markup = {};
    
    mesh.options = {
      markup: {
        noshadow: false, 
        stylesheet: "../dist/m.css",
      },
      tooltip: {
        grouped: true,
      },
    };
    mesh.dependencies =  []
    mesh.themes = []
    mesh.templates = []
    mesh.classes = {}
    mesh.sharedModules = {}
    //mesh.xref = {}
    //mesh.init = {}
    mesh.fn = {}
    if (!mesh.env) mesh.env = {};
    if (mesh.env.hasDoc) mesh.env.doc = document
    mesh.colors = ["#2196F3", "#009688", "#9C27B0", "#FFEB3B", "#afbbc9", "#4CAF50", "#2d3748", "#f56565", "#ed64a6"]
    mesh.sample = {
      users: [
        {
          id: 1,
          name: "Wade Cooper",
          avatar: "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
        { id: 2, name: "Arlene Mccoy", avatar: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 3, name: "Devon Webb", avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" },
        { id: 4, name: "Tom Cook", avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 5, name: "Tanya Fox", avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 6, name: "Hellen Schmidt", avatar: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 7, name: "Caroline Schultz", avatar: "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 8, name: "Mason Heaney", avatar: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 9, name: "Claudie Smitham", avatar: "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
        { id: 10, name: "Emil Schaefer", avatar: "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" },
      ],
    }
    
    mesh.placeholders = [];
    //mesh.templates = {};
    mesh.target = {
      calls: [],
      assets: [],
      blobs: [],
    };
    mesh.render = {
      config: null,
      content: null,
      doc: null,
      progress: 0,
    };
    mesh.npm = {};
    mesh.mods = {};
    mesh.data = {};
    mesh.temp = {};
    mesh.state = {};
    mesh.layout = {};
    mesh.template = {};
    mesh.components = {
      data: {},
    };
    mesh.charts = {};
    mesh.grids = {};
    mesh.json = {};
    mesh.heatmaps = {};
    mesh.radars = {};
    mesh.texts = {};
    mesh.config = {};
    mesh.dataMapping = {};
    mesh.helpers = {};
    mesh.promises = {};
    mesh.events = {};
    mesh.missingContent = [];
    mesh.externalScripts = [];
    mesh.npmScripts = [];
    mesh.transitions = {};
    
    mesh.log = true;
    m.logs = !m.logs ? {} : m.logs;
    m.logs.required = ["read"]; // "stage", "exchange", "bridge", "worker", "act", "event", "read", "recognize", "all"

    mesh.debug = true;
    mesh.content = {test: true};
    mesh.theme = {};
    mesh.app = {};
    mesh.blobs = {};
    mesh.settings = {};   

  };

  loadSettings = () => {

    // mesh.orchestrate = require('./config/m.orchestrate.config.json');
    // mesh.content = require('./config/m.build.config.json');
    // mesh.attract = require('./config/m.attract.config.json');
    // mesh.templates = require('./config/m.templates.config.json');
    // mesh.theme = require('./config/m.theme.config.json');
    // mesh.transitions = require('./config/m.transitions.config.json');
    // mesh.placeholders = require('./config/m.placeholders.config.json');
    // mesh.app = require('./config/m.app.config.json');
    // mesh.blobs = require('./config/m.blobs.config.json');
    // mesh.content = require('./config/m.content.config.json');
    // mesh.externalScripts = require('./config/m.externalScripts.config.json');
    // mesh.npmScripts = require('./config/m.npmScripts.config.json');

  };

}

export { Config };

//import HtmlSanitizer from '../components/HtmlSanitizer.mjs';
// import { createRequire } from 'module'
// const require = createRequire(import.meta.url);
// import texting from "./texting.mjs";
//import $ from "jquery";
//import { isBrowser } from "browser-or-node";

// import("../worker/controller/object.js")
// import("../worker/controller/property.js")
// import("../worker/controller/controller.js");

//import 'jsdom-global/register'
//import 'jsdom-worker'

export default class Html {
  constructor() {
    String.prototype.toDOM = this.toDOM.bind();
    // HTMLElement.prototype.setElementAttribute = this.setElementAttribute.bind();
    // HTMLElement.prototype.getOriginalElement = this.getOriginalElement.bind();
    // HTMLDocument.prototype.getElementByUuid = this.getElementByUuid.bind()
    // HTMLElement.prototype.getElementUuid = this.getElementUuid.bind()
    // HTMLElement.prototype.setOriginalAttribute = this.setOriginalAttribute.bind()

    if (!m.elements) m.elements = {};
    m.elements.loads = {};
    if (!m.elements.counter) m.elements.counter = 0;
  }
  
  getElementUuid (ele) {
      let uuid = ele.getAttribute("m-uuid");
      if (!uuid) {
          if (ele.children.length === 1) uuid = ele.children[0].getAttribute("m-uuid");
          if (!uuid) {
              // throw "no Uuid found"
          }
      }
      return uuid
  }

  getElementByLocalId (dom, localId) {
      let ele = dom.querySelectorAll('[m-id="'+localId+'"]')[0];
      return ele
  }

  getElementByUuid (dom, uuid) {
      let ele = dom.querySelectorAll('[m-uuid="'+uuid+'"]')[0];
      return ele
  }

  getOriginalElementDom (dom, ele) {
      let uuid = this.getElementUuid(ele)
      let originalEle = this.getElementByUuid(dom, uuid)
      return originalEle
  }

  getOriginalElement (ele) {
      let uuid = this.getElementUuid(ele)
      let dom = m.source.dom
      let originalEle = this.getElementByUuid(dom, uuid)
      return originalEle
  }

  setOriginalInner(uuid, htm) {
    let dom = m.source.dom
    let originalEle = this.getElementByUuid(dom, uuid)
    if (originalEle) originalEle.innerHTML = htm;
    return originalEle
  }

  wrapOriginalInner(uuid, tag) {
    let dom = m.source.dom
    let originalEle = this.getElementByUuid(dom, uuid)
    return this.wrapInner(originalEle, tag)
  }

  wrapInner(ele, tag) {    
    if (ele) {
      let hash = m.id.getHash(ele.innerHTML)
     ele.innerHTML = `<m-${tag} m-uuid="${hash}">${ele.innerHTML}</m-${tag}>`;
    }
    return ele
  }

  setElementAttribute (ele, key, value) {
      ele.setAttribute("m-"+ key, value);
      return ele
  }

  setOriginalAttribute (ele, key, value) {
      let originalEle = this.getOriginalElement(ele)
      if (originalEle) originalEle.setAttribute("m-"+ key, value);
      return originalEle
  }

  parseParams = (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return params;
  };

  createDocument(html, title) {
    //let parsedHtml = $.parseHTML(html);

    let clone = document.cloneNode(true);
    clone.write(html);

    //let doc = document.implementation.createHTMLDocument("m. run");
    //let srcNode = document.documentElement;
    //let newNode = doc.importNode(parsedHtml, true);

    //doc.replaceChild(newNode, document.documentElement);

    //let doc2 = document.implementation.createHTMLDocument(title)
    //doc2.documentElement.innerHTML = html

    return clone;
  }

  // decodeHTMLEntities(text) {
  //   return $("<textarea/>").html(text).text();
  // }

  // encodeHTMLEntities(text) {
  //   return $("<textarea/>").text(text).html();
  // }

  loadStyles(doc, externalHref) {
    const styles = doc.createElement("link");
    styles.setAttribute("rel", "stylesheet");
    styles.setAttribute("href", externalHref);
    doc.head.appendChild(styles);
  }

  addStylesFromString(doc, styles) {
    var css = doc.createElement("style");
    css.type = "text/css";
    if (css.styleSheet) css.styleSheet.cssText = styles;
    else css.appendChild(doc.createTextNode(styles));
    doc.getElementsByTagName("head")[0].appendChild(css);
  }

  addStyle(doc, style) {
    const [sheet] = doc.styleSheets;
    sheet.insertRule("strong { color: red; }", sheet.cssRules.length);
  }

  addStyles(doc) {
    var link = doc.createElement("link");
    link.rel = "stylesheet";
    link.href = base + "overlay.css?v=" + Date.now();
    m.env.doc.body.appendChild(link);
  }

  loadInNewTag(parent, tag, src, callback, params) {
    let req = new XMLHttpRequest();

    let id = "load" + m.elements.counter++;
    let slug = src.slug();

    req.addEventListener(
      "progress",
      function (event) {
        if (event.lengthComputable) {
          var percentComplete = event.loaded / event.total;
          console.log("Progress: " + percentComplete);
        }
      },
      false
    );

    req.addEventListener(
      "load",
      function (event) {
        var e = event.target;
        var s = m.env.doc.createElement(tag);
        s.setAttribute("data-m-src", src);
        s.setAttribute("id", id);
        s.setAttribute("data-m-name", slug);
        s.innerHTML = e.responseText;
        // or: s[s.innerText!=undefined?"innerText":"textContent"] = e.responseText
        //m.env.doc.body.insertBefore(s, m.env.doc.body.firstChild);
        parent.appendChild(s);
        m.eval(s);

        s.addEventListener("load", function () {
          m.log.success("Loaded '" + src + "' " + tag + " ready!");
          callback(s, params);
        });
      },
      false
    );

    req.open("GET", src);
    req.send();
  }

  // loadScript(src, onload) {
  //   let s = m.env.doc.createElement("script");
  //   s.onload = onload
  //     ? onload
  //     : function (e) {
  //         console.log(e.target.src + " is loaded.");
  //       };
  //   s.src = src;
  //   s.charset = "UTF-8";
  //   s.async = false;
  //   //m.env.doc.head.appendChild(s);
  //   m.env.doc.body.insertBefore(s, m.env.doc.body.firstChild);

  //   let cb = function (e) {};
  //   if (typeof cb === "function") {
  //     s.onload = cb;
  //     s.onreadystatechange = function () {
  //       /loaded|complete/.test(s.readyState) && cb(s);
  //     };
  //   }
  // }

  append(doc, tag, id, clas) {
    const container = doc.createElement(tag);
    container.setAttribute("id", "°m-" + id);
    if (clas) container.setAttribute("class", "°m-" + clas);
    doc.body.appendChild(container);
    return container;
  }

  getElement(doc, id) {
    id = id.replace("°m-", "");
    let ele = doc.getElementById("°m-" + id);
    return ele;
  }

  // decodeHTMLEntities(text) {
  //   return $("<textarea/>").html(text).text();
  // }

  // encodeHTMLEntities(text) {
  //   return $("<textarea/>").text(text).html();
  // }

  toDOM() {
    var d = document,
      i,
      a = d.createElement("div"),
      b = d.createDocumentFragment();
    a.innerHTML = this;
    while ((i = a.firstChild)) b.appendChild(i);
    return b;
  }

  getText = async (title) => {
    let text = await new Promise((resolve, reject) => {
      wtf = require("https://unpkg.com/wtf_wikipedia@8.4.0/builds/wtf_wikipedia-client.min.js");
      wtf.fetch(title).then((doc) => {
        if (err) return reject(err);
        try {
          resolve(doc.plaintext());
        } catch (error) {
          reject(error);
        }
      });
    });

    try {
      m.log.report(text);
      return text;
    } catch (err) {
      m.log.error(err);
    }
  };

  includeElement(destination, name, value) {
    try {
      if (value !== null && value.length > 0) {
        destination[name] = value;
      }
    } catch (error) {
      return destination;
    }
    return destination;
  }

  getNewDocument() {
    // var parser = require('ua-parser-js');
    // m.log.report(parser.getResult());
    //var parser = new UAParser();
    // if (m.env.hasDoc) {
    let doc = document.implementation.createHTMLDocument("temporary Document");
    return doc;
    // } else {
    //   const jsdom = require("jsdom");
    //   const { JSDOM } = jsdom;
    //   var dom = new JSDOM(`<!DOCTYPE html>`);
    //   var doc = dom.g.document;
    //   return doc;
    // }
  }

  selectHtml(input, selector) {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(input, 'text/html');
    let ele = htmlDoc.querySelector(selector)
    return ele.innerHTML;
  }

  getNewDomFromHtml(input, url) {
    let htmlDoc = document.implementation.createHTMLDocument("temporary Document");
    let htmlBody = htmlDoc.body; //htmlDoc.createElementNS("http://www.w3.org/1999/xhtml", "body");
    htmlBody.innerHTML = input;
    htmlDoc.documentElement.appendChild(htmlBody);
    return htmlDoc;

    // if (m.env.hasDoc) {
    //   let doc = document.implementation.createHTMLDocument("temporary Document");
    //   let body = doc.createElementNS("http://www.w3.org/1999/xhtml", "body");
    //   body.innerHTML = html;
    //   doc.documentElement.appendChild(body);
    //   return doc;
    //   // } else {
    //   //   const jsdom = require("jsdom");
    //   //   const { JSDOM } = jsdom;
    //   //   var dom = new JSDOM("<body>"+html+"</body>");
    //   //   var doc = dom.g.document;
    //   //   return doc;
    // } else {

    //   // require("../worker/receiver/receiver.js");
    //   // require("../worker/controller/object.js")
    //   // require("../worker/controller/property.js")
    //   // require("../worker/controller/controller.js");

    //   var document = self.document = { parentNode: null, nodeType: 9, toString: function () { return "FakeDocument" } };
    //   var window = self.window = self;
    //   var fakeElement = Object.create(document);
    //   fakeElement.nodeType = 1;
    //   fakeElement.toString = function () { return "FakeElement" };
    //   fakeElement.parentNode = fakeElement.firstChild = fakeElement.lastChild = fakeElement;
    //   fakeElement.ownerDocument = document;

    //   document.head = document.body = fakeElement;
    //   document.ownerDocument = document.documentElement = document;
    //   document.getElementById = document.createElement = function () { return fakeElement; };
    //   document.createDocumentFragment = function () { return this; };
    //   document.getElementsByTagName = document.getElementsByClassName = function () { return [fakeElement]; };
    //   document.getAttribute = document.setAttribute = document.removeChild =
    //       document.addEventListener = document.removeEventListener =
    //       function () { return null; };
    //   document.cloneNode = document.appendChild = function () { return this; };
    //   document.appendChild = function (child) { return child; };
    //   document.childNodes = [];
    //   document.implementation = {
    //       createHTMLDocument: function () { return document; }
    //   }

    //   let doc = document;
    //   // let body = doc.createElementNS("http://www.w3.org/1999/xhtml", "body");
    //   doc.body.innerHTML = html;
    //   //doc.documentElement.appendChild(body);

    //   return doc;

    // }
  }

  toElement(html) {
    let textDoc = m.html.getNewDocument();
    let wrapper = textDoc.createElement("div");
    try {
      wrapper.innerHTML = html;
    } catch {
      let unclosedTags = /<(|'[^']*'|[^>])*>/;
      html = m.str.replaceAll(html, unclosedTags, "");
      html = m.str.cleanSentence(html);
      wrapper.innerHTML = html;
    }

    return wrapper.firstChild;
  }

  toDomElement(html, tag) {
    let textDoc = m.html.getNewDocument();
    let wrapper = textDoc.createElement(tag);
    try {
      wrapper.innerHTML = html;
    } catch {
      let unclosedTags = /<(|'[^']*'|[^>])*>/;
      html = m.str.replaceAll(html, unclosedTags, "");
      html = m.str.cleanSentence(html);
      wrapper.innerHTML = html;
    }

    return wrapper;
  }

  decode(text) {
    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      const span = m.html.toDomElement(entity, "span");
      return span.innerText;
    });
  }

  // decodeHTMLEntities(text) {
  //   return $("<textarea/>").html(text).text();
  // }

  // encodeHTMLEntities(text) {
  //   return $("<textarea/>").text(text).html();
  // }

  deleteTag(tag, doc) {
    const elms = doc.getElementsByTagName(tag);
    for (let i = 0; i < elms.length; i++) {
      elms[i].remove();
    }
  }

  highlight(doc, element) {
    let meshId = "";
    element.classList.forEach((c) => {
      if (c.toString().includes("mesh")) {
        meshId = c;

        let elem = doc.querySelector("." + meshId);

        if (this.last != elem) {
          if (this.last != null) {
            this.last.classList.remove("hovered");
          }

          this.last = elem;
          elem.classList.add("hovered");
        }

        //this.pausecomp(3000);
      }
    });
  }

  getElement(doc, element) {
    let meshId = "";
    element.classList.forEach((c) => {
      if (c.toString().includes("mesh")) {
        meshId = c;

        let elem = doc.querySelector("." + meshId);
        return elem;
      }
    });
  }

  // Function to map HTML DOM attributes to inner text and hrefs
  mapDOM(element) {
    treeObject = {};
    let json = true;

    // IMPT: use jsdom because of in-built g.js
    // DOMParser() does not provide client-side window for element access if coding in Nodejs
    //domClone = new jsdom.JSDOM(html_string)
    //source = domClone.g.document
    //element = m.env.doc.firstChild

    // Recursively loop through DOM elements and assign attributes to inner text object
    // Why attributes instead of elements? 1. attributes more descriptive, 2. usually important and lesser
    function treeHTML(element, object) {
      var nodeList = element.childNodes;
      if (nodeList != null) {
        if (nodeList.length) {
          object[element.nodeName] = []; // IMPT: empty [] array for non-text recursivable elements (see below)
          for (var i = 0; i < nodeList.length; i++) {
            // if final text
            if (nodeList[i].nodeType == 3) {
              if (element.attributes != null) {
                for (var j = 0; j < element.attributes.length; j++) {
                  if (element.attributes[j].nodeValue !== "" && nodeList[i].nodeValue !== "") {
                    if (element.attributes[j].name === "href") {
                      // separate href
                      object[element.attributes[j].name] = element.attributes[j].nodeValue;
                    } else {
                      object[element.attributes[j].nodeValue] = nodeList[i].nodeValue;
                    }
                  }
                }
              }
              // else if non-text then recurse on recursivable elements
            } else {
              object[element.nodeName].push({}); // if non-text push {} into empty [] array
              treeHTML(nodeList[i], object[element.nodeName][object[element.nodeName].length - 1]);
            }
          }
        }
      }
    }
    treeHTML(element, treeObject);

    return json ? JSON.serialize(treeObject) : treeObject;
  }

  download(doc, exportObj, exportName) {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.serialize(exportObj, null, 2));
    let downloadAnchorNode = doc.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    doc.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  AllowedTags() {
    return {
      A: true,
      ABBR: true,
      B: true,
      BLOCKQUOTE: true,
      BODY: true,
      BR: true,
      CENTER: true,
      CODE: true,
      DIV: true,
      EM: true,
      FONT: true,
      H1: true,
      H2: true,
      H3: true,
      H4: true,
      H5: true,
      H6: true,
      HR: true,
      I: true,
      IMG: true,
      LABEL: true,
      LI: true,
      OL: true,
      P: true,
      PRE: true,
      SMALL: true,
      SOURCE: true,
      SPAN: true,
      STRONG: true,
      TABLE: true,
      TBODY: true,
      TR: true,
      TD: true,
      TH: true,
      THEAD: true,
      UL: true,
      U: true,
      VIDEO: true,
    };
  }

  AllowedContentTags() {
    return { FORM: true };
  } //tags that will be converted to DIVs

  AllowedAttributes() {
    return { "m-uuid": true, "m-contentScore": true, "m-contentBonus": true, "m-linkDensity": true, align: true, color: true, controls: true, height: true, href: true, src: true, style: true, target: true, title: true, type: true, width: true };
  }

  AllowedCssStyles() {
    return { color: true, "background-color": true, "font-size": true, "text-align": true, "text-decoration": true, "font-weight": true };
  }

  AllowedSchemas() {
    return ["http:", "https:", "data:", "m-files:", "file:", "ftp:"];
  } //which "protocols" are allowed in "href", "src" etc

  AllowedUriAttributes() {
    return { href: true, action: true };
  }

  sanitizeHtml(html) {
    delete m.html.AllowedCssStyles()[("color", "background-color", "font-size", "text-align", "text-decoration", "font-weight")];
    delete m.html.AllowedTags()[("table", "body", "br", "center", "div", "font", "hr", "small", "span", "strong")];
    delete m.html.AllowedAttributes()[("m-uuid", "m-contentScore", "m-contentBonus", "m-linkDensity", "align", "color", "controls", "height", "style", "target", "width")];

    let input = html.trim();
    if (input == "") return ""; //to save performance and not create iframe

    //firefox "bogus node" workaround
    if (input == "<br>") return "";

    let htmlDoc = m.html.getNewDomFromHtml(input);
    let htmlBody = htmlDoc.body;

    /*var iframe = m.env.doc.createElement('iframe');
    if (iframe['sandbox'] === undefined) {
      //alert('Your browser does not support sandboxed iframes. Please upgrade to a modern browser.');
      return '';
    }
    iframe['sandbox'] = 'allow-same-origin';
    iframe.style.display = 'none';
    m.env.doc.body.appendChild(iframe); // necessary so the iframe contains a document
    var iframedoc = iframe.contentDocument || iframe.contentg.document;
    if (iframedoc.body == null) iframedoc.write("<body></body>"); // null in IE
    iframedoc.body.innerHTML = input;
    */

    var resultElement = m.html.makeSanitizedCopy(htmlBody, htmlDoc); //iframedoc.body
    //m.env.doc.body.removeChild(iframe);

    let out = resultElement.innerHTML.replace(/<br[^>]*>(\S)/g, "<br>\n$1").replace(/div><div/g, "div>\n<div"); //replace is just for cleaner code

    return out; //replace is just for cleaner code
  }

  makeSanitizedCopy(node, doc) {
    if (node.nodeType == 8) {
      //Comment
      var newNode = node.cloneNode(true);
    } else if (node.nodeType == 3) {
      //Node.TEXT_NODE
      var newNode = node.cloneNode(true);
    } else if (node.nodeType == 1 && (m.html.AllowedTags()[node.tagName] || m.html.AllowedContentTags()[node.tagName])) {
      //Node.ELEMENT_NODE

      //remove useless empty spans (lots of those when pasting from MS Outlook)
      if ((node.tagName == "SPAN" || node.tagName == "B" || node.tagName == "I" || node.tagName == "U") && node.innerHTML.trim() == "") {
        return doc.createDocumentFragment();
      }

      if (m.html.AllowedContentTags()[node.tagName]) {
        newNode = doc.createElement("DIV");
      } else {
        newNode = doc.createElement(node.tagName);
      }
      for (var i = 0; i < node.attributes.length; i++) {
        var attr = node.attributes[i];
        if (m.html.AllowedAttributes()[attr.name]) {
          if (attr.name ==="m-uuid") //debugger;
          if (attr.name == "style") {
            for (let s = 0; s < node.style.length; s++) {
              var styleName = node.style[s];
              if (m.html.AllowedCssStyles()[styleName]) newNode.style.setProperty(styleName, node.style.getPropertyValue(styleName));
            }
          } else {
            if (m.html.AllowedUriAttributes()[attr.name]) {
              //if this is a "uri" attribute, that can have "javascript:" or something
              if (attr.value.indexOf(":") > -1 && !m.html.startsWithAny(attr.value, m.html.AllowedSchemas())) continue;
            }
            newNode.setAttribute(attr.name, attr.value);
          }
        }
      }
      for (i = 0; i < node.childNodes.length; i++) {
        var subCopy = m.html.makeSanitizedCopy(node.childNodes[i], doc);
        newNode.appendChild(subCopy, false);
      }
    } else {
      newNode = doc.createDocumentFragment();
    }
    return newNode;
  }

  startsWithAny(str, substrings) {
    for (var i = 0; i < substrings.length; i++) {
      if (str.indexOf(substrings[i]) == 0) {
        return true;
      }
    }
    return false;
  }
}

export default class Events {
  constructor() {
    m.pub = this.triggerEvent
  }

  setup() {    
    m.event = m.event ? m.event : {}
    m.event.open = m.event.open ? m.event.open : []
    m.event.expexted = m.event.expexted ? m.event.expexted : []
    m.event.promises = m.event.promises ? m.event.promises : {}
    m.event.triggered = m.event.triggered ? m.event.triggered : {}
    m.event.initialized = m.event.initialized ? m.event.initialized : {}
  }

  subscribed(cause) {
    m.event.open.push(cause);
  }

  unsubscribed(causes) {    
    m.event.subscribed = m.event.subscribed.filter(item => {
      return !causes.find(cause => {
         return item === cause;
      });
    })
  }

  listenEvent(name, context = g) {
    this.setup()
    let eve = m.events[name];
    if (!eve) this.initEvent(name);

    let prom = m.promises[name];
    if (!prom)
      m.event.open.push(name);
      m.promises[name] = new Promise(function (resolve) {
        m.log.event.wait("event.listenEvent '" + name + "'")
        context.addEventListener(name, resolve, false);
      });
  }

  triggerEventBak(name, actName = "", context = g) {
    this.setup()
    m.log.event.success("event.triggerEvent '" + name + "'" + (actName !== "" ?  " triggered -> '" + actName + "'": ""));
    let eve = m.events[name];
    if (!eve) this.initAndListenEvent(name);
    eve = m.events[name];
    context.dispatchEvent(eve);
    return eve;
  }

  listenDataEvent(name, context = g) {
    this.setup()
    m.event.data = m.event.data ? m.event.data : {}
    let eve = m.events[name];
    if (!eve) this.initEvent(name);

    let prom = m.promises[name];
    if (!prom)
      m.promises[name] = new Promise(function (resolve) {
        m.log.event.wait("event.listenEvent '" + name + "'")
        context.addEventListener(name, resolve, false);
      });
  }

  async triggerEvent(name, data, target, actName, context = g) {

    let eventName = actName || name.toEventName()

    if (!target) {
      m.evs.setup()
      m.event.data = m.event.data ? m.event.data : {}
      if (data) m.event.data[name] = data;
      m.log.event.success("event.triggerEvent '" + name + "'" + (eventName !== "" ?  " triggered -> '" + eventName + "'": ""), data);
      let eve = m.events[name];
      if (!eve) m.evs.initAndListenEvent(name);
      eve = m.events[name];
      if (data) {eve.data = data} else {eve.data = {}}
      let now = Date.now();
      m.event.triggered[now.toString()] = eve;
      m.event.open = m.event.open.filter(item => item !== name);
      context.dispatchEvent(eve);
      return eve;
    } else {
      return m.bridge.triggerEvent(eventName, target, data)
    }

  }

  initEvent(name, context = g) {
    this.setup()
    //m.log.event.report("Event '" + name + "' initialized")
    if (!m.events[name]) m.events[name] = new CustomEvent(name);
    let now = Date.now();
    m.event.initialized[now.toString()] = m.events[name];
    return m.events[name];
  }

  initAndListenEvent(name) {
    //m.log.event.wait("Event '" + name + "' initialized and listening")
    let eve = this.initEvent(name);
    this.listenEvent(name);
    return eve;
  }

  declareCompletionBak(name, actName = "") {
    //m.log.event.done("Event '" + name + "' initialized and listening -> '" + actName + "'")
    let eve = initEvent(name);
    this.listenEvent(name);
    this.triggerEvent(name, actName);
    return eve;
  }
}

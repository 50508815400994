import Item from "./item.js";
import Unit from "./unit.js";
import Topic from "./topic.js";
import Mention from "./position.js";

export default class Weight extends Item {

	#value;
	#unit;
	#measurable;
	#mention;

	constructor(items, value = 1, unit = m.defaultUnit, measurable = m.defaultMeasurable, mention) {
        super(items);
		this.basing = () => `${this.#value} ${this.#unit.name} ${this.#measurable.name}`

		this.#value = value;
		this.#unit = unit;
		this.#measurable = measurable;
		if (mention) this.#mention = mention;
		this.init()
	}

	update(value = 1, unit = m.defaultUnit, measurable = m.defaultMeasurable, mention) {
		this.#value = value;
		this.#unit = unit;
		this.#measurable = measurable;
		if (mention) this.#mention = mention;
		this.store()
	}

	get value() {
		return this.#value
	}

	get unit() {
		return this.#unit
	}

	get measurable() {
		return this.#measurable
	}

	get mention() {
		return this.#mention
	}

}

export default class List {
	constructor(items) {
		this.items = JSON.parse(JSON.stringify(items));

		delete this.items.protocol;
		delete this.items.host;
		delete this.items.path;
		delete this.items.method;
		delete this.items.port;
		delete this.items.mapping;
	}

	items = {};
	splitter = "&";
	definer = "=";

	add(key, value) {
		this.items[key] = value;
	}

	forEach(callback) {
		for (let i = 0; i < this.items.length; i += 1) {
			if (Object.keys(this.items).hasOwnProperty(i)) {
				callback(this[i], i, this);
			}
		}
	}

	get items() {
		return this.items;
	}

	get arr() {
		let arra = Object.keys(this.items);
		let result = arra.map((key) => {
			let out = { key: key, value: this.items[key] };
			return out;
		});

		return result;
	}

	toUrlEncoded() {
		var urlencoded = new URLSearchParams();
		this.arr.forEach((item) => {
			urlencoded.append(item.key, item.value);
		});
		return urlencoded;
	}

	toString() {
		let list = this.arr;
		let str = "";
		list.forEach((item) => {
			let out = encodeURIComponent(item.key) + this.definer + encodeURIComponent(item.value);
			str = str !== "" ? str + this.splitter + out : out;
		});
		return str;
	}

	static newFromArray(arr) {
		let items = {};
		try {
			arr.forEach((item) => {
				if (item.id) {
					dict[item.id] = item;
				}
			});
			return new List(items);
		} catch (e) {
			return e.message;
		}
	}

}

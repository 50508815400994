import Toastify from "toastify-js";

export default class Report {
  constructor() {
    let preString = "";

    switch (mesh.env.channel) {
      case "app":
        preString = "⏳ m.app |";
        break;
      case "main":
        preString = "💎 m.main |";
        break;
      case "service":
        preString = "⚙️ m.worker |";
        break;
      case "lab":
        preString = "🤖 m.lab  |";
        break;
      case "local":
        preString = "📂 m.file |";
        break;
      case "generate":
        preString = "🪄 m.generator |";
        break;
      case "bot":
        preString = "🤖 m.bot |";
        break;
      case "server":
        preString = "📡 m.server |";
        break;
      case "hub":
        preString = "⚙️ m.hub |";
        break;
      case "host":
        preString = "🔖 m.host |";
        break;
      default:
        preString = "🔗 m.web |";
        break;
    }

    g.report = new Console(console);
    mesh.log = {};
    mesh.log.debug = report.stream(preString + "☠️").debug;
    mesh.log.warn = report.stream(preString + "🔥").warn;
    mesh.log.error = report.stream(preString + "💥").error;
    mesh.log.list = report.stream(preString + "🔹").log;
    mesh.log.report = report.stream(preString + "   ").log;

    function logSet(preIndent, name, emoji, postIndent) {
      mesh.log[name] = {};
      let setString = "" + emoji + " " + name + " | ";
      let mustString = name + "###" + preString + preIndent + setString + postIndent;
      mesh.log[name].trigger = report.stream(mustString + "🎫 trigger:").log;
      mesh.log[name].reponse = report.stream(mustString + "📦 response:").log;
      mesh.log[name].listen = report.stream(mustString + "🎧 listen:").log;
      mesh.log[name].watch = report.stream(mustString + "🧭 watch:").log;
      mesh.log[name].post = report.stream(mustString + "✉️ post:").log;
      mesh.log[name].error = report.stream(mustString + "💥 error:" + postIndent).error;
      mesh.log[name].warn = report.stream(mustString + "🔥 warn:" + postIndent).warn;
      mesh.log[name].debug = report.stream(mustString + "☠️ debug:" + postIndent).debug;
      mesh.log[name].wait = report.stream(mustString + "⏳ wait:").log;
      mesh.log[name].processing = report.stream(mustString + "⚙️ process:").log;
      mesh.log[name].success = report.stream(mustString + "👍 success:").log;
      mesh.log[name].ready = report.stream(mustString + "🏁 ready:").log;
      mesh.log[name].report = report.stream(mustString + "        :").log;
    }
    logSet("", "stage", "🥇", "   ");
    logSet(" ", "read", "📰", "  ");
    logSet(" ", "recognize", "🔎", "  ");
    logSet(" ", "exchange", "💱", "  ");
    logSet("   ", "bridge", "📞", "");
    logSet("      ", "worker", "⚙️", "");
    logSet("      ", "act", "⚙️", "");
    logSet("         ", "event", "   ⚙️", "");

    mesh.talk = {};
    mesh.talk.message = report.stream(preString + "   ").message;
    mesh.talk.warning = report.stream(preString + "🔥").warning;
    mesh.talk.danger = report.stream(preString + "💥").danger;
    mesh.talk.success = report.stream(preString + "👍").success;
    mesh.talk.warn = report.stream(preString + "🔥").warning;
    mesh.talk.wait = report.stream(preString + "⏳").warning;
  }

  expect(message, causes) {
    let causesString = mesh.acts.getCausesString(causes);
    let promises = mesh.acts.getPromises(causes);
    if (message && message !== "") {
      Promise.all(promises).then(() => {
        mesh.log.info("'" + causesString + "': " + message);
      });
    }
  }

  logFnCall(name, args) {
    let s = name + "(";
    for (let i = 0; i < args.length; i++) {
      if (i > 0) s += ", ";
      s += String(args[i]);
    }
    s += ")";
    logObject(s);
  }

  logInjection(message, obj) {
    if (isLog() && 1 === 0) {
      if (obj) {
        logObject("info", "calling: " + message + " -> ");
        logObject("info", obj);
      } else {
        logObject("info", "calling: " + message);
      }
    }
  }

  logMessage(level, message, obj) {
    if (isLog()) {
      if (obj) {
        log(level, "💎 m. | " + message + " -> ");
        logObject(level, obj);
      } else {
        //mesh.rep.checkMessage(false,"info",'Mesh ' + ♾️ mesh.mode + ' mode: ' + message);
        log(level, "💎 m. | " + message);
      }
    }
  }

  log(level, message, scope = "") {
    var group = report.stream(scope);

    if (isLog()) {
      switch (level) {
        case "silent":
          break;

        case "fatal":
          group.fatal(message);
          break;

        case "warn":
          group.warn(message);
          break;

        case "log":
          group.log(message);
          break;

        case "info":
          group.info(message);
          break;

        case "success":
          group.success(message);
          break;

        case "debug":
          group.debug(message);
          break;

        case "trace":
          group.trace(message);
          break;
      }
    }
  }

  logReplace(message, match, replaced) {
    if (isLog()) {
      mesh.rep.checkMessage(false, "info", "Replaced " + message + ": " + match + " -> ");
      logObject("info", replaced);
    }
  }

  logObject(level, obj) {
    if (isLog() && level === "warning") {
      if (obj) {
        mesh.rep.checkMessage(false, "warning", obj);
      }
    }
  }

  checkReplace(isActive = false, message, match, replaced) {
    if (this.isDebug(isActive)) {
      logReplace(message, match, replaced);
    }
  }

  checkMessage(isActive = false, level, message, obj) {
    if (this.isDebug(isActive)) {
      mesh.rep.checkMessage(false, level, message, obj);
    }
  }

  checkData(isActive = false, level, obj) {
    if (this.isDebug(isActive)) {
      logObject(level, obj);
    }
  }

  isLog() {
    if (mesh.log === true) {
      return true;
    } else {
      return false;
    }
  }

  isDebug(isActive) {
    if ((isActive && mesh.log === true && mesh.debug === true) || (isActive && mesh.mode === "build")) {
      return true;
    } else {
      return false;
    }
  }

  check(isActive = false, message = "check", obj = null, debugging = true) {
    if (this.isDebug(isActive)) {
      mesh.rep.checkMessage(isActive, "debug", message, obj);
      if (debugging === true) {
        // debugger;
      }
    }
  }

  isIncluded(include, text) {
    let isIncluded = text.indexOf(include) >= 0;
    return isIncluded;
  }

  checkInclude(isActive = false, include, text) {
    if (this.isDebug(isActive)) {
      if (isIncluded(include, text)) {
        mesh.rep.checkMessage(isActive, "debug", include + " is included in: " + text);
        if (this.isDebug === true) debugger;
      } else {
        mesh.rep.checkMessage(isActive, "debug", include + " is not included in: " + text);
        if (this.isDebug === true) debugger;
      }
    }
  }

  checkDebug(isActive = false, message = "check", obj = null) {
    mesh.rep.checkMessage(isActive, "debug", message);
    checkData(false, "debug", obj);
    check(this.isDebug);
  }

  checkDataIndicators(isActive, data, text, origin) {
    let matrixLength = matrixCount(data);
    let seriesLength = seriesCount(data);

    if (data && hasValues(data) && (matrixLength > 0 || seriesLength > 0)) {
      let timeseriesLength = timelineCount(data);
      let message = "Data - " + origin + " | " + text + ": # " + matrixLength + " | TL " + timeseriesLength + " | TS " + seriesLength;
      mesh.rep.checkMessage(true, "info", message);
      checkData(false, "info", data);
    } else {
      let message = "No Data - " + origin + " | " + text + "";
      checkData(false, "info", data);
    }
  }

  timelineCount(data) {
    let isArray = Array.isArray(data);
    if (hasValues(data) && isArray) {
      return data.filter((x) => x.timeline).length;
    } else {
      return 0;
    }
  }

  seriesCount(data) {
    return 0;
  }

  matrixCount(data) {
    let isArray = Array.isArray(data);
    if (hasValues(data) && isArray) {
      return data.filter((x) => x.query).length;
    } else {
      return 0;
    }
  }

  injectLog(caller, fn) {
    if (Object.prototype.toString.call(fn) === "[object Function]") {
      //if (typeof fn === 'function') {

      let methodName = getFunctionName(fn);
      let callerName = caller.m ? "window" : caller.prototype.constructor.name;
      let originalMethod = fn; //caller[methodName];

      caller[methodName] = function () {
        //logInjection.call(caller, callerName + " -> " + methodName);
        let output = originalMethod.apply(caller, arguments);
        return output;
      };
    }
  }

  #intercept(fn) {
    /*scarlet.intercept(fn)
        .on('before', mesh.rep.checkMessage(false,"info",fn.name + ': before'))
        .on('after', logFn(fn.name + ': after'))
        .on('done', logFn(fn.name + ': done'))
        .on('error', logFn(fn.name + ': error'));
        */
  }
}

class Console {
  constructor(console, name, options) {
    // //const { toast, snackbar } = require("tailwind-toast");
    // this.toast = toast;
    // this.snackbar = snackbar;

    options = options || {};
    let opts = {
      enabled: typeof options.enabled != "undefined" ? options.enabled : true,
      color: typeof options.color != "undefined" ? options.color : "#bada55",
      background: typeof options.background != "undefined" ? options.background : "#111",
    };
    let colorTypes = "dir|dirxml|error|info|log|warn";
    let colorSupported = isColorSupported();
    let streams = {};
    let logs = [];

    //private

    let duration = 15000;

    let warnToast, successToast, dangerToast, infoToast;
    if (mesh.env.hasDoc) {
      let config = {
        gravity: "bottom",
        stopOnFocus: true,
        duration: duration,
        offset: {
          x: 25,
          y: 80,
        },
      };

      let warnConfig = { ...config, style: { background: "#e15a25" } };
      let successConfig = { ...config, style: { background: "#5a8cea" } };
      let dangerConfig = { ...config, style: { background: "#fd301e" } };
      let infoConfig = { ...config, style: { background: "#2e2e2e" } };

      warnToast = (message) => {
        Toastify({ ...warnConfig, text: message }).showToast();
      };
      successToast = (message) => {
        Toastify({ ...successConfig, text: message }).showToast();
      };
      dangerToast = (message) => {
        Toastify({ ...dangerConfig, text: message }).showToast();
      };
      infoToast = (message) => {
        Toastify({ ...infoConfig, text: message }).showToast();
      };
    }

    function log(args, type) {
      var color;
      args = argumentsToArray(args);

      if (opts.enabled) {
        if (colorSupported && name !== undefined && colorTypes.indexOf(type) != -1) {
          color = type !== "dir" ? "%c " : "";
          //hat tip: http://stackoverflow.com/questions/7505623/colors-in-javascript-console
          //args.unshift(color + name + ' ', 'color:' + opts.color + '; background:' + opts.background + '; font-weight:bold');
          args.unshift(name + " ", "");
          console[type].apply(console, args);
          args.splice(0, 1);
        } else {
          console[type].apply(console, args);
        }
      }

      args.push(new Date()); //add timestamp
      logs.push(args);
    }

    function argumentsToArray(args) {
      return Array.prototype.slice.call(args, 0);
    }

    function isColorSupported() {
      var ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("firefox") != -1 || ua.indexOf("chrome") != -1;
    }

    this.stream = function (name, options) {
      var stream = streams[name];

      if (!stream) {
        stream = new Console(console, name, options);
        streams[name] = stream;
      }

      return stream;
    };

    this.on = function (name) {
      if (typeof name != "undefined") {
        streams[name].on();
      } else {
        opts.enabled = true;
      }
    };

    this.off = function (name) {
      if (typeof name != "undefined") {
        streams[name].off();
      } else {
        opts.enabled = false;
      }
    };

    this.logs = function () {
      return logs;
    };

    this.color = function (color) {
      opts.color = color;
    };

    this.background = function (background) {
      opts.background = background;
    };

    this.assert = function () {
      log(arguments, "assert");
    };
    this.clear = function () {
      log(arguments, "clear");
    };
    this.count = function () {
      log(arguments, "count");
    };
    this.debug = function () {
      log(arguments, "debug");
    };
    this.dir = function () {
      log(arguments, "dir");
    };
    this.dirxml = function () {
      log(arguments, "dirxml");
    };
    this.error = function () {
      log(arguments, "error");
    };
    this.exception = function () {
      log(arguments, "exception");
    };
    this.group = function () {
      log(arguments, "group");
    };
    this.groupCollapsed = function () {
      log(arguments, "groupCollapsed");
    };
    this.groupEnd = function () {
      log(arguments, "groupEnd");
    };
    this.info = function () {
      log(arguments, "info");
    };
    this.log = function () {
      log(arguments, "log");
    };
    this.profile = function () {
      log(arguments, "profile");
    };
    this.profileEnd = function () {
      log(arguments, "profileEnd");
    };
    this.table = function () {
      log(arguments, "table");
    };
    this.time = function () {
      log(arguments, "time");
    };
    this.timeEnd = function () {
      log(arguments, "timeEnd");
    };
    this.timeStamp = function () {
      log(arguments, "timeStamp");
    };
    this.trace = function () {
      log(arguments, "trace");
    };
    this.warn = function () {
      log(arguments, "warn");
    };

    function log(args, type) {
      var color;
      args = argumentsToArray(args);
      args[0] = "" + args[0];

      let intent = "";
      if (name && name.includes("###")) {
        let parts = name.split("###");
        if (parts.length > 0) {
          name = parts[1];
          intent = parts[0];
        }
      }
      if (opts.enabled) {
        if (colorSupported && name !== undefined && colorTypes.indexOf(type) != -1) {
          color = type !== "dir" ? "%c " : "";

          //hat tip: http://stackoverflow.com/questions/7505623/colors-in-javascript-console
          //args.unshift(color + name + ' ', 'color:' + opts.color + '; background:' + opts.background + '; font-weight:bold');
          args.unshift(mesh.env.stage + ": " + name, "");
          if (mesh.logs.required.includes(intent) || mesh.logs.required.includes("all")) console[type].apply(console, args);
          args.splice(0, 1);
        } else {
          if (mesh.logs.required.includes(intent) || mesh.logs.required.includes("all")) console[type].apply(console, args);
        }
      }

      if (mesh.logs.required.includes(intent) || mesh.logs.required.includes("all")) {
        args.push(new Date()); //add timestamp
        logs.push(args);
      }
    }

    this.warning = function () {
      if (Toastify && mesh.env.hasDoc) warnToast(arguments[0]);
      log(arguments, "warn");
    };
    this.danger = function () {
      if (Toastify && mesh.env.hasDoc) dangerToast(arguments[0]);
      log(arguments, "error");
    };
    this.success = function () {
      if (Toastify && mesh.env.hasDoc) successToast(arguments[0]);
      log(arguments, "info");
    };
    this.message = function () {
      if (Toastify && mesh.env.hasDoc) infoToast(arguments[0]);
      log(arguments, "info");
    };
  }
}

import Arr from "./arr";
import Obj from "./obj";
import Str from "./str";
import Html from "./html";
import Ui from "./ui";
import Id from "./id";
import Json from "./json";
import Schema from "./schema";
import List from "./list";

export default class Types {
  constructor() {
    m.mods.instantiate(Arr, "Arr", "arr", "meta");
    m.mods.instantiate(Obj, "Obj", "obj", "meta");
    m.mods.instantiate(Str, "Str", "str", "meta");
    m.mods.instantiate(Html, "Html", "html", "meta");
    m.mods.instantiate(Ui, "Ui", "ui", "meta");
    m.mods.addClass(List, "List", "meta");
    m.mods.instantiate(Id, "Id", "id", "meta");
    m.mods.instantiate(Json, "Json", "json", "meta");
    m.mods.instantiate(Schema, "Schema", "schema", "meta");
    m.eval = this.eval
  }

};

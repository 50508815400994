//import * as d3 from "d3";
import {parseEntities} from "../logic/utility";
import force from "../visualization/force";
    
import Matrix from "./matrix";

export default class Adjacency {

  constructor(name) {
    //m.talk.wait("Your adjacency is now calculating...");    

    var g = g || window;
    let d3 = !g.d3 ? require("d3") : g.d3
    g.d3 = d3;

    let math = require("mathjs");
    g.math = math;

    this.name = name || "default".id();

    // m.mods.addClass(Matrix, "Matrix", "run");
    // // m.mods.addClass(Graph, "Graph", "run");
    // let matrix = new Matrix();
    // let graph = new Graph();

    if (typeof m !== "undefined" && m.evs) m.pub("onInitializedAdjacency");
    // m.talk.message("Your adjacency matrix and graph have been calculated");
  }

  setCSV(csv, weights) {    
    csv = csv.replace(/;/g, "	");  
    csv = csv.replace(/\\n/g, "\n");
    csv = csv.replace(/\"\n"/g, "");
    this.entities = parseEntities(csv);
    this.calculate(weights);
  }

  calculate(weights) {
    this.setEntitiesLabels(weights);
    //this.setMat();
    delete this.similarity;
    delete this.mat;
    delete this.mat0;
    delete this.mat1;
    delete this.mat2;
    delete this.mat3;
    delete this.mat4;
    delete this.mat5;
    this.setMat0(this.name);
    this.setMat1(this.name);
    this.setMat2(this.name);
    this.setMat3(this.name);
    this.setMat4(this.name);
    this.setMat5(this.name);
    //this.setGraph(settings, sourceHref);
  }

  /**
   * Sets the entities labels based on the given weights.
   * @param {Array<Array<number>>} weights - The weights for the entities.
   */
  setEntitiesLabels = (weights) => {
    this.weights = weights
    delete this.columnLabels
    delete this.rowLabels
    delete this.labels
    this.columnLabels = this.entities.columns.slice(1).map((id) => ({ type: "class", id, weight: weights[1][id] || 1 }))
    this.rowLabels = this.entities.map((row) => ({...row, ...{ type: "topic", weight: weights[0][row.id] || 1  }})) 
    this.labels = [...this.rowLabels, ...this.columnLabels]
  }
  
  setMat = (name = "mat") => {
    // this.mat0 = Matrix.NamedMatrix(
    //   [this.rowLabels.length, this.columnLabels.length], 
    //   d3.merge(this.rowLabels.map((s) => this.columnLabels.map((f) => s[f.id]))),
    //   this.columnLabels, this.rowLabels);
    this.mat = Matrix.MatrixFromVectors(this.columnLabels, this.rowLabels, name + "||mat");
    console.log(this.mat)
  };
  
  setMat0 = (name = "mat0") => {
    this.mat0 = Matrix.NamedMatrix(
      [this.rowLabels.length, this.columnLabels.length], 
      d3.merge(this.rowLabels.map((s) => this.columnLabels.map((f) => {
        let columnWeight = f.weight || 1
        let rowWeight = s.weight || 1
        return s[f.id] * columnWeight * rowWeight}))),
      this.columnLabels, this.rowLabels, name + "||mat0");
  };

  setMat1 = (name = "mat1") => {
    const sz = this.mat0.nrow + this.mat0.ncol;
    const cells = new Array(sz * sz);
    for (let i = 0; i < sz; i++) {
      for (let j = 0; j < sz; j++) {
        cells[i * sz + j] = i < this.mat0.nrow ? (j < this.mat0.nrow ? 0 : this.mat0.get(i, j - this.mat0.nrow)) : j < this.mat0.nrow ? this.mat0.get(j, i - this.mat0.nrow) : 0;
      }
    }
    this.mat1 = Matrix.NamedMatrix([sz, sz], cells, this.labels, this.labels, name + "||mat1");
  };

  setMat2 = (name = "mat2") => {
    this.mat2 = this.mat1.multiply(this.mat1, name + "||mat2")
    this.mat2.rowLabels = this.mat1.rowLabels;
    this.mat2.columnLabels = this.mat1.columnLabels;
  };

  setMat3 = (name = "mat3") => {
    this.mat3 = Matrix.NamedMatrix(
      [this.entities.length, this.entities.length],
      d3.merge(
        this.mat2
          .pprint()
          .slice(0, this.entities.length)
          .map((row, i) => {
            return row.slice(0, this.entities.length).map((value, j) => (j >= i ? 0 : value));
          })
      ),
      this.rowLabels, this.rowLabels, name + "||mat3");
  };

  setMat4 = (name = "mat4") => {
    this.mat4 = this.mat2.add(this.mat1, name + "||mat4")
    this.mat4.rowLabels = this.mat1.columnLabels;
    this.mat4.columnLabels = this.mat1.columnLabels;
  };

  setMat5 = (name = "mat5") => {
    this.mat5 = Matrix.NamedMatrix( 
      [this.labels.length, this.labels.length],
      d3.merge(
        this.mat4
          .pprint()
          .slice(0, this.labels.length)
          .map((row, i) => {
            return row.slice(0, this.labels.length).map((value, j) => (j >= i ? 0 : value));
          })
      ),
      this.labels, this.labels, name + "||mat5");
  };

  // setGraph = (settings, sourceHref) => {
  //   this.similarity = this.mat3.graph("similarity", settings, sourceHref)
  //   this.mat2Similarity = this.mat4.graph("mat2Similarity", settings, sourceHref)
  //   //this.mat1Similarity = new Graph(this.entities, this.mat1)
  //   //this.mat0Similarity = new Graph(this.entities, this.mat0)
  // };  
  
  showForceGraph (graph, eleName, w = mesh.input.w, h = mesh.input.h) {

    this.setConfig(w, h)
    
    force(eleName, graph, this.config)

    // let graphSVG = d3.select("#" + eleName).append("svg").attr("width", w).attr("height", h);
    // const graphChart = graphSVG.append("g");
    // graphChart.append(() => force(this.similarity, this.config));

  };

  // showHeatmap (matrix, eleName, w = 500, h = 500) {

  //   this.setConfig(w, h)

  //   let heatmap = renderHeatmap(matrix, this.config)
    
  //   d3.select("#" + eleName).selectAll("*").remove();
  //   let matrixSVG = d3.select("#" + eleName).append("svg").attr("width", heatmap.w).attr("height", heatmap.h);
  //   const matrixChart = matrixSVG.append("g");
  //   matrixChart.append(() => renderHeatmap(matrix, this.config).svg);

  // }

 setConfig = (w, h) => {    
    this.config = {
    w,
    h,
    width: w / 3,
    height: h / 2,
    size: [w / 2, h / 2],
  }
}

}


export { Adjacency };
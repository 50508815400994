export default class Workflow {
  constructor() {}

  orchestrate() {

    let workflows = m.orchestrate;
    let workers = {};
    list(workflows, (meta, workflow) => {
      let name = "orchestrate" + m.str.capitalize(meta.key);
      m.methods[name] = newFunctionFromWorkflow(workflow, name);
      workers[name] = m.methods[name];
      if (workflow.expect) {
        expect(m.methods[name], workflow.expect.prerequisits || [], workflow.expect.name || null, workflow.expect.message || "", workflow.expect.finalStage || null);
      } else {
        expect(m.methods[name], [], name, "", null);
      }
    });
  }

  newFunctionFromWorkflow(workflow, name) {
    let script = "";
    if (workflow.doc) script += "let doc = g." + workflow.doc + ";\n";
    list(workflow.flow, (flowMeta, flow) => {
      script += flowMeta.key + "(";

      let propsString = "";
      list(flow, (propMeta, prop) => {
        if (propsString !== "") propsString += ",";
        propsString += prop;
      });

      script += propsString + ");\n";
    });
    script += "lockDuringWaiting('" + name + "');";
    return new Function("...args", script);
  }

  list(obj, callback) {
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var i;
        let newArguments = [];
        for (i = 2; i < arguments.length; i++) {
          if (arguments[i]) newArguments.push(arguments[i]);
        }
        let value = obj[key];
        let meta = { key, position: i, list: obj };
        callback(meta, value, newArguments);
      }
    }
  }
}

export default class Obj {
  constructor() {
    Array.prototype.isNull = this.isNull
    Array.prototype.hasValue = this.hasValue
    Array.prototype.mergeDictionary = this.mergeDictionary
    Array.prototype.dictKeysToArray = this.dictKeysToArray
    
    Array.prototype.getKeys = this.getKeys
    Array.prototype.getValues = this.getValues
    Array.prototype.getObjects = this.getObjects
  }

  exists (x) {x != null}

  isObject (val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
  }

  ensureExists(obj) {
      if (!obj || typeof obj === "undefined") obj = {};
      return obj;
  }

  isNull(obj = this) {
    if (!obj || obj === undefined || obj == {}) {
      return true;
    } else {
      return false;
    }
  }

  hasValue(obj = this) {
    if (this.isNull(obj)) {
      return false;
    } else {
      return true;
    }
  }

  //return an array of objects according to key, value, or key and value matching
  getObjects(obj = this, key, val) {
      var objects = [];
      for (let i in obj) {
          if (!obj.hasOwnProperty(i)) continue;
          if (typeof obj[i] == 'object') {
              objects = objects.concat(getObjects(obj[i], key, val));
          } else
          //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
          if (i == key && obj[i] == val || i == key && val == '') { //
              objects.push(obj);
          } else if (obj[i] == val && key == '') {
              //only add if the object is not already in the array
              if (objects.lastIndexOf(obj) == -1) {
                  objects.push(obj);
              }
          }
      }
      return objects;
  }
  
  //return an array of values that match on a certain key
  getValues(obj = this, key) {
      var objects = [];
      for (let i in obj) {
          if (!obj.hasOwnProperty(i)) continue;
          if (typeof obj[i] == 'object') {
              objects = objects.concat(getValues(obj[i], key));
          } else if (i == key) {
              objects.push(obj[i]);
          }
      }
      return objects;
  }
  
  //return an array of keys that match on a certain value
  getKeys(obj = this, val) {
      var objects = [];
      for (let i in obj) {
          if (!obj.hasOwnProperty(i)) continue;
          if (typeof obj[i] == 'object') {
              objects = objects.concat(getKeys(obj[i], val));
          } else if (obj[i] == val) {
              objects.push(i);
          }
      }
      return objects;
  }

  mergeDictionary (dict1 = this, dict2) {

    try {
      return Object.assign({}, dict1, dict2);;
    }
    catch (e) {
      return e.message;
    }

  }

  dictKeysToArray (obj = this, separator) {

    var arr = [];
    try {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          arr.push("'" + key + "'");                          //key + '=' + obj[key]
        }
      }
      ;
      var result = arr.join(separator);
      return result;
    }
    catch (e) {
      return e.message;
    }

  }

}

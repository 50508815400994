let nodesFromMatrix = (entities, columnLabel) => {
	return {
		cols: columnLabel.map(({ id }) => id),
		rows: entities.map(({ id }) => id),
		background: background(val),
	};
};

let graphFromMatrix = (entities, columnLabel) => {
	return {
		nodes: [].concat(entities, columnLabel),
		links: d3.merge(
			entities.map((entity, i) =>
				columnLabel
					.map((feature, j) => ({
						source: i, // entity's index within nodes
						target: entities.length + j,
						value: mat0.get(i, j),
					}))
					.filter((d) => d.value > 0)
			)
		),
	};
};

const noOp = () => {};

const apply_dijw = (fn = noOp, width) =>
	function (d, i) {
		let x = width - 1;
		return fn.call(this, d, Math.floor(i / width), i % width, x);
	};

let kSumEq = (m, i, j) => tex`c_{${i + 1}${j + 1}} = \displaystyle\sum_{k=1}^${m + 1} a_{${i + 1}k} \cdot b_{k${j + 1}}`;

let abSumEq = (m, i, j) => {
	const color = d3.scaleSequential(d3.interpolateWarm).domain([0, m]);
	return d3.range(0, m).map((k) => componentEq(m, k, color(k), `c_{${i + 1}${j + 1}}`, `a_{${i + 1}${k + 1}}`, `b_{${k + 1}${j + 1}}`));
};

let valSumEq = (m, i, j, A, B, C) => {
	const color = d3.scaleSequential(d3.interpolateWarm).domain([0, m]);
	const format = d3.format(".1f");
	return d3.range(0, m).map((k) => componentEq(m, k, color(k), `c_{${i + 1}${j + 1}}`, format(A.get(i, k)), format(B.get(k, j)), format(C.get(i, j))));
};

let componentEq = (m, k, color, c, a, b, d) => `<span>
            ${k === 0 ? `<span><span style=color:transparent>${tex`${c}`}</span>&nbsp;${tex` = `}&nbsp;</span>` : ``}
            <span style="color:${color}">${tex`${a} \cdot ${b}`}</span>
            ${k < m - 1 ? `<span>${tex`+`}</span>` : d ? `<span>${tex`=${d}`}</span>` : ``}
        </span>`;

let inputMatrix = () => {
	let matrix = [];
	for (let j = 0; j < size; j++) {
		let Arr = [];
		for (let i = 0; i < j + 1; i++) {
			Arr.push(Math.floor(Math.random() * 10 + 1));
		}
		matrix.push(Arr);
	}
	for (let i = 0; i < size; i++) {
		matrix[i][i] = 0;
	}
	for (let j = 0; j < size; j++) {
		for (let i = j + 1; i < size; i++) {
			matrix[j].push(matrix[i][j]);
		}
	}
	return matrix;
};

let adjacencyMatrix = () => {
	let w = 1,
		h = 1,
		value = 1;

	function layout(columnLabels, sourceMatrix, rowLabels) {
		const lenh = rowLabels.length;
		const lenw = columnLabels.length;
		let cellh = 15; //(h / lenh).toFixed(0)
		let cellw = cellh; // (w / lenw).toFixed(0)

		const resultMatrix = [];
		for (let s = 0; s < lenh; s++) {
			for (let t = 0; t < lenw; t++) {
				let cell = sourceMatrix[s][t];
				const v = +cell.value;
				const rect = { ...cell, ...{ x: parseInt((t * cellw).toFixed(0)), y: parseInt((s * cellh).toFixed(0)), w: parseInt(cellw), h: parseInt(cellh) } };
				if (v > 0) {
					let row = rowLabels[s];
					let column = columnLabels[t];
					let title = `${row.id} vs ${column.id}`;
					const edge = { ...{ title, source: row, target: column, value: (value = v.toFixed(1)) }, ...cell };
					resultMatrix.push(Object.assign(edge, rect));
				} else {
					resultMatrix.push(Object.assign({}, rect));
				}
			}
		}
		return resultMatrix;
	}

	layout.size = function (array) {
		return arguments.length ? ((w = +array[0]), (h = +array[1]), layout) : [w, h];
	};

	return layout;
};

export { nodesFromMatrix, graphFromMatrix, noOp, apply_dijw, inputMatrix, adjacencyMatrix };

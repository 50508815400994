import Common from "./source/common";

(function () {
  
  var g, m, mesh;
  if (typeof window !== "undefined") {
    window.g = window;
    g = window;
  } else if (typeof self !== "undefined") {
    self.g = self;
    g = self;
  } else if (typeof global !== "undefined") {
    global.g = global;
    g = global;
  }

  if (!g.m) {
    g.m = {};
    g.m.globalcheck = true;
  }
  m = g.m;
  g.mesh = g.m;
  mesh = g.mesh;

  m.common = new Common();
  
})();
export default class Load {
  constructor() {}

  async fetchHtmlAsText(url) {
    let html = await (await fetch(url)).text();
    return html;
  }

  async fetchAsync (url) {
    let response = await fetch(url);
    let text = await response.text();
    return text;
  }

  getHTMLAsText(url) {
    let output = "";

    /* Make an HTTP request using the attribute value as the file name: */
    xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          output = this.responseText;
        } else {
          output = "Template not found:" + this.status;
        }
      }
    };
    xhttp.open("GET", url, false);
    xhttp.onerror = function () {
      m.log.report("** An error occurred during the transaction");
    };
    xhttp.send();

    return output;
  }

  xhrSuccess() {
    this.callback.apply(this, this.arguments);
  }

  xhrError() {
    m.log.error(this.statusText);
  }

  loadFile(url, callback /*, opt_arg1, opt_arg2, ... */) {
    var xhr = new XMLHttpRequest();
    xhr.callback = callback;
    xhr.arguments = Array.prototype.slice.call(arguments, 2);
    xhr.onload = this.xhrSuccess;
    xhr.onerror = this.xhrError;
    xhr.open("GET", url, true);
    xhr.send(null);
  }

  loadScript(src, onload) {
    let s = m.env.doc.createElement("script");
    s.onload = onload
      ? onload
      : function (e) {
          m.log.report(e.target.src + " is loaded.");
        };
    s.src = src;
    s.charset = "UTF-8";
    s.async = false;
    //m.env.doc.head.appendChild(s);
    m.env.doc.body.insertBefore(s, m.env.doc.body.firstChild);

    let cb = onload; //function (e) {};
    if (typeof cb === "function") {
      s.onload = cb;
      s.onreadystatechange = function () {
        /loaded|complete/.test(s.readyState) && cb(s);
      };
    }
  }
  
}

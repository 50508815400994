export default class Str {
  constructor() {
    let that = this
    String.prototype.slugify = this.slugify.bind()
    //String.prototype.hash = this.hashString.bind()
    String.prototype.regexEscape = this.regexEscape.bind()
    String.prototype.keysToLowerCase = this.keysToLowerCase.bind()
    String.prototype.cleanText = this.cleanText.bind()
    String.prototype.cleanSentence = this.cleanSentence.bind()
    // String.prototype.replaceAll = this.replaceAll.bind()
    String.prototype.deleteUselessChars = this.deleteUselessChars.bind()
    String.prototype.getAllMatches = this.getAllMatches.bind()
    String.prototype.capitalize2 = this.capitalize.bind()
    String.prototype.capitalize = function() {
      return this.charAt(0).toUpperCase() + this.slice(1)
    }
    String.prototype.toEventName = function() {
      const first2 = this.slice(0, 2);
      if (first2 === "on") {return this} else {return "on" + this.capitalize()}
    }
    String.prototype.slug = function() {
      return m.str.slugify(this)
    }
    Date.prototype.yyyymmddhh = function() {
      var yyyy = this.getFullYear().toString();
      var mm = (this.getMonth()+1).toString(); // getMonth() is zero-based
      var dd  = this.getDate().toString();
      var hh = this.getHours().toString();
      return yyyy + (mm[1]?mm:"0"+mm[0]) + (dd[1]?dd:"0"+dd[0]) + (hh[1]?hh:"0"+hh[0]); // padding
    };
    var todayDate = new Date();
    Date.prototype.today = todayDate.yyyymmddhh();/*from w  w w. j av a2  s  .  c om*/
    var nowDate = new Date();
    var lastWeek = new Date(nowDate.getTime()-1000*60*60*24*7);
    Date.prototype.lastWeek = lastWeek.yyyymmddhh();
    var lastMonth = new Date(nowDate.getTime()-1000*60*60*24*30);
    Date.prototype.lastMonth = lastMonth.yyyymmddhh();
    var lastYear = new Date(nowDate.getTime()-1000*60*60*24*30*12);
    Date.prototype.lastYear = lastYear.yyyymmddhh();
    var lastDecade = new Date(nowDate.getTime()-1000*60*60*24*30*12*10);
    Date.prototype.lastDecade = lastDecade.yyyymmddhh();

  }

  capitalize = (aString) => {
    if (typeof aString !== "string") return "";
    return aString.charAt(0).toUpperCase() + aString.slice(1);
  };

  toOn = (aString) => {
    const first2 = aString.slice(0, 2);
    if (first2 === "on") {return aString} else {return "on" + capitalize (aString)}
  };

  regexEscape (text) {

    let str = String(text);
    let cpList = Array.from(str[Symbol.iterator]());
    let cuList = [];
    for(let c of cpList){
      if("^$\\.*+?()[]{}|".indexOf(c) !== -1){
        cuList.push("\\");
      }
      cuList.push(c);
    }
    let output = cuList.join("");
    return output;

  }

  keysToLowerCase (el) {
    let key, keys = Object.keys(el);
    let n = keys.length;
    let newobj = {}
    while (n--) {
      key = keys[n];
      newobj[key.toLowerCase()] = el[key];
    }
    return newobj;
  }

  cleanText (text) {
    if (text && text.includes(' ')) {
      let output = text;
      let multiplespaces = /  +/g;
      output = output.replace(/  +/g, ' ');

      let lineFeed = /\n/g;
      output = output.replace(lineFeed, ' ');
      let endMess = /[\\.,!;:\-'~+#*=_”]+$/;
      output = output.replace(endMess, '');
      let startMess = /^[\\.,!;:\-'~+#*=_”]/;
      output = output.replace(startMess, '');
      output = output.trim();
      return output;
    }
  }

  cleanSentence (text) {
    if (text && text.includes(' ')) {
      let output = text;
      output = helper.replaceAll(output,"&nbsp;"," ");
      output = output.replace(/  +/g, ' ');

      let lineFeed = /\n/g;
      output = output.replace(lineFeed, ' ');

      let tabs = /\t/g;
      output = output.replace(tabs, ' ');

      output = output.replace(/  +/g, ' ');

      output = output.trim();
      return output;
    }
    return text.trim();
  }

  // replaceAll (text, search, replacement) {
  //   let output = text.split(search).join(replacement);
  //   return output;
  // }

  deleteUselessChars (word) {
    return word.replace(/[^\w]/g, "");
  }

  getAllMatches (text, regex) {
    if (regex.constructor !== RegExp) {
      throw new Error('not RegExp');
    }

    return (text.match(regex) || []).map(e => RegExp(regex.source, regex.flags).exec(e));
  }

  slugify (aString, separator = "") {
      return aString
          .toString()
          .normalize('NFD') // split an accented letter in the base letter and the acent
          .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
          .replace(/\s+/g, separator)
          .replace(/\((.+?)\)/g, "$1");
  };
  
  hashString(aString) {
  
      if (aString && aString !== "") {
  
          let replaceWith = '';
  
          aString.replaceAll("(", replaceWith);
          aString.replaceAll(")", replaceWith);
          aString = aString.slugify(replaceWith);
  
          aString = aString.toLowerCase();
          aString = aString.replace(new RegExp(" ", 'g'), replaceWith);
          aString = aString.replace(new RegExp("_", 'g'), replaceWith);
          aString = aString.replace(new RegExp("%20", 'g'), replaceWith);
          aString = aString.replace(new RegExp("%c2%a7", 'g'), replaceWith);
          aString = aString.replace(new RegExp("§", 'g'), replaceWith);
          aString = aString.replace(new RegExp("#", 'g'), replaceWith);
  
          if (Array.prototype.reduce) {
              return aString.split("").reduce(function(a, b) {
                  a = ((a << 5) - a) + b.charCodeAt(0);
                  return a & a
              }, 0).toString().replace("-", "");
          }
  
          let hash = 0;
          if (aString.length === 0) return hash.toString().replace("-", "");
  
          for (let i = 0; i < aString.length; i++) {
              let character = aString.charCodeAt(i);
              hash = ((hash << 5) - hash) + character;
              hash = hash & hash; // Convert to 32bit integer
          }
  
          if (hash < 0) hash = hash * -1;
          return hash.toString().replace("-", "");
  
          if (typeof astring !== "undefined") {} else {
              //checkpointMessage(true, "info","Hash undefined: '" + aString + "'");
              return "undefined";
          }
  
      } else {
          //checkpointMessage(true, "info","Hash undefined: '" + aString + "'");
          return "undefined";
      }
  
  }
  
}

"use strict";

export default class Json {
  constructor() {
    let that = this;

    this.DEFAULT_MAX_DEPTH = 6;
    this.DEFAULT_ARRAY_MAX_LENGTH = 50;
    this.seen = []; // Same variable used for all stringifications

    Date.prototype.toPrunedJSON = Date.prototype.toJSON;
    String.prototype.toPrunedJSON = String.prototype.toJSON;

    const safeStringify = require("fast-safe-stringify");

    JSON.serialize = (o) => {
      function replacer(key, value) {
        
        if (key === "host" || key === "env" || key === "m" || key === "self" || key === "window" || key === "global") {
          return;
        }

        if (value.host) {
          delete value.host;
          return;
        }

        if (value.key) {
          delete value.key;
          return;
        }

        if (value.m) {
          return;
        }

        if (value.self) {
          return;
        }

        if (value.window) {
          return;
        }

        if (value.global) {
          return;
        }
        if (value.document) {

          delete value.document;
        }

        for (var prop in value) {
          if (typeof value[prop] === "object") {
            if (value[prop].host) {
              delete value[prop].host; //.document;
            }
            if (value[prop].key) {
              delete value[prop].key; //.document;
            }
            if (value[prop].document) {
              delete value[prop]; //.document;
            }
            if (value[prop].m || value[prop].self || value[prop].window || value[prop].global || value[prop].alert) {
              delete value[prop];
            }
          }
        }

        if (value === "[Circular]") {

          return;
        }

        if (value.host && value.host.self) {

          delete value.host;
        }
        if (value.frame && value.frame.self) {

          delete value.frame;
        }

        return value;
      }

      const options = {
        depthLimit: 50,
        edgesLimit: 50,
      };

      try {
          let str = safeStringify(o, replacer, 2, options);
          //let clone = cloneDeep(o)
          //let str = JSON.stringify(o);
          //if (str === JSON.stringify("[unable to serialize, circular reference is too complex to analyze]")) debugger;  str = "{error: 'Unable to serialize, circular reference is too complex to analyze.'}";
          return str;
      } catch (e) {
         // debugger
          return "{error: 'Unable to serialize, cross-origin access blocked.'}";
      }
    };

    this.cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
    this.escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
    this.meta = {
      // table of character substitutions
      "\b": "\\b",
      "\t": "\\t",
      "\n": "\\n",
      "\f": "\\f",
      "\r": "\\r",
      '"': '\\"',
      "\\": "\\\\",
    };

    JSON.pruned = function (value, depthDecr, arrayMaxLength) {
      this.seen = [];
      depthDecr = depthDecr || this.DEFAULT_MAX_DEPTH;
      arrayMaxLength = arrayMaxLength || this.DEFAULT_ARRAY_MAX_LENGTH;
      return m.json.str("", { "": value }, depthDecr, arrayMaxLength);
    };

    Object.assign(String.prototype, {
      //   isArray: () => that.isArray(this),
      //   mergeArrays: () => that.mergeArrays(this),
      //   findIndexInNLPArray: () => that.findIndexInNLPArray(this),
      //   hasValues: () => that.hasValues(this),
      //   intersectObjects: () => that.intersectObjects(this),
      //   intersectObjectsAndValues: () => that.intersectObjectsAndValues(this)
    });
  }
  //

  quote(string) {
    this.escapable.lastIndex = 0;
    return this.escapable.test(string)
      ? '"' +
          string.replace(this.escapable, function (a) {
            var c = this.meta[a];
            return typeof c === "string" ? c : "\\u" + ("0000" + a.charCodeAt(0).toString(16)).slice(-4);
          }) +
          '"'
      : '"' + string + '"';
  }

  str(key, holder, depthDecr, arrayMaxLength) {
    var i, // The loop counter.
      k, // The member key.
      v, // The member value.
      length,
      partial,
      value = holder[key];
    if (value && typeof value === "object" && typeof value.toPrunedJSON === "function") {
      value = value.toPrunedJSON(key);
    }

    switch (typeof value) {
      case "string":
        return quote(value);
      case "number":
        return isFinite(value) ? String(value) : "null";
      case "boolean":
      // case "null":
      //   return String(value);
      // case "undefined":
      //   return String(value);
      case "object":
        if (!value) {
          return "null";
        }
        if (depthDecr <= 0 || m.json.seen.indexOf(value) !== -1) {
          return '"-pruned-"';
        }
        this.seen.push(value);
        partial = [];
        if (Object.prototype.toString.apply(value) === "[object Array]") {
          length = Math.min(value.length, arrayMaxLength);
          for (i = 0; i < length; i += 1) {
            partial[i] = str(i, value, depthDecr - 1, arrayMaxLength) || "null";
          }
          v = partial.length === 0 ? "[]" : "[" + partial.join(",") + "]";
          return v;
        }
        for (k in value) {
          if (Object.prototype.hasOwnProperty.call(value, k)) {
            try {
              v = str(k, value, depthDecr - 1, arrayMaxLength);
              if (v) partial.push(quote(k) + ":" + v);
            } catch (e) {
              // this try/catch due to some "Accessing selectionEnd on an input element that cannot have a selection." on Chrome
            }
          }
        }
        v = partial.length === 0 ? "{}" : "{" + partial.join(",") + "}";
        return v;
    }
  }
}

//import mapper from './mapper.js'
import sharpMapper from 'sharp-mapper'

const schema = {    

        map: function(oldObject, schema) {
            const  REMOVE_UNDEFINED  =  false;
            let mappingSchema = this.transform(schema);
            var  mappedObject  =  sharpMapper.structureMap(oldObject, mappingSchema, REMOVE_UNDEFINED);

            return this.toJson(mappedObject);
        },

		mapResults: function (json, mapping, REMOVE_UNDEFINED  =  true) {
			var  mappedObject  = sharpMapper.structureMap(json, mapping, REMOVE_UNDEFINED);
			return mappedObject;
		},

        toJson: function(mappedObject) {

            let jsonObject = {};
            
            Object.keys(mappedObject).forEach(function (key) {

                let val = mappedObject[key];

                if (Array.isArray(val)) {
                    let outArray = [];
                    val.forEach(item => {
                        if (item['toJson']) outArray.push(item.toJson())
                    })
                    jsonObject[key] = outArray;
                } else if (typeof val === 'object') {
                    let outObject = {};
                    if (val['toJson'])  jsonObject[key] = val.toJson()
                } else {
                    jsonObject[key] = val;
                }

            });

            let outJson = JSON.parse(JSON.serialize(jsonObject));
            return outJson;
        },

        transform: function(schema) {

            let outSchema = {};
            
            Object.keys(schema).forEach(function (key) {
                let val = schema[key];
                if (val === true) outSchema[key] = "@" + key;
            });

            return outSchema;
        },

        isObject: function (val) {
            return (typeof val === 'object');
        }
        
}

export default schema
import Adjacency from "./source/classes/adjacency"
import * as THREE from "three";
    
  (function() {  
    let csv = "";
    const openPanelBtn = document.getElementById('openPanelBtn');
    const closePanelBtn = document.getElementById('closePanelBtn');
    const openRightPanelBtn = document.getElementById('openRightPanelBtn');
    const closeRightPanelBtn = document.getElementById('closeRightPanelBtn');
    const csvPanel = document.getElementById('csvPanel');
    const rightPanel = document.getElementById('rightPanel');
    const submitBtn = document.getElementById('submitBtn');
    const csvDataTextArea = document.getElementById('csvData');
    
    openPanelBtn.addEventListener('click', function() {
        csvPanel.classList.remove('hidden');
    });
    
    closePanelBtn.addEventListener('click', function() {
        csvPanel.classList.add('hidden');
    });
    
    openRightPanelBtn.addEventListener('click', function() {
        rightPanel.classList.remove('hidden');
    });
    
    closeRightPanelBtn.addEventListener('click', function() {
        rightPanel.classList.add('hidden');
    });


    

    document.addEventListener('DOMContentLoaded', function() {
      const openMatrixBtn = document.getElementById('openMatrixBtn');
      const cornerContainer = document.getElementById('cornerContainer');
      const csvDataTextArea = document.getElementById('csvData');
      const idColumnSelect = document.getElementById('idColumnSelect');
      const additionalColumnsList = document.getElementById('additionalColumnsList');
      const submitBtn = document.getElementById('submitBtn');
      const matrixTypeSelect = document.getElementById('matrixTypeSelect');

      openMatrixBtn.addEventListener('click', function() {
          // Toggle the 'hidden' class to show/hide the cornerContainer
          cornerContainer.classList.toggle('hidden');
      });

      const openQrCodeBtn = document.getElementById('openQrCodeBtn');
        const qrCodeOverlay = document.getElementById('qrCodeOverlay');
        const closeQrOverlayBtn = document.getElementById('closeQrOverlayBtn');
        const qrCodeContainer = document.getElementById('qrCodeContainer');

        // Function to generate QR Code
        const bwipjs = require('bwip-js');

  function generateQRCode(text) {
      const svg = bwipjs.toSVG({
          bcid: 'qrcode',  // Barcode type
          text: "tolle sache",  // Text to encode
          scale: 3,  // Scaling factor
          height: 70,  // Bar height, in millimeters
          width: 70,  // Bar width, in millimeters
          includetext: true,  // Don't include human-readable text
          textxalign: 'center',  // Always good to set this
          color: '#000000', // QR code color
    backgroundcolor: '#ffffff'
      });
      return svg;
  }

  function generateQRCodeImage(text) {
    // Create a canvas element
    const canvas = document.createElement('canvas');
        // Use bwip-js to draw the QR code on the canvas
        bwipjs.toCanvas(canvas, {
            bcid: 'qrcode', // Barcode type
            text: text, // Text to encode
            scale: 3, // Scaling factor
            height: 70, // Bar height, in millimeters
            width: 70, // Bar width, in millimeters
            includetext: true, // Include human-readable text
            textxalign: 'center', // Text alignment
            color: '#000000', // QR code color
            backgroundcolor: '#ffffff' // Background color
        });
        return canvas;
}


      // Usage
      openQrCodeBtn.addEventListener('click', function() {
        let url = 'https://staging.mesh.app?matrix='; // 'file:///E:/mesh.app/m.adjacency/index.html?matrix='; // 
        let text = url + encodeURI(mesh.input.csv)
        let canvas = generateQRCodeImage(text);
        // Create an img element and set its src to the generated image URL
        const img = document.createElement('img');
        img.src = canvas.toDataURL('image/png');
        qrCodeContainer.innerHTML = ''; // Clear the container
        qrCodeContainer.appendChild(img); // Insert the image into the container
        
    // Create a link element
    const link = document.createElement('a');
    link.href = text; // Set the href to the URL encoded in the QR code
    link.textContent = 'Share this!'; // Set the link text
    link.style.display = 'block'; // Ensure the link is on a new line
    link.style.textAlign = 'center'; // Center-align the link
    link.style.marginTop = '10px'; // Add some space above the link

    qrCodeContainer.appendChild(link); // Append the link under the QR code


        qrCodeOverlay.classList.remove('hidden'); // Show the overlay
      });

      //   // Open QR Code Overlay
      //   openQrCodeBtn.addEventListener('click', function() {
      //     const svgMarkup = generateQRCode(csv); // Replace with your actual CSV variable
      //     qrCodeContainer.innerHTML = svgMarkup; // Insert the SVG into the container
      //     qrCodeOverlay.classList.remove('hidden'); // Show the overlay
      // });

        // Close QR Code Overlay
        closeQrOverlayBtn.addEventListener('click', function() {
            qrCodeOverlay.classList.add('hidden');
        });
    
      // Add event listener for the 'change' event
      matrixTypeSelect.addEventListener('change', function() {
        // Get the selected value
        const selectedLevel = matrixTypeSelect.value;
    
        // Call the renderChangedLevel function with the selected value
        renderChangedLevel(selectedLevel);
      });
    
      const idOptions = ['id', 'name', 'title', 'Id', 'Name', 'Title', 'ID', 'NAME', 'TITLE', 'label','Label','LABEL','key','Key','KEY'];
      // Function to display the 'id' column dropdown
      function populateIdColumnSelect(columns) {
        idColumnSelect.innerHTML = columns.map(column => {
          const isSelected = idOptions.includes(column) ? ' selected' : '';
          return `<option value="${column}"${isSelected}>${column}</option>`;
        }).join('');
      }
    
// Function to display checkboxes for additional columns, preselecting numeric columns
function displayAdditionalColumns(columns, data) {
  additionalColumnsList.innerHTML = columns.map(column => {
    const isNumeric = isColumnNumeric(data, column);
    const checkedAttribute = isNumeric ? 'checked' : ''; // Preselect if numeric
    return `
      <label class="inline-flex items-center mt-3">
        <input type="checkbox" class="form-checkbox h-5 w-5" value="${column}" ${checkedAttribute}><span class="ml-2 text-gray-700">${column}</span>
      </label>
    `;
  }).join('');
}

// Revised isColumnNumeric to better suit the requirement
function isColumnNumeric(data, columnName) {
  // Assuming at least one data row exists, check if a column is numeric by examining row values
  let numericCount = 0;
  for (let i = 0; i < data.length; i++) {
    if (!isNaN(parseFloat(data[i][columnName])) && isFinite(data[i][columnName])) {
      numericCount++;
    }
  }
  // Consider a column numeric if the majority of its values are numeric
  return numericCount > data.length / 2;
}

    
      // Event listener for pasting CSV data
      csvDataTextArea.addEventListener('input', function() {
        Papa.parse(csvDataTextArea.value, {
          complete: function(results) {
            const columns = results.meta.fields;
            populateIdColumnSelect(columns);
            // Pass parsed data for numeric check, skipping headers automatically as they're not included in `data`
            displayAdditionalColumns(columns.filter(column => !idOptions.includes(column)), results.data);
          },
          header: true
        });
      });

    
      // Event listener for the submit button
      submitBtn.addEventListener('click', function() {
        const selectedIdColumn = idColumnSelect.value;
        const selectedAdditionalColumns = Array.from(document.querySelectorAll('#additionalColumnsList input[type="checkbox"]:checked')).map(input => input.value);
    
        // Re-parse the CSV to filter and rename columns based on selections
        Papa.parse(csvDataTextArea.value, {
          complete: function(results) {
            const filteredData = results.data.map(row => {
              const newRow = { 'id': row[selectedIdColumn] };
              selectedAdditionalColumns.forEach(column => {
                if (row[column] !== undefined) {
                  newRow[column] = row[column];
                }
              });
              return newRow;
            });
    
            // Convert the filtered data back to CSV
            const newCsv = Papa.unparse(filteredData, {
              delimiter: ";",
              newline: "\n"
            });
    
            // Process the new CSV
            renderNewCSV(newCsv);
          },
          header: true
        });
      });
    });

    
    


     
    require("m.common");

    window.m = window.m ? window.m : {}
    mesh = window.m;
    mesh.adjacency = mesh.adjacency ? mesh.adjacency : {}    
    mesh.Space = THREE
    mesh.input = {};

    //mesh.plugin.add(Adjacency, "Adjacency") 
    if (mesh.mods) mesh.mods.instantiate(Adjacency, "Adjacency", "adjacency", "run"); else mesh.adjacency = new Adjacency()
    require('m.graph')

    
    let queryData = {};
    let postedData = {};

    postedData.matrix = "<%= postedMatrix %>";

    let searchParams = new URLSearchParams(window.location.search);
    let matrixParam = searchParams.get('matrix');
    let search = location.search.substring(1);
    if(search) {
        let pairs = search.split('&');
        for(let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            queryData[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
    }
    if (queryData.matrix) {
        csv = queryData.matrix;
        // try {
        //     // Assuming the matrix is compressed using LZString
        //     //let decodedURIMatrix = decodeURIComponent(queryData.matrix);
        //     let decompressedMatrix = readCompressedMatrix(queryData.matrix);
        //     if (decompressedMatrix) {
        //         // Assuming JSON format for simplicity, adjust according to your actual format
        //         query.decodedMatrix = JSON.parse(decompressedMatrix);
        //     } else {
        //         console.error('Matrix parameter is not in the expected encoded format.');
        //     }
        // } catch (error) {
        //     console.error('Error decoding matrix parameter:', error);
        // }
    }

    function preprocessData(data) {
      const rows = data.split('\n');
      const headers = rows[0].split(';');
      const uniqueTexts = {};
      const columnDefaults = new Array(headers.length).fill(null);

      const processedRows = rows.slice(1).map(row => {
          return row.split(';').map((cell, colIdx) => {
              if (!uniqueTexts[cell]) uniqueTexts[cell] = Object.keys(uniqueTexts).length + 1;
              if (columnDefaults[colIdx] === null) {
                  columnDefaults[colIdx] = cell;
              } else if (columnDefaults[colIdx] !== cell) {
                  columnDefaults[colIdx] = null;
              }
              return uniqueTexts[cell];
          });
      });

      const filteredColumnDefaults = columnDefaults.filter(val => val !== null);
      const filteredHeaders = headers.filter((_, idx) => columnDefaults[idx] === null);
      const filteredRows = processedRows.map(row => row.filter((_, idx) => columnDefaults[idx] === null));

      return { headers: filteredHeaders, rows: filteredRows, uniqueTexts, columnDefaults: filteredColumnDefaults };
  }

  function serializeData({ headers, rows, uniqueTexts, columnDefaults }) {
      const serializedHeaders = headers.join(';');
      const serializedRows = rows.map(row => row.join(';')).join('|');
      const serializedTexts = Object.entries(uniqueTexts).map(([text, code]) => `${code}:${text}`).join(';');
      const serializedDefaults = columnDefaults.join(';');

      return `${serializedHeaders}|${serializedRows}|${serializedTexts}|${serializedDefaults}`;
  }

  function compressAndEncode(data) {
      const compressedData = LZString.compressToEncodedURIComponent(data);
      return compressedData;
  }

  function compressMatrix(matrix) {
      const preprocessed = preprocessData(matrix);
      const serialized = serializeData(preprocessed);
      return compressAndEncode(serialized);
  }

  function decompressAndDecode(compressedData) {
      return LZString.decompressFromEncodedURIComponent(compressedData);
  }

  function deserializeData(serializedData) {
      const [serializedHeaders, serializedRows, serializedTexts, serializedDefaults] = serializedData.split('|');
      const headers = serializedHeaders.split(';');
      const rows = serializedRows.split('|').map(row => row.split(';').map(Number));
      const uniqueTexts = serializedTexts.split(';').reduce((acc, textCodePair) => {
          const [code, text] = textCodePair.split(':');
          acc[code] = text;
          return acc;
      }, {});
      const columnDefaults = serializedDefaults.split(';').map(val => (val === '' ? null : Number(val)));

      return { headers, rows, uniqueTexts, columnDefaults };
  }

  function postprocessData({ headers, rows, uniqueTexts, columnDefaults }) {
      const reversedTexts = Object.fromEntries(Object.entries(uniqueTexts).map(([code, text]) => [Number(code), text]));
      const fullRows = rows.map(row => {
          const fullRow = new Array(headers.length + columnDefaults.length).fill(null);
          let colIdx = 0;
          columnDefaults.forEach((defaultVal, idx) => {
              if (defaultVal !== null) {
                  fullRow[idx] = reversedTexts[defaultVal];
              } else {
                  fullRow[idx] = reversedTexts[row[colIdx++]];
              }
          });
          return fullRow;
      });

      return { headers: [...headers, ...columnDefaults.map((_, idx) => `Default-${idx}`)], rows: fullRows };
  }
  
  function readCompressedMatrix(compressedData) {      
      const decompressedData = decompressAndDecode(compressedData);
      const deserializedData = deserializeData(decompressedData);
      const postprocessedData = postprocessData(deserializedData);
      console.log(postprocessedData);
  }

  // Example Usage:
  const data = `List Type;Cluster Name;Order Number;List Name;Title;Excerpt;Google Keywords;Total Points;Decade-ago;5 years ago;Only just;Today;Short-term future;Mid-term future;Long-term future;Growth;Acceleration
  Predictive Weighted List;Technological;1;Top 50 Overall Factors Performance;AI-Driven Automation;Low-Code platforms enhance productivity by using AI to automate coding and streamline application development processes.;AI-driven automation low-code, AI automating coding, AI in application development, AI low-code tools;95;20;40;60;80;90;95;98;85;90
  Predictive Weighted List;Technological;2;Top 50 Overall Factors Performance;IoT Device Management;Low-Code platforms facilitate the integration and management of IoT devices, allowing for real-time data processing and operational efficiency.;IoT device management low-code, IoT integration platforms, real-time IoT data, IoT operational efficiency;90;25;45;65;85;90;95;97;80;85
  ...`; // and so on with the remaining data

  let postedMatrix3 = `id;Pet;Wild;Safari;Predator;Intelligence;Size;Occurence
  Dog;0.7;0.1;0.1;0.2;0.2;0.5;0.8
  Cat;0.6;0.4;0.1;0.5;0.2;0.3;0.9
  Mouse;0.1;0.7;0.1;0.4;0.1;0.05;0.9
  Monkey;0.1;0.5;0.6;0.1;0.4;0.9;0.3
  Donkey;0.2;0.5;0.3;0.05;0.05;1.5;0.5
  Lion;0.0;0.1;0.9;0.9;0.2;1.4;0.05>>
  Snail;0.0;0.9;0.1;0.0;0.05;0.4;0.1
  Human;0.8;0.0;0.1;1.8;1;1;1`;

  // Example usage:
  //const compressedData = compressMatrix(data); // Assuming `data` is the string from your example
  //console.log(compressedData);
  

    let csv9 = `id	Prevalence	Impact on Quality of Life	Impact on Mobility	Responsiveness to Treatment	Duration of Symptoms	Risk of Complications	Necessity for Professional Care	Ease of Self-Management
    Pain and Tenderness	95	90	85	75	70	65	60	55
    Swelling	75	85	80	70	65	60	55	50
    Stiffness	70	80	75	65	60	55	50	55
    Weakness	65	75	85	60	55	50	45	60
    Popping or Crunching Noises	60	65	60	55	50	70	65	45
    Rest	90	85	80	75	70	65	60	85
    Ice Therapy	85	80	75	70	65	60	55	90
    Physical Therapy	95	90	85	95	90	85	80	60
    Elevation	80	75	70	65	60	55	50	75
    Compression	75	70	65	60	55	50	45	80`;
    
    let csv4 = `id;Pet;Wild
    Dog;0.7;0.1
    Cat;0.6;0.4
    Mouse;0.1;0.7
    Monkey;0.1;0.5`;
    
    let csv3 = `id;Pet;Wild;Safari;Predator;Intelligence;Size;Occurence
    Dog;0.7;0.1;0.1;0.2;0.2;0.5;0.8
    Cat;0.6;0.4;0.1;0.5;0.2;0.3;0.9
    Mouse;0.1;0.7;0.1;0.4;0.1;0.05;0.9
    Monkey;0.1;0.5;0.6;0.1;0.4;0.9;0.3
    Donkey;0.2;0.5;0.3;0.05;0.05;1.5;0.5
    Lion;0.0;0.1;0.9;0.9;0.2;1.4;0.05
    Snail;0.0;0.9;0.1;0.0;0.05;0.4;0.1
    Human;0.8;0.0;0.1;1.8;1;1;1`;

    let csv1 = `id	Friendliness	Responsiveness	Quality of Information	Value for Money	Atmosphere	Accessibility	Facilities	Historical Interest	Aesthetics
    Audio Guide	85	80	95	70	85	80	70	90	85
    Staff	95	90	80	70	90	80	75	85	80
    Guided Tour	90	85	98	80	95	75	80	90	85
    Cafe	80	65	80	65	85	70	75	75	80
    Gift Shop	90	85	75	85	80	75	80	80	80
    Entrance Fee	60	0	60	55	80	60	65	70	0`;

    let csv11 = `Topics,Zielsetzung,Motivation,Planung,Überwachung,Anpassungsfähigkeit,Wissensstand,Ressourcenverfügbarkeit,Hindernisse,Erfolgsbilanz,Gesundheitsbewusstsein
    Ernährungsgewohnheiten,3,4,3,4,3,4,4,3,3,4
    Kalorienzufuhr,5,4,4,5,3,4,4,2,4,4
    Nährstoffbalance,4,3,3,4,3,4,3,2,3,4
    Wasserzufuhr,3,3,2,4,3,3,4,1,3,3
    Trainingsintensität,3,4,4,4,4,4,3,3,3,3
    Trainingshäufigkeit,4,5,5,5,4,3,3,3,4,3
    Schlafqualität,2,3,3,3,4,2,4,2,3,4
    Stressmanagement,2,4,3,3,4,3,3,4,2,4
    Soziale Unterstützung,3,4,3,3,3,2,3,3,4,3
    Medizinische Faktoren,1,2,2,3,3,4,2,5,2,4`;
    
    let csvSample = `id;Protein Quality;Fiber Content;Vitamin Diversity;Mineral Diversity;Healthy Fats;Low in Added Sugars;Low in Unhealthy Fats;Antioxidant Content;Low in Sodium;Hydration
    Spinach;20;40;100;60;10;100;100;80;70;10
    Salmon;80;10;40;30;100;90;90;20;40;5
    Blueberries;10;60;70;20;10;100;100;100;80;15
    Nuts;70;50;30;50;90;60;70;30;30;5
    Broccoli;30;70;90;100;20;100;100;70;90;10
    Sweet Potatoes;40;80;60;40;10;80;80;50;60;15
    Garlic;5;20;10;10;0;100;100;60;50;5
    Avocado;15;30;20;15;80;70;75;10;20;10
    Quinoa;85;90;50;70;30;100;100;40;100;20
    Greek Yogurt;90;5;15;5;50;40;50;5;80;100
    Almonds;75;35;25;80;70;75;60;25;10;0
    Lentils;100;100;55;90;5;100;100;35;85;15
    Kale;25;45;80;75;15;100;100;90;75;10
    Apples;5;65;35;25;5;100;100;55;65;80
    Chia Seeds;60;95;45;65;60;100;80;75;45;20
    Eggs;95;0;50;20;40;85;85;0;55;10
    Beets;50;75;65;85;20;90;90;85;50;15
    Olive Oil;0;10;5;0;95;95;65;15;25;5
    Turmeric;10;15;30;35;10;100;100;95;45;5
    Flaxseeds;65;85;40;55;85;100;85;65;35;10
    `;
    
    let csv10 = `List Type;Order Number;Book Title;Chapter Title;Title;Excerpt;Google Keywords;Relevance;Character Development;Plot Complexity;Emotional Engagement;Cultural Impact;Historical Significance;Adaptation Potential;Moral Message;Educational Value;Total Points
    Weighted Book's Topic List;1;Little Lord Fauntleroy;;Wealth and Class;Exploration of the social hierarchies and the impact of wealth on relationships and behavior."Little Lord Fauntleroy wealth class distinction","social hierarchy in Victorian England","impact of wealth on relationships";70;60;55;65;70;60;75;65;60;65
    Weighted Book's Topic List;2;Little Lord Fauntleroy;;Childhood Innocence;The central theme of the purity and innocence of childhood shaping the adults around them."childhood innocence in literature","effect of children on adults","Little Lord Fauntleroy childhood themes";80;75;50;80;55;40;50;80;75;70
    Weighted Book's Topic List;3;Little Lord Fauntleroy;;Transformation;The transformation of the Earl of Dorincourt through his relationship with his grandson."transformation through love","character development in Little Lord Fauntleroy","Earl of Dorincourt changes";75;85;60;70;45;55;65;75;60;70
    Weighted Book's Topic List;4;Little Lord Fauntleroy;;American versus British Ideals;Contrast between American openness and British reserve within the narrative."American vs British culture in literature","cultural contrast in Little Lord Fauntleroy","values clash in Little Lord Fauntleroy";65;55;60;55;65;70;60;55;50;60
    Weighted Book's Topic List;5;Little Lord Fauntleroy;;Role of Women;Roles and perceptions of women in the story, particularly the character of Mrs. Errol."role of women in Victorian literature","Mrs. Errol in Little Lord Fauntleroy","female representation in 19th century novels";55;50;45;50;60;65;55;60;55;55
    Weighted Book's Topic List;6;Little Lord Fauntleroy;;Legal and Inheritance Issues;The legal challenges and inheritance disputes that arise with Cedric's new status as a lord."inheritance disputes in literature","legal issues in Little Lord Fauntleroy","Cedric's inheritance challenge";65;60;70;60;50;55;70;65;70;65
    Weighted Book's Topic List;7;Little Lord Fauntleroy;;Moral Lessons;Various moral lessons conveyed through the story, such as kindness, generosity, and honesty."moral lessons in Little Lord Fauntleroy","teaching kindness in children's books","values in Victorian children's literature";85;80;55;90;70;50;80;90;85;80
    Weighted Book's Topic List;8;Little Lord Fauntleroy;;Perceptions of the Other;How characters perceive and are influenced by cultural and social differences."perceptions of cultural differences in books","social bias in Little Lord Fauntleroy","understanding the other in literature";70;65;60;75;65;60;65;70;70;70
    Weighted Book's Topic List;9;Little Lord Fauntleroy;;Identity and Self-Discovery;Cedric's journey in discovering his true identity and responsibilities as a lord."identity in children's literature","self-discovery in Little Lord Fauntleroy","growth and responsibilities in youth";80;85;65;85;75;45;75;80;80;80
    Weighted Book's Topic List;10;Little Lord Fauntleroy;;Power and Responsibility;The exploration of the responsibilities that come with power, exemplified by Cedric and the Earl."power dynamics in literature","responsibility in power","ethical leadership in Little Lord Fauntleroy";75;80;70;80;70;65;85;85;75;80
    Weighted Book's Topic List;1;Little Lord Fauntleroy;;Wealth and Class;Exploration of the social hierarchies and the impact of wealth on relationships and behavior."Little Lord Fauntleroy wealth class distinction","social hierarchy in Victorian England","impact of wealth on relationships";70;60;55;65;70;60;75;65;60;65
    Weighted Book's Topic List;2;Little Lord Fauntleroy;;Childhood Innocence;The central theme of the purity and innocence of childhood shaping the adults around them."childhood innocence in literature","effect of children on adults","Little Lord Fauntleroy childhood themes";80;75;50;80;55;40;50;80;75;70
    Weighted Book's Topic List;3;Little Lord Fauntleroy;;Transformation;The transformation of the Earl of Dorincourt through his relationship with his grandson."transformation through love","character development in Little Lord Fauntleroy","Earl of Dorincourt changes";75;85;60;70;45;55;65;75;60;70
    Weighted Book's Topic List;4;Little Lord Fauntleroy;;American versus British Ideals;Contrast between American openness and British reserve within the narrative."American vs British culture in literature","cultural contrast in Little Lord Fauntleroy","values clash in Little Lord Fauntleroy";65;55;60;55;65;70;60;55;50;60
    Weighted Book's Topic List;5;Little Lord Fauntleroy;;Role of Women;Roles and perceptions of women in the story, particularly the character of Mrs. Errol."role of women in Victorian literature","Mrs. Errol in Little Lord Fauntleroy","female representation in 19th century novels";55;50;45;50;60;65;55;60;55;55
    Weighted Book's Topic List;6;Little Lord Fauntleroy;;Legal and Inheritance Issues;The legal challenges and inheritance disputes that arise with Cedric's new status as a lord."inheritance disputes in literature","legal issues in Little Lord Fauntleroy","Cedric's inheritance challenge";65;60;70;60;50;55;70;65;70;65
    Weighted Book's Topic List;7;Little Lord Fauntleroy;;Moral Lessons;Various moral lessons conveyed through the story, such as kindness, generosity, and honesty."moral lessons in Little Lord Fauntleroy","teaching kindness in children's books","values in Victorian children's literature";85;80;55;90;70;50;80;90;85;80
    Weighted Book's Topic List;8;Little Lord Fauntleroy;;Perceptions of the Other;How characters perceive and are influenced by cultural and social differences."perceptions of cultural differences in books","social bias in Little Lord Fauntleroy","understanding the other in literature";70;65;60;75;65;60;65;70;70;70
    Weighted Book's Topic List;9;Little Lord Fauntleroy;;Identity and Self-Discovery;Cedric's journey in discovering his true identity and responsibilities as a lord."identity in children's literature","self-discovery in Little Lord Fauntleroy","growth and responsibilities in youth";80;85;65;85;75;45;75;80;80;80
    Weighted Book's Topic List;10;Little Lord Fauntleroy;;Power and Responsibility;The exploration of the responsibilities that come with power, exemplified by Cedric and the Earl."power dynamics in literature","responsibility in power","ethical leadership in Little Lord Fauntleroy";75;80;70;80;70;65;85;85;75;80
    `;

    if (queryData && queryData.matrix) {
      csv = queryData.matrix;
    } else if (((postedData && postedData.matrix) && postedData.matrix !== "<%= postedMatrix %>")) {
      csv = postedData.matrix;
    } else {
      csv = csvSample;
    }

    let weights = [{}, { "Protein Quality": 0.9, "Healthy Fats": 1.75, "Low in Added Sugars": 1.4, "Mineral Diversity": 2, "Vitamin Diversity": 1, Size: 2, Occurence: 4 }]; // Hund: 1.1, Katze: 1.2, Maus: 1.3, Affe: 1.4, Esel: 1.5, Löwe: 1.6, Schnecke: 1.7, Mensch: 1.8 

    let w = window.innerWidth;
    let h = window.innerHeight;
    let level = 5;
        
		let settings = {
      defaultClassificationFactor: 1, 
      defaultClassificationValue: 1, 
      defaultClassificationUnitName: "Fibonacci Number", 
      defaultClassificationMeasurableName: "Preference",
      defaultClassificationMentionName: "Adjacency classification weights from " + name,
      defaultTopicFactor: 10, 
      defaultTopicValue: 10, 
      defaultTopicUnitName: "Fibonacci Number", 
      defaultTopicMeasurableName: "Preference",
      defaultTopicMentionName: "Adjacency topic weights from " + name,
      defaultLinkFactor: 1, 
      defaultLinkValue: 1, 
      defaultLinkUnitName: "Count", 
      defaultLinkMeasurableName: "Mention",
      defaultLinkMentionName: "Adjacency matrix from " + name,
			value: 26,
			topCount: 30,
      level: level,
      w: w,
      h: h,
			margin: 20,
			maxNodeSize: h/10,
			minNodeSize: h/100,
			minNodeDistance: 25,
			maxNodeDistance: (h-(h/100))/3, //h-(h/100)-200,
			maxLinkOpacity: 0.35,
			minLinkWidth: 1,
			maxLinkWidth: 50,
			default_link_color: "blue",
      top: 15,
      "tooltip": {
        width: 370,
        height: 400
      },
      "classifierTexts": [
          "< -66%",
          "-66% to -33%",
          "-33% to 0%",
          "0% to 33%",
          "33% to 66%",
          "66% to 96%",
          "> 96%"
      ],
			color: d3.scaleSequential().range(["transparent", "blue"]),
      "colors": {
        "primary": [
            "#D04A02",
            "#EB8C00",
            "#FFB600",
            "#7D7D7D",
            "#DEDEDE",
            "#295477",
            "#299D8F"
        ],
        "secondary": [
            "#4577C9",
            "#00C6F7",
            "#3DD5B0",
            "#DB536A"
        ],
        "chart": [
            "#7D7D7D",
            "#FFB600",
            "#EB8C00",
            "#D04A02",
            "#DB536A",
            "#E0301E",
            "#4577C9",
            "#00C6F7",
            "#3DD5B0",
            "#DB536A",
            "#D04A02",
            "#EB8C00",
            "#FFB600",
            "#7D7D7D",
            "#295477",
            "#299D8F"
        ],
        "xheat": [
            "#BECFD9",
            "#8DA9C4",
            "#134074",
            "#113A69",
            "#13315C",
            "#0B2545",
            "#0A223F"
        ],
        "heat": [
          "#C2C2C2",
          "#BECFD9",
          "#8DA9C4",
            "#fc940a",
            "#fd830e",
            "#fd6713",
            "#fe1324"
        ],
        "xcold": [
            "#F5F5F5",
            "#E0E0E0",
            "#D6D6D6",
            "#CCCCCC",
            "#C2C2C2",
            "#BECFD9",
            "#8DA9C4"
        ],
        "cold": [
            "#FEFEFE",
            "#F5F5F5",
            "#EBEBEB",
            "#E0E0E0",
            "#D6D6D6",
            "#CCCCCC",
            "#C2C2C2"
        ]
    }
    }
    
    let sourceHref = mesh.env?.doc?.location.href || "http://test.test";
    mesh.input = {
      csv: csv,
      topCount: settings.topCount,
      weights: weights,
      settings: settings,
      level: settings.level,
      sourceHref: sourceHref
    }
    setData(mesh.input.csv,mesh.input.weights);    
    renderChange(mesh.input.level);
    
  })();

  function renderNewCSV(csv, name = "default"){
    window.m = window.m ? window.m : {}
    mesh = window.m;
    mesh.adjacency = mesh.adjacency ? mesh.adjacency : {}    
    if (mesh.mods) mesh.mods.instantiate(Adjacency, "Adjacency", "adjacency", "run"); else mesh.adjacency = new Adjacency()
    mesh.input.csv = csv;
    setData(csv,mesh.input.weights);    
    renderChange();
  }

  function renderNewWeights(weights){
    mesh.input.weights = weights;
    setData(mesh.input.csv,weights);    
    renderChange();
  }

  function renderChangedLevel(level){
    mesh.input.level = level;
    renderChange(level)
  }

  function setData(csvData, weights){
    if (validateCSVData(csvData)) {
        csvPanel.classList.add('hidden'); // Close the panel
    } else {
        alert('Invalid CSV format. Please ensure the data is semicolon or tab separated.');
    }
    mesh.adjacency.setCSV(csvData, weights);
  }

  function renderChange(level = mesh.input.level, settings = mesh.input.settings, sourceHref = mesh.env?.doc?.location.href || mesh.input.sourceHref){
    mesh.input.level = level;
    let name = `mat${level}`;
    let graphData = mesh.adjacency[name].graph(`${name}-${Date.now()}`, settings, sourceHref+`?ref=${name}-${Date.now()}`);
    mesh.adjacency.showForceGraph(graphData, "graph");  
    //mesh.adjacency.showHeatmap(graphData.matrix, "matrix"); 
    mesh.adjacency["mat"+ level].showHeatmap("matrix"); 

    //mesh.cast("onGraph", graph);
  }

  function validateCSVData(csvData) {
    // Basic validation to check if data is semicolon or tab separated
    const isValid = csvData.includes(';') || csvData.includes('\t');
    return isValid;
  }
import { renderHeatmap } from "../visualization/matrix";
//import Graph from "./graph";

export default class Matrix {
	constructor(name, dims, fill = 0) {
		this.name = name;
		this.nrow = dims[0];
		this.ncol = dims[1];
		this.size = this.nrow * this.ncol;
		const vec_fill = fill.length > 1;
		if (vec_fill && fill.length !== this.nrow * this.ncol) {
			throw `Expecting a fill vector of length ${this.size} but got a vector of length ${fill.length}`;
		}
		this.vals = Array.from(vec_fill ? fill : [...new Array(this.nrow * this.ncol)].map((d) => fill));

		this.aggregation = this.calculateExtendedValues(this.vals, this.nrow, this.ncol);
		this.items = this.aggregation.items;
		this.rows = this.itemsToRows(this.items, this.ncol);
		this.top(mesh.input.settings.top || 20);
	}

	calculateExtendedValues(values, nrow, ncol) {
		let result = this.calcArray(values);
		let bandClassifier = this.bandClassifier;

		result.items = this.valuesToItems(values, nrow, ncol);
		result.items.forEach(function (item) {
			item.perChange = (item.value - result.mean) / result.mean;
			item.perTotal = item.value / result.total;
			item.classifier = bandClassifier(item.perChange, 100);
		});

		return result;
	}

	calcArray(sortedItems) {
		let aggregation = [];
		aggregation["mean"] = math.mean(sortedItems);
		aggregation["total"] = math.sum(sortedItems);
		aggregation["deviation"] = math.std(sortedItems);
		aggregation["variance"] = math.variance(sortedItems);
		aggregation["min"] = math.min(sortedItems);
		aggregation["max"] = math.max(sortedItems);
		aggregation["median"] = math.median(sortedItems);
		return aggregation;
	}

	// Sort all values to find the top x values
	top(topCount = 30) {
		const sortedItems = this.items;

		// Sort all values to find the top x values
		sortedItems.sort((a, b) => b.perTotal - a.perTotal);
		let sortedValues = new Set(sortedItems.map((v) => `${v.rowIndex}-${v.colIndex}`));
		let topValues = new Set(sortedItems.slice(0, topCount).map((v) => `${v.rowIndex}-${v.colIndex}`));

		// Extract only the top values for min/max calculation
		let topValuesOnly = sortedItems.slice(0, topCount).map((v) => v.value);
		this.topAggregation = this.calcArray(topValuesOnly);
		this.topAggregation.isTop = true;

		// Extract only the top values for min/max calculation
		let nonTopValuesOnly = sortedItems.slice(topCount + 1, sortedItems.length).map((v) => v.value);
		this.nonTopAggregation = this.calcArray(nonTopValuesOnly);
		this.nonTopAggregation.isTop = false;

		// Retain only top x values and set others to a minimal value
		this.rows.forEach((row, rowIndex) => {
			row.forEach((item, colIndex) => {
				if (!topValues.has(`${rowIndex}-${colIndex}`)) {
					item.topPerChange = (item.value - this.nonTopAggregation.mean) / this.nonTopAggregation.mean;
					item.isTop = false;
				} else {
					item.topPerChange = (item.value - this.topAggregation.mean) / this.topAggregation.mean;
					item.isTop = true;
				}
				item.number = this.findIndexInSet(sortedValues, `${rowIndex}-${colIndex}`) + 1;
				item.topClassifier = this.bandClassifier(item.topPerChange, 100);
			});
		});

		this.norm(this.topAggregation);
		this.norm(this.nonTopAggregation);

		sortedItems.sort((a, b) => a.index - b.index);

		return topValuesOnly;
	}

	norm(aggregate) {
		let normlizedValues = [];
		this.rows.forEach((row, rowIndex) => {
			row.forEach((item, colIndex) => {
				if (item.isTop === aggregate.isTop) {
					item.normalizedValue = Math.round(((item.value - aggregate.min) / (aggregate.max - aggregate.min)) * 99 + 1);
					normlizedValues.push(item.normalizedValue);
				}
			});
		});

		this.normalizedAggregation = this.calcArray(normlizedValues);
		this.normlizedValues = normlizedValues;

		this.rows.forEach((row, rowIndex) => {
			row.forEach((item, colIndex) => {
				if (item.isTop === aggregate.isTop) {
					item.normalizedPerChange = (item.normalizedValue - this.normalizedAggregation.mean) / this.normalizedAggregation.mean;
					item.normalizedClassifier = this.bandClassifier(item.normalizedPerChange, 100);
				}
			});
		});
	}

	findIndexInSet(set, searchString) {
		// Iterate over the Set using for...of
		let i = 0;
		for (const element of set) {
			if (element === searchString) {
				return i; // Return the element if it matches the searchString
			}
			i++;
		}
		return undefined; // Return undefined if no match is found
	}

	findEntryInSet(set, searchString) {
		// Iterate over the Set using for...of
		for (const element of set) {
			if (element === searchString) {
				return element; // Return the element if it matches the searchString
			}
		}
		return undefined; // Return undefined if no match is found
	}

	valuesToItemsInRows(arr, colCount) {
		const rows = [];

		for (let i = 0; i < arr.length; i += colCount) {
			// Slice the flatArray from index i to i + columns and push to rows array
			rows.push(arr.slice(i, i + colCount));
		}

		return rows;
	}

	itemsToRows(items, colCount) {
		const rows = [];

		for (let i = 0; i < items.length; i += colCount) {
			// Slice the flatArray from index i to i + columns and push to rows array
			rows.push(items.slice(i, i + colCount));
		}

		return rows;
	}

	valuesToItems(arr, r, c) {
		if (arr.length !== r * c) {
			throw new Error("The total elements in the array do not match the specified dimensions (rows * columns).");
		}

		const mat = arr.map((value, index) => ({
			index: index,
			value: value,
			rowIndex: Math.floor(index / c),
			colIndex: index % c,
		}));

		return mat;
	}

	bandClassifier(val) {
		// Assuming a standard deviation of 1/3 to map -1 to -3, 1 to 3.
		const sigma = 1 / 3;

		// Classify based on the standard deviation
		if (val < -2 * sigma) return -3;
		else if (val < -1 * sigma) return -2;
		else if (val < -0.3) return -1;
		else if (val < 0.3) return 0;
		else if (val <= 1 * sigma) return 1;
		else if (val <= 2 * sigma) return 2;
		else return 3; // If val > 2 * sigma

		// if (val >= 0) {
		//     let value = (val * multiplier) / (.98 * multiplier);
		//     let roundedValue = Math.floor(value);
		//     return (roundedValue + 1) > 4 ? 4 : roundedValue + 1;
		// } else {
		//     let value = (val * multiplier) / (.49 * multiplier);
		//     let roundedValue = Math.floor(value);
		//     return (roundedValue) < -3 ? -3 : roundedValue;
		// }
	}

	//adjacency.mat0.row(0)

	// internal helper function for finding position in 2d array
	mat_pos(i, j) {
		return this.ncol * i + j;
	}

	// given a row and col fill the place with a value, if inplace = false then
	// returns a new matrix object with the given location filled.
	set(i, j, val, inplace = true) {
		if (inplace) {
			this.vals[this.mat_pos(i, j)] = val;
		} else {
			const newMat = new Matrix(this.name, [this.nrow, this.ncol], [...this.vals]);
			newMat.set(i, j, val);
			return newMat;
		}
	}

	// returns a value at a given location
	get(i, j) {
		return this.vals[this.mat_pos(i, j)];
	}

	// gives back a given row
	row(i) {
		const row_res = new Float32Array(this.ncol);
		for (let j = 0; j < this.ncol; j++) row_res[j] = this.get(i, j);
		return row_res;
	}

	// ...and a given column
	col(j) {
		const col_res = new Float32Array(this.nrow);
		for (let i = 0; i < this.nrow; i++) col_res[i] = this.get(i, j);
		return col_res;
	}

	// pretty print in a 2d array
	pprint() {
		return [...new Array(this.nrow)].map((_, i) => [...new Array(this.ncol)].map((_, j) => this.get(i, j)));
	}

	transpose(name = this.name + "||transposed") {
		const t_mat = new Matrix(name, [this.mat.ncol, this.mat.nrow]);

		for (let i = 0; i < this.nrow; i++) {
			for (let j = 0; j < this.mat.ncol; j++) {
				t_mat.set(j, i, this.mat.get(i, j));
			}
		}
		return t_mat;
	}

	rowArray() {
		let rows = [];
		for (let i = 0; i < this.nrow; i++) {
			let row = JSON.parse(JSON.stringify(this.row(i)));
			rows.push(Object.values(row));
		}
		return rows;
	}

	multiply(otherMatrix, name = this.name + "||multiplied") {
		let dot_prod = (vec_a, vec_b) => vec_a.reduce((sum, d, i) => sum + d * vec_b[i], 0);
		let mat_a = this;
		let mat_b = otherMatrix;
		if (mat_a.ncol !== mat_b.nrow) throw "Make sure your inner dimensions match for multiplication";
		const mat_res = new Matrix(name, [mat_a.nrow, mat_b.ncol]);
		for (let i = 0; i < mat_a.nrow; i++) {
			for (let j = 0; j < mat_b.ncol; j++) {
				mat_res.set(i, j, dot_prod(mat_a.row(i), mat_b.col(j)));
			}
		}
		return mat_res;
	}

	add(otherMatrix, name = this.name + "||added") {
		let dot_sum = (vec_a, vec_b) =>
			vec_a.reduce((sum, d, i) => {
				let out = sum + d + vec_b[i];
				return out, 0;
			});
		let mat_a = this;
		let mat_b = otherMatrix;
		if (mat_a.ncol !== mat_b.nrow) throw "Make sure your inner dimensions match for multiplication";
		const mat_res = new Matrix(name, [mat_a.nrow, mat_b.ncol]);
		for (let i = 0; i < mat_a.nrow; i++) {
			for (let j = 0; j < mat_b.ncol; j++) {
				let sum = mat_a.get(i, j) + mat_b.get(i, j);
				mat_res.set(i, j, sum);
			}
		}
		return mat_res;
	}

	static DiagonalMatrix(dim, val = 1, name = this.name + "||diagonal") {
		if (val.length > 1 && dim !== val.length) throw `Expected fill vector of length ${dim} but got one of length ${val.length}.`;
		const mat = new Matrix(this.name, [dim, dim]);
		const vector_val = val.length > 1;
		for (let i = 0; i < dim; i++) {
			mat.set(i, i, vector_val ? val[i] : val);
		}
		return mat;
	}

	static NamedMatrix(dims, fill, columnLabels, rowLabels, name) {
		const mat = new Matrix(name, dims, fill);
		mat.columnLabels = columnLabels;
		mat.rowLabels = rowLabels;
		mat.name = name;
		return mat;
	}

	static MatrixFromVectors = (columnLabels, rowLabels, name) => {
		let fill = d3.merge(rowLabels.map((s) => columnLabels.map((f) => s[f.id])));
		let dims = [rowLabels.length, columnLabels.length];
		const mat = NamedMatrix(dims, fill, columnLabels, rowLabels, name);
		// const mat = new Matrix(this.name, dims, fill);
		// mat.columnLabels = columnLabels;
		// mat.rowLabels = rowLabels;
		return mat;
	};

	graph = (name, settings, sourceHref) => {
		//  return new m.class.Graph(this)
		//  let normalizedMat = this.normalize(settings.topCount);
		let newGraph = m.class.Graph.createFromMatrix(this, name, settings, sourceHref);
		newGraph.matrix = this;
		// newGraph.normalizedMat = normalizedMat;
		return newGraph;
	};

	normalize = () => {
		const items = this.rowArray();

		// Normalize the items to scale from 1 to 100
		const normalizedItems = items.map((row) => row.map((value, index) => (value !== 0 ? Math.round(((value - this.min) / (this.max - this.min)) * 99 + 1) : 0)));

		// Filter out rows and track removed indices from normalized matrix
		const rowRemovedIndices = [];
		const filteredItems = normalizedItems.filter((row, index) => {
			if (row.slice(1).some((val) => val > 0)) {
				return true;
			} else {
				rowRemovedIndices.push(index);
				return false;
			}
		});

		// Transpose and filter columns and track removed indices from normalized matrix
		const transposed = filteredItems[0].map((col, i) => filteredItems.map((row) => row[i]));
		const colRemovedIndices = [];
		const filteredTransposed = transposed.filter((col, index) => {
			if (col.slice(1).some((val) => val > 0)) {
				return true;
			} else {
				colRemovedIndices.push(index);
				return false;
			}
		});

		// Transpose back to original orientation from normalized matrix
		const finalItems = filteredTransposed[0].map((col, i) => filteredTransposed.map((row) => row[i]));

		// Construct the normalized matrix
		let normalizedMatrix = new Matrix(this.name + "||normalized", [this.nrow, this.ncol]);
		finalItems.forEach((row, rowIndex) => {
			row.forEach((value, colIndex) => {
				normalizedMatrix.set(rowIndex, colIndex, value);
			});
		});

		normalizedMatrix.rowLabels = this.rowLabels.filter((label, i) => !rowRemovedIndices.includes(i));
		normalizedMatrix.origRowLabels = this.rowLabels;
		normalizedMatrix.columnLabels = this.columnLabels.filter((label, i) => !colRemovedIndices.includes(i));
		normalizedMatrix.origColumnLabels = this.columnLabels;

		return normalizedMatrix;
	};

	showHeatmap(eleName, w = 500, h = 800) {
		this.setConfig(w, h);

		d3.select("#" + eleName)
			.selectAll("*")
			.remove();
		let matrixSVG = d3.select("#" + eleName); //.append("svg").attr("width", w).attr("height", h);
		//const matrixChart = matrixSVG.append("g");
		let heatmap = renderHeatmap(this, this.config);
		matrixSVG.append(() => heatmap.svg);
	}

	setConfig = (w, h, default_link_color = "blue") => {
		this.config = {
			// w,
			// h,
			// width: w / 3,
			// height: h / 2,
			value: 26,
			margin: 20,
			// size: [w / 2, h / 2],
			maxNodeSize: 100,
			minNodeSize: 10,
			minNodeDistance: 50,
			maxNodeDistance: 200,
			maxLinkOpacity: 0.6,
			maxLinkWidth: 50,
			default_link_color: default_link_color,
			color: d3.scaleSequential().range(["transparent", default_link_color]),
		};
	};
}

export { Matrix };

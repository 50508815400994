import Item from "./item.js";
import Weight from "./weight.js";
import Name from "./name.js";
import TopicType from "./topicType.js";
import Link from "./link.js";
import Items from "./items.js";

export default class Topic extends Item {
	#names;
	#topicTypes;
	#links;
	#neighbors;
	#graph;
	#weights;

	constructor(items, graph, name, topicType, value, unit, measurable, mention) {
		super(items);

		this.basing = () => `${this.name}`;
		this.#graph = graph;

		this.#names = new Items(Name);
		this.#topicTypes = new Items(TopicType);
		this.#weights = new Items(Weight);
		this.#neighbors = new Items(Topic);
		this.#links = new Items(Link);

		this.update(name, topicType, value, unit, measurable, mention);
		this.init();
	}

	update(name, topicType, value, unit, measurable, mention) {
		if (name) this.addName(name);
		if (topicType) this.addTopicType(topicType);
		if (value) this.createWeight(value, unit, measurable, mention);
		this.store();
	}

	static create(graph, name, topicType, value, unit, measurable, mention) {
		return new Topic(undefined, graph, name, topicType, value, unit, measurable, mention);
	}

	get topicTypes() {
		return this.#topicTypes;
	}

	get topicType() {
		return this.#topicTypes.first().name.trim();
	}

	addTopicType(name) {
		return this.#topicTypes.create(name);
	}

	get neighbors() {
		return this.#neighbors;
	}

	addNeighbor(name, topicType, value, unit, measurable, mention) {
		let target = this.#graph.topics.create(this.#graph, name, topicType);
		let neighbor = this.#neighbors.add(target);
		return this.addLink(neighbor, value, unit, measurable, mention);
	}

	get links() {
		return this.#links;
	}

	addLink(target, value, unit, measurable, mention) {
		let neighborLink = this.#graph.links.create(this.#graph, this, target, value, unit, measurable, mention);
		return this.#links.add(neighborLink);
	}

	get names() {
		return this.#names;
	}

	get name() {
		return this.#names.first().name.trim();
	}

	addName(name) {
		return this.#names.create(name);
	}

	get default() {
		return this.#graph.default.topic;
	}

	get settings() {
		return this.#graph.settings;
	}

	get significance() {
		let out = this.metrics.shareMax * this.settings.maxNodeSize || this.settings.minNodeSize;
		return out;
	}

	get size() {
		let out = 1 * this.metrics.shareMax * this.settings.maxNodeSize || this.settings.minNodeSize;
		return out;
	}

	get fill() {
		let out = this.topicType === "entity" ? "#333" : "#ccc";
		return out;
	}

	get weights() {
		return this.#weights;
	}

	weight(filterFn, calculateFn) {
		let weights = this.#weights;
		if (filterFn) weights = this.#weights.filter(filterFn);
		let calculatedResult;
		if (!calculateFn) {
			calculatedResult = weights.reduce((accumulator, weight, total, array) => {
				let temp = weight.value + total;
				return temp;
			}, 0);
		} else {
			let arr = weights.map((weight) => weight.value);
			calculatedResult = calculateFn(...arr);
		}
		return calculatedResult;
	}

	addWeight(weight) {
		this.#weights.add(weight)
	}

	createWeight(value, unit, measurable, mention) {
		let weight = this.#weights.create(value || this.default.value, unit || this.default.unit, measurable || this.default.measurable, mention || this.default.mention)
		return weight
	}
}

import ForceGraph3D from "3d-force-graph";
import { CSS2DObject, CSS2DRenderer } from "./CSS2DRenderer";
import SpriteText from "three-spritetext";
//import * as dat from 'dat.gui';

export default force = function (eleName, graph, settings) {
	// const w = settings.w;
	// const h = settings.h;
	settings.redDistance = 50;
	settings.greenDistance = 50;

	const gData = {
		nodes: graph.nodes,
		links: graph.edges,
	};

	// function updateLinkDistance() {
	//   linkForce.distance((link) => (link.color ? settings.redDistance : settings.greenDistance));
	//   graph.numDimensions(3); // Re-heat simulation
	// }

	let selectedNodes = new Set();
	//const nodeColorScale = d3.scaleOrdinal(d3.schemeRdYlGn[4]);

	let highlightNodes = new Set();
	let highlightLinks = new Set();
	let hoverNode = null;

	const forceGraph = ForceGraph3D({ alpha: true, extraRenderers: [new CSS2DRenderer()] })(document.getElementById(eleName)).graphData(gData);

	forceGraph.backgroundColor("#eeeeee");

	forceGraph
		.nodeThreeObjectExtend(true)
		//.nodeThreeObjectExtend = true
		.nodeLabel((node) => "")
		.nodeResolution(30)
		.nodeVal((node) => node.weight * 10)
		.onNodeHover((node, event) => {
			if ((!node && !highlightNodes.size) || (node && hoverNode === node)) return;

			highlightNodes.clear();
			highlightLinks.clear();

			if (node) {
				highlightNodes.add(node);
				node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
				node.links.forEach((link) => highlightLinks.add(link));
			}

			hoverNode = node || null;
			updateHighlight();
		})
		.nodeOpacity(0.2)
		.nodeColor((node) => (highlightNodes.has(node) ? (node === hoverNode ? "#fcb004" : "#fd6713") : "#eeeeee"))
		//.nodeAutoColorBy((node) => node.type)
		//.nodeColor((node) => (selectedNodes.has(node) ? "yellow" : "white"))

		.onNodeClick((node, event) => {
			node.pinned = false;

			forceGraph.nodeThreeObject(updateNode);
			if (event.ctrlKey || event.shiftKey || event.altKey) {
				node.selected = node.selected != true;
				// multi-selection
				selectedNodes.has(node) ? selectedNodes.delete(node) : selectedNodes.add(node);
				forceGraph.nodeThreeObject(updateNode);
				node = updateNode(node);
			} else {
				// single-selection
				const untoggle = selectedNodes.has(node) && selectedNodes.size === 1;
				selectedNodes.clear();
				selectedNodes = new Set();

				!untoggle && selectedNodes.add(node);

				forceGraph.nodeColor(forceGraph.nodeColor()); // update color of selected nodes
				// Aim at node from outside it
				const distance = 500;
				const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

				// forceGraph.cameraPosition(
				//   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
				//   node, // lookAt ({ x, y, z })
				//   1000 // ms transition duration
				// );
			}
		})

		// // .nodeColor((node) => (highlightNodes.indexOf(node) === -1 ? "rgba(0,255,255,0.6)" : "rgb(255,0,0,1)"))
		// .onNodeHover((node) => {
		//   // no state change
		//   if ((!node && !highlightNodes.length) || (highlightNodes.length === 1 && highlightNodes[0] === node)) return;
		//   highlightNodes = node ? [node] : [];
		//   updateGeometries();
		// })

		.onNodeDrag((node) => {
			node.pinned = true;
		})

		.onNodeDragEnd((node) => {
			node.fx = node.x;
			node.fy = node.y;
			node.fz = node.z;
			forceGraph.nodeThreeObject(updateNode);
		})
		.nodeThreeObjectExtend(true);

	// let isSelected = (node) => {
	//   let out = selectedNodes.has(node);
	//   //if (out) debugger
	//   return out;
	// };

	// let updateColor = (node) => {
	//   let color = isSelected(node) ? "rgba(250,200,190,0.65)" : "rgba(0,0,190,0.99)";
	//   return color;
	// };

	let updateNode = (node) => {
		let text = node.name; // + isSelected(node).toString();
		const sprite = new SpriteText(text);
		sprite.material.depthWrite = false; // make sprite background transparent
		sprite.color = "#eeeeee";
		sprite.textHeight = node.significance / 3;
		sprite.backgroundColor = node.selected ? "#fcb004" : node.type === "topic" ? "#537ded" : "#222222";
		sprite.borderColor = node.pinned ? "#fd451a" : "transparent";
		sprite.borderWidth = node.pinned ? 0.5 : 0;
		sprite.borderRadius = 1.2;
		sprite.padding = [3, 2];
		sprite.position.set(0, 0, 0);

		// const group = new m.Space.Group();
		if (node.selected) {
			sprite.add(addCallout(node));
		} else {
			removeCallout(node);
		}

		return sprite;
	};
	forceGraph.nodeThreeObject(updateNode);

	let addCallout = (node) => {
		let text = node.name; // + isSelected(node).toString();
		const nodeEl = document.createElement("div");
		nodeEl.textContent = text;
		nodeEl.style.fontSize = (node.significance / 1.5).toString() + "px";
		nodeEl.style.background = node.type === "topic" ? "#5a8cea" : "#222222";
		nodeEl.className = "node-label";
		let htmlEle = new CSS2DObject(nodeEl);

		htmlEle.position.set(100, 50, 200);
		node.htmlEle = htmlEle;
		return htmlEle;
	};

	let removeCallout = (node) => {
		if (!node.htmlEle) return;
		scene.remove(node.htmlEle);
		node.htmlEle = null;
	};

	forceGraph
		.linkCurvature((link) => (link.width <= 1 ? 0 : 0.1 * (1 - link.width * 0.2)))
		.linkDirectionalParticles((link) => (highlightLinks.has(link) ? 2 : 8))
		.linkDirectionalParticleColor((link) => (highlightLinks.has(link) ? "#fc940a" : "#e6e6e6"))
		.linkDirectionalParticleWidth((link) => (highlightLinks.has(link) ? link.width / 2 : link.width / 5))
		.linkHoverPrecision(50)
		.linkOpacity(0.3)
		.linkWidth((link) => (link.width <= 1 ? 0.01 : link.width))
		.linkColor((link) => (highlightLinks.has(link) ? "#fcb004" : "#aaaaaa"))
		.onLinkHover((link) => {
			highlightNodes.clear();
			highlightLinks.clear();

			if (link) {
				highlightLinks.add(link.id);
				highlightNodes.add(link.source);
				highlightNodes.add(link.target);
			}

			updateHighlight();
		});

	// fit to canvas when engine stops
	// forceGraph.cooldownTime(2000).d3AlphaDecay(0).d3VelocityDecay(0);
	// forceGraph.onEngineStop(() => forceGraph.zoomToFit(20));

	forceGraph.d3Force("charge", (link) => d3.forceManyBody().strength(-1 * link.shareMax * 100000000 * graphSettings.charge));

	const linkForce = forceGraph
		.d3Force("link")
		//.charge((link) => d3.forceManyBody().strength(-1 * link.shareMax * 100000000))
		.distance((link) => link.distance * graphSettings.distance)
		.strength((link) => link.shareMax * graphSettings.strength);
	//.collide().radius((link) => 500).iterations(5)
	//.collision((link) => d3.forceCollide().radius((d) => 5 + link.size))
	// .x((link) => 100)
	// .y((link) => 1000);

	//Define GUI
	const Settings = function () {
		this.distance = 1;
		this.strength = 1;
		this.charge = 1;
	};

	let graphSettings = new Settings();
	// let  gui = new dat.GUI();

	// let controllerOne = gui.add(graphSettings, 'distance', 1, 20);
	// // let controllerTwo = gui.add(graphSettings, 'strength', 1, 100);
	// // let controllerThree = gui.add(graphSettings, 'charge', 1, 100);

	// controllerOne.onChange(updateLinkDistance);
	// // controllerTwo.onChange(updateLinkStrength);
	// // controllerThree.onChange(updateLinkCharge);

	function updateLinkDistance() {
		linkForce.distance((link) => link.distance * graphSettings.distance);
		updateHighlight();
	}

	function updateLinkStrength() {
		linkForce.strength((link) => link.shareMax * graphSettings.strength);
		updateHighlight();
	}

	function updateLinkCharge() {
		linkForce.charge((link) => link.shareMax * graphSettings.strength);

		forceGraph.d3Force("charge", (link) => d3.forceManyBody().strength(-1 * link.shareMax * 100000000 * graphSettings.charge));
		updateHighlight();
	}

	function updateGeometries() {
		forceGraph.nodeRelSize(4); // trigger update of 3d objects in scene

		//forceGraph.numDimensions(3); // Re-heat simulation
	}

	function updateHighlight() {
		// trigger update of highlighted objects in scene
		forceGraph.nodeColor(forceGraph.nodeColor()).linkWidth(forceGraph.linkWidth()).linkDirectionalParticles(forceGraph.linkDirectionalParticles()).linkDirectionalParticleColor(forceGraph.linkDirectionalParticleColor()).linkDirectionalParticleWidth(forceGraph.linkDirectionalParticleWidth()).linkColor(forceGraph.linkColor());

		linkForce.strength(linkForce.strength()).distance(linkForce.distance());

		forceGraph.nodeThreeObject(updateNode);

		//forceGraph.numDimensions(3);
		forceGraph.nodeRelSize(4);
	}
};

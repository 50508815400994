
export default class Error {
  constructor() {
    // //m.talk.wait("Error handling is preparing...");

    // m.talk.message("Error handling is ready.");
    // m.pub("onInitializedError");
  }

  message (text, element, logType, start) {

    let grey = 'color:grey';
    let black = 'color:black';
    let blue = 'color:blue';
    let red = 'color:red';
    let yellow = 'color:yellow';
    let purple = 'color:purple';

    if (logType && m.LOG === true) {

      let end = Date.now();
      let durationText
      if (start) {
        let duration = (end - start) / 1000;
        durationText = " (duration: " + duration+ "sec.)";
      }

      switch (logType) {
        case 'Event':
        case 'Component':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,purple,black,grey);
          this.obj(element);
          break;
        case 'Provider':
        case 'Process':
        case 'Worker':
        case 'startedWorker':
          //this._text("%c" + logType + ": %c" + text + "%c" +durationText,blue,black,grey);
          //this.obj(element);
          break;
        case 'successWorker':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,blue,black,grey);
          this.obj(element);
          break;
        case 'Item':
        case 'changedItem':
        case 'Identification':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,grey,black,grey);
          this.obj(element);
          break;
        case 'Warning':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,yellow,black,grey);
          this.obj(element);
          break;
        case 'Critical':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,red,black,grey);
          this.obj(element);
          break;
        case 'Error':
        case 'failedWorker':
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,red,black,grey);
          this.obj(element);
          break;
        case 'Info':
        case 'Util':
        case 'Tool':
          //this._text("%c" + logType + ": %c" + text + "%c" +durationText,grey,black,grey);
          //this.obj(element);
          break;
        case 'Queue':
          //this._text("%c" + logType + ": %c" + text + "%c" +durationText,grey,black,grey);
          //this.obj(element);
          break;
        default:
          this._text("%c" + logType + ": %c" + text + "%c" +durationText,grey,black,grey);
          //this.obj(element);
          break;
      }

    } else {
      //this._text("%cInfo: %c" + text,grey,black);
    }

  }

  _text (text) {
    m.log.error(text);
  }

  obj (element) {
    if( (typeof element === "object") && (element !== null) )
    {
      m.log.error(element);
    }
  }

  throw(message, element, error, start) {
    let red = 'color:red';
    let grey = 'color:grey';
    let blue = 'color:blue';
    let black = 'color:black';
    let text = message ? message : error.message

    if (element) {
      this.message(error.name + ": " + text, element, "Error", start);
    } else {
      this.message(error.name + ": " + text, null, "Error", start);
    }

    this.obj(error);
    if (error) {
      throw error;
    } else {
      throw text;
    }
  }

};
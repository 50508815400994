// Configuration file for error messages

const ERROR_MESSAGES = {
  INTERNAL_ERROR: "Sorry! Something was wrong.",
  NO_DATA: "Sorry! No Data.",
  INVALID_GRANT: "Invalid email or password.",
  NO_GROUPS: "Please add group to this domain.",
  NO_TOPIC_TYPES: "Please add topic type.",
  NOT_UNIQ_GROUP_NAME:
    "In a domain every group name must be unique. " +
    "The name you tried is already in use, please change the name and add the group again.",
  NOT_UNIQ_TOPIC_TYPE_NAME:
    "The name you tried is already in use, please change the name and add try again.",
  NOT_UNIQ_KEYWORD_NAME:
    "The name you tried is already in use, please change the name and add try again.",
  NOT_UNIQ_TOPIC_SOURCE: "The dom you tried is already in use.",
  TOPIC_SOURCE_FORMAT:
    "This field format incorrect. It must be 'http(s)://host/path'",
  ENQUEUED_ITEM_NOT_FOUND: "The enqueued item you wanted to process couldn't be resolved.",
};

module.exports = ERROR_MESSAGES
//export default ERROR_MESSAGES;

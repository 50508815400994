/**
 * Parses a CSV string and returns an array of parsed entities.
 *
 * @param {string} csv - The CSV string to parse.
 * @returns {Array} An array of parsed entities.
 */
let parseEntities = (csv) => {
  let parsed = d3.tsvParse(csv.trim(), function (entity, i, columns) {
    // coerce each column's value (except `id`) into Number
    return columns
      .slice(1) // don't coerce `id` column
      .reduce(
        (s, c) => {
          s[c] = parseFloat(entity[c]);
          return s;
        },
        { id: (entity.id || "").trim(), type: "entity" }
      );
  });
  return parsed;
};
//let frame2 =  `<m-import src="../templates${template.path}.html"></m-import>`.toDOM()

// async function getText(title){
//   return await wtf.fetch(title).then(doc=>doc.plaintext())
//  }

export { parseEntities };

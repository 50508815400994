import schema from '../mapping/schema.mjs';
import sharpMapper from 'sharp-mapper'

export default class Schema {
  constructor() {
  }

  eval(str) {
    // return eval(str); //"(" + obj + ")"
    return
  }

  mapResults (json, mapping) {
    var  mappedObject  = sharpMapper.structureMap(json, mapping, true);
    return mappedObject;
  }

  isMeshList (source) {
    if (!source || source === null) {
      return false;
    } else {
      if (Array.isArray(source) && source.length > 0 && this.isMeshObject(source[0])) {
        return true;
      }
      return false;
    }
  }

  isMeshObject (source) {
    if (!source || source === null) {
      return false;
    } else {
      if (source.id && source.className && source.changes) {
        return true;
      }
      return false;
    }
  }

  merge  (prevState, source) {

    let output = {};
    let index = prevState["index"];
    if (!index) index = {};
    index = this.updateIndex (source, prevState, index);

    if (source) {
      let sourceName = this.getType(source);
      if (Array.isArray(source)) {
        output[sourceName] = this.mergeArrays(prevState[sourceName], source);
      } else {

        if (this.isMeshObject(source)) {
          sourceName = source.className;
          if (prevState[sourceName]) {
            output[sourceName] = this.mergeArrayWithObject(prevState[sourceName], source);
          } else if (prevState[sourceName + "s"]) {
            output[sourceName + "s"] = this.mergeArrayWithObject(prevState[sourceName + "s"], source);
          }
        } else {

          for (let propertyName in source) {
            if (Array.isArray(source[propertyName])) {
              if (Array.isArray(prevState[propertyName])) {
                output[propertyName] = this.mergeArrays(prevState[propertyName], source[propertyName]);
              }
            } else {
              if (this.isMeshObject(source[propertyName])) {
                output[propertyName] = this.mergeArrayWithObject(prevState[propertyName], source[propertyName]);
              } else {
                output[propertyName] = source[propertyName];
              }
            }
          }

        }
      }
    }

    output.index = index;
    return output;
  }

  addIndexItem  (source, parent, index, drilldown) {

    if (this.isMeshObject(source)) {

      if (!index[source.id]) {
        source.parent = parent;
        index[source.id] = source;
      } else {
        source.parent = parent;
        let oldMeshObject = index[source.id];
        oldMeshObject = source;
        index[source.id] = source;
      }

      switch (source.className) {
        case 'article':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
        case 'content':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
        case 'chapter':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
        case 'block':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
        case 'sentence':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
        case 'part':
          if (drilldown) this.updateIndex(source, parent, index, false);
          break;
      };

    }

  }

  updateIndex  (source, parent, index) {
    if (this.isMeshList(source)) {
      source.forEach(item => {
        this.updateIndex (item, parent, index);
      })
    } else {

      this.addIndexItem(source, parent, index, false)

      for (let propertyName in source) {
        if (propertyName != "parent") {
          let inner = source[propertyName];
          this.addIndexItem(inner, source, index, true);

          if (this.isMeshList(inner)) {

            switch (propertyName) {
              case 'articles':
                this.updateIndex(inner, source, index, true);
                break;
              case 'content':
                this.updateIndex(inner, source, index, true);
                break;
              case 'chapters':
                this.updateIndex(inner, source, index, true);
                break;
              case 'blocks':
                this.updateIndex(inner, source, index, true);
                break;
              case 'sentences':
                this.updateIndex(inner, source, index, true);
                break;
              case 'parts':
                this.updateIndex(inner, source, index, true);
                break;
            };
          }
        }
      }

    }
    return index;
  }

  mergeArrayWithObject  (target, source) {
    let outArray = [];
    if (!target) target = [];
    if (!source) return target;
    if (  m.id.isIdentifiableArray(target) &&  m.id.isIdentifiableObject(source) && target.some(t => t.ID === source.ID)) {
      outArray = target && target.map(t => t.ID === source.ID ? source : t);
    } else {
      if (this.isArray(target)) {
        outArray = target;
      }
      outArray.push(source);
    }
    return outArray;
  }

  isInstance  (obj, type) {
    var ret = false,
        isTypeAString = this.getType(type) == "String",
        functionConstructor, i, l, typeArray, context;
    if (!isTypeAString && this.getType(type) != "Function") {
      throw new TypeError("type argument must be a string or function");
    }
    if (obj !== undefined && obj !== null && obj.constructor) {
      //get the Function constructor
      functionConstructor = obj.constructor;
      while (functionConstructor != functionConstructor.constructor) {
        functionConstructor = functionConstructor.constructor;
      }
      //get the object's window
      context = functionConstructor == Function ? self : Constructor("return window")(); // : added
      //get the constructor for the type
      if (isTypeAString) {
        //type is a string so we'll build the context (g.Array or g.some.Type)
        for (typeArray = type.split("."), i = 0, l = typeArray.length; i < l && context; i++) {
          context = context[typeArray[i]];
        }
      } else {
        //type is a function so execute the function passing in the object's window
        //the return should be a constructor
        context = type(context);
      }
      //check if the object is an instance of the constructor
      if (context) {
        ret = obj instanceof context;
        if (!ret && (type == "Number" || type == "String" || type == "Boolean")) {
          ret = obj.constructor == context
        }
      }
    }
    return ret;
  }

  copyAttributes  (source, destination) {

    if (typeof source === 'undefined') {
      return destination;
    }

    if (source.attributes !== null && source.attributes !== undefined) {
      for (let i = 0; i < source.attributes.length; i++) {
        let attribute = source.attributes[i];
        try {
          destination[attribute.name] = attribute.value.trim();
        } catch (error) {
          return destination;
        }
      }
      return destination;
    } else {
      return {...source, ...destination};
    }
    return destination;
  }

  copyObject  (obj) {
    let output = Object.assign({}, obj);
    if (obj.id) output.id += "copy" +  m.id.getRandomCode();
    return output;
  }

  sortDictionaryByKeyLength  (dict) {

    // Create items array
    let items = Object.keys(dict).map(function (key) {
      return {topic: key, length: key.length, topicType: dict[key]};
    });

// Sort the array based on the second element
    items.sort(function (first, second) {
      return second.length - first.length;
    });

    let outDict = {};
    items.forEach(item => {
      outDict[item.topic] = item.topicType;
    });

    return outDict;
  }

  objectToJsonMapping  (mapObject) {

    const result = schema.map(mapObject, mapObject.schema);
    return result;

  }

  getClass  (that) {
   return that.constructor.name;
  }

  recursiveNodes  (inputNode, tags, level) {
    let nodes = Array.from(inputNode.childNodes);
    let outputNodes = [];
    level = level + 1;

    if (nodes !== null && nodes !== undefined) {
      for (let i = 0; i < nodes.length; i++) {
        let currentNode = nodes[i];
        if (currentNode != null && currentNode.tagName != null && currentNode.nodeName !== '#text') {
          let tag = nodes[i].tagName.toLowerCase();

          if (tags.includes(tag)) {
            outputNodes.push(currentNode);
            // m.log.report('Level: ' + level + '.' + i + ' Tag: ' + tag + ' (recursive detection)');
          }

          if (currentNode.childNodes.length > 0 && tag !== 'figure' && tag !== 'table' && tag !== 'ul' && tag !== 'ol') {  //
            let recursiveChildNodes = this.recursiveNodes(currentNode, tags, level);

            if (recursiveChildNodes.length > 0) {
              outputNodes = outputNodes.concat(recursiveChildNodes);
            }
          }
        }
      }
    }
    return outputNodes;
  }

  getJsonObjects  (objects) {
    let output = null;
    if (objects !== null || objects.length < 1) {
      output = objects.json();
    }
    return output;
  }

  toJson  (collection) {
    let output = [];
    let elementOrderNumber = 0;
    collection.forEach(element => {
      if (element.__proto__.hasOwnProperty("toJson")) {
        elementOrderNumber += 1;
        //m.log.report('run', element.toJson.call(element));
        output.push(element.toJson(elementOrderNumber));
      }
    });
    //m.log.report('out', output);

    return output;
  }

  topicsToJson  (topics) {
    let outputArray = [];
    topics.forEach(topic => {
      let output = {};
      output['text'] = topic.text;
      output['topic'] = topic.topic;
      output['topicType'] = topic.topicType;
      output['type'] = topic.type;
      if (topic.isStopWord) {
        output['isStopWord'] = topic.isStopWord
      }
      if (topic.occurrences[0] !== undefined) output['occurrences'] = this.occurrencesToJson(topic.occurrences);
      output['relevance'] = topic.relevance;
      outputArray.push(output);
    });
    //m.log.report('out', output);

    return outputArray;
  }

  termsToJson  (terms) {

    let outputArray = [];
    terms.forEach(term => {
      let output = {};
      output['base'] = term.base;
      if (term.isStopWord) {
        output['isStopWord'] = term.isStopWord
      }
      output['tags'] = term.tags;
      output['occurrences'] = this.occurrencesToJson(term.occurrences);
      outputArray.push(output);
    });
    //m.log.report('out', output);

    return outputArray;
  }

  occurrencesToJson  (occurrences) {
    return occurrences.map(occurrence => occurrence.id);
  }

};

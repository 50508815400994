import Item from "./item.js";
export default class Name extends Item {
	#name;
	
	constructor(items,name) {
        super(items);
		this.basing = () => `${this.name}`
		this.#name = name
		this.init()
	}

	update(name) {
		this.#name = name
		this.store()
	}

	get name() {
		return this.#name;
	}
}
//import {base, html} from '@mesh./m.helper';
//import html from '../@mesh./m.common/source/m.html.mjs';
//import base from '../@mesh./m.common/source/base.mjs';
import BaseClass from "./baseClass.mjs";

class RootClass extends BaseClass {

    constructor(ele, sourceId, childType, options, tag) {
        
        //let sanitized = m.html.sanitizeHtml(htm);
        let rootElement = ele // m.html.toDomElement(sanitized,"div");  //sanitized
        
        super(rootElement, tag);        

        this.sourceId = sourceId;
        this._setId();

        //this.index = {};
        //this.index["0"] = this;

        options = options || {};

        this.DEFAULT_TEXT_EXPORT = true;
        this.DEFAULT_COPY_ATTRIBUTES = false;
        this.DEFAULT_LOG = true;

        this.TEXT_EXPORT  = options.textExport || this.DEFAULT_TEXT_EXPORT;
        this.COPY_ATTRIBUTES  = options.copyAttributes || this.DEFAULT_COPY_ATTRIBUTES;
        this.LOG  = m.obj.exists(options.log) ? options.log : this.DEFAULT_LOG;
        m.LOG = this.LOG;

        this._childType = childType;
        this.children = [];
        let types = (childType + "s").toLowerCase()
        this[types] = this.children;

        this.schema.sourceId = true;
        this.schema[types] = true;

    }

    _setId () {
        this.id = this.sourceId + "." + this.localId;
    }

    getSentences() {

        let sentences = [];

        this.children.forEach(child => {
            if (child.sentences) {
                sentences.push(...child.sentences);
            } else {
                sentences.push(...child.getSentences());
            }
        });

        return sentences;
    }

    toMarkup() {

        let outElement = super.toMarkup();
        outElement.dataset.sourceId = this.sourceId;

        this.children.forEach(child => {
            let markup = child.toMarkup();
            outElement.append(markup)
        });

        return outElement;
    }

    toText() {
        let text = "";
        this.children.forEach(child => {
            text = text.trim() + " " + child.toText();
        });
        return text;
    }

};

//module.exports = RootClass
export default RootClass

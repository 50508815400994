import Item from "./item.js";
import Items from "./items.js";

export default class Scope extends Item {
	#location;
	#datetime;
	#intents;
	#name;
	
	constructor(items, name, location, datetime, intent) {
        super(items);
		this.basing = () => `${this.name}`
		this.#name = name
		this.#location = location
		this.#datetime = datetime
		this.#intents = new Items(Graph)
		if (intent) this.addIntent(intent)

		this.init()
	}

	update(name, location, datetime, intent) {
		this.#name = name
		this.#location = location
		this.#datetime = datetime
		if (intent) this.addIntent(intent)
		this.store()
	}

	addIntent(intent) {
		return this.#intents.create(intent)
	}

	get name() {
		return this.#name;
	}

	get location() {
		return this.#location;
	}

	get datetime() {
		return this.#datetime;
	}

	get intent() {
		return this.#intents[0];
	}
}
import Item from "./item.js";
import Items from "./items.js";
import Weight from "./weight.js";

export default class Link extends Item {
	#source;
	#target;
	#weights;
	#graph;
	// #distance;

	constructor(items, graph, source, target, value, unit, measurable, mention) {
		super(items);
		this.basing = () => `${this.#source.name} -> ${this.#target.name}`

		this.#source = source;
		this.#target = target;
		this.#graph = graph;

		this.#weights = new Items(Weight)

		this.update(graph, source, target, value, unit, measurable, mention)
		this.init()
	}

	update(graph, source, target, value, unit, measurable, mention) {
		if (source) this.#source = source
		if (target) this.#target = target
		if (value) this.createWeight(value, unit, measurable, mention)
		this.addNeighbor()
		this.store()
	}

	addNeighbor() {	
		this.#source.neighbors.add(this.#target);
		this.#target.neighbors.add(this.#source);
		this.#source.links.add(this);
		this.#target.links.add(this);
	}

	get source() {
		return this.#source;
	}

	get target() {
		return this.#target;
	}

	get weights() {
		return this.#weights;
	}

	// set distance(value) {
	// 	this.#distance = value
	// }

	// get distance() {
	// 	return this.#distance;
	// }

	get settings() {
		return this.#graph.settings;
	}

	get default() {
		return this.#graph.default.link;
	}

	get distance() {
		let distance = 1 - (this.#source.metrics.shareMax + this.#target.metrics.shareMax) / 2;
		let out = 2 * (this.settings.maxNodeDistance * distance > this.settings.minNodeDistance ? this.settings.maxNodeDistance * distance : this.settings.minNodeDistance)
		return out
	}

	get significance() {
		let out = this.metrics.shareMax || this.settings.minNodeSize
		return out
	}

	get width() {
		let out = (this.settings.maxLinkWidth * this.metrics.shareMax) / 1.5
		return out
	}

	get opacity() {
		let out = this.settings.maxLinkOpacity * this.metrics.shareMax
		return out
	}

	weight(filterFn, calculateFn) {
		let weights = this.#weights
		if (filterFn) weights = this.#weights.filter(filterFn);
		let calculatedResult
		if (!calculateFn) {
			function sum (accumulator, weight, total, array) {
				let temp = weight.value + total
				return temp
			}
			calculatedResult =  weights.reduce(sum, 0);
		} else {
			let arr = weights.map((weight) => weight.value)
			calculatedResult = calculateFn(...arr);
		}
		return calculatedResult
	}

	addWeight(weight) {
		return this.#weights.add(weight)
	}

	createWeight(value, unit, measurable, mention) {
		let weight = this.#weights.create(value || this.default.value, unit || this.default.unit, measurable || this.default.measurable, mention || this.default.mention)

		// let existingWeight = this.#weights.find((item) => item.mention === weight.mention);
		// if (!existingWeight) this.#weights.push(weight);
		return weight
	}	

	static readBySourceTarget(source, target) {
		let link = this.links.find(link => link.source === source && link.target === target)
		if (!link) link = new Link(source, target)
		return link
	}
}

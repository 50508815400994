import Changes from "./change.js";

export default class Item {
	#meta;

	constructor(items, basing) {
		this.#meta = {};
		this.#meta.changes = new Changes();
		this.#meta.archived = false;
		this.#meta.items = items;
		let defaultBasing = () => "temp";
		basing = basing ? basing : defaultBasing;
		this.#meta.basing = items ? items.basing : basing;
	}

	set basing(fn) {
		this.#meta.basing = fn;
	}

	get uuid() {
		return this.#meta.uuid;
	}

	get hash() {
		return this.#meta.base.hash();
	}

	get archived() {
		return this.#meta.archived;
	}

	get changes() {
		return this.#meta.changes;
	}

	get base() {
		return this.#meta.base;
	}

	init() {
		this.change("created");
	}

	change(input) {
		this.#meta.base = this.#meta.basing();
		let changesEntry = this.#meta.changes.add(input + ": " + input);
		if (!this.#meta.uuid) this.#meta.uuid = changesEntry.hash;
		//this.#meta.changes.add(change);
		if (this.#meta.items) this.#meta.items.changes.add(changesEntry);
	}

	toString() {
		return this.base;
	}

	store() {
		this.change("stored");
	}

	archive() {
		this.#meta.archived = true;
		this.change("archived");
	}

	remove(consent) {
		this.change("deleted with consent: " + consent);
		if (this.#meta.items) this.#meta.items.delete(this);
	}
}

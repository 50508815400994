// import * as apiImp from './api.js'
// import * as baseImp from './base.js'
// import * as calcImp from './calc.js'
// import * as eventsImp from './events.js'
// import * as fileImp from './file.js'
// import * as htmlImp from './html.js'
// import * as idImp from './id.js'
// import * as languageImp from './language.js'
// import * as loggingImp from './logging.js'
// import * as queueImp from './queue.js'
// import * as regexImp from './regex.js'
// import * as relevanceImp from './relevance.js'
// import * as textImp from './text.js'
// import * as workersImp from './workers.js'
// import * as wrapperImp from './wrapper.js'

// export const api = apiImp.default;
// export const base = baseImp.default;
// export const calc = calcImp.default;
// export const events = eventsImp.default;
// export const file = fileImp.default;
// export const html = htmlImp.default;
// export const id = idImp.default;
// export const language = languageImp.default;
// export const logging = loggingImp.default;
// export const queue = queueImp.default;
// export const regex = regexImp.default;
// export const text = textImp.default;
// export const relevance = relevanceImp.default;
// export const workers = workersImp.default;
// export const wrapper = wrapperImp.default;

import Mods from "./module/mods";
import Config from "./config/config";
import Types from "./types/types";
import Report from "./log/report";
import Error from "./log/error";
import Acts from "./events/acts";
import Bridge from "./events/bridge";
import Load from "./load/load";
import ContentClasses from "./class/contentClasses";

export default class Common {
  
  constructor() {

    let config = new Config();

    m.mods = new Mods();

    m.mods.addClass(Config, "Config", "meta");

    m.mods.instantiate(Report, "Report", "rep", "meta");
    m.mods.instantiate(Error, "Error", "err", "meta");
    m.mods.instantiate(Types, "Types", "types", "meta");
    m.mods.instantiate(Acts, "Acts", "acts", "meta");
    m.mods.instantiate(Bridge, "Bridge", "bridge", "meta");
    m.mods.instantiate(Load, "Load", "load", "meta");
    m.mods.instantiate(ContentClasses, "ContentClasses", "contentClasses", "meta");

    m.mods.setup();   

  }

  isMeshHosted(ref) {
    let whitelist = ["http://localhost:3000", "https://mesh.app", "http://127.0.0.1:3000", "https://staging.mesh.app"];
    if (!ref || ref === "" || !whitelist.includes(ref)) {
      m.env.mesh = false;
      return false;
    } else {
      m.env.mesh = true;
      return true;
    }
  }
  
  hasEmbeddedData() {
    if (typeof g.meshData !== "undefined") {
      return true;
    } else {
      return false;
    }
  }

  getEmbedder() {
    if (m.input && m.input.embedder) {
      return m.input.embedder;
    } else {
      return "tag";
    }
  }

  getRuntime(ref) {
    let dev = ["http://localhost:3000", "http://127.0.0.1:3000"];
    let staging = ["https://staging.mesh.app"];
    let production = ["https://mesh.app"];
    if (ref && ref !== "" && dev.includes(ref)) {
      return "dev";
    } else if (ref && ref !== "" && staging.includes(ref)) {
      return "staging";
    } else if (ref && ref !== "" && production.includes(ref)) {
      return "production";
    } else {
      return "affiliate";
    }
  }

  isBuild() {
    if (g.location.protocol == "file:") {
      if (g.m) {
        m.env.build = true;
        return true;
      } else {
        m.env.build = false;
        return false;
      }
    } else {
      m.env.build = false;
      return false;
    }
  }

  isFile() {
    if (g.location.protocol == "file:") {
      m.env.file = true;
      return true;
    } else {
      m.env.file = false;
      return false;
    }
  }

  isMain() {
    if (m.env.channel === "main") {
      m.env.isMain = true;
      return true;
    } else {
      m.env.isMain = false;
      return false;
    }
  }

  isWorker() {
    if (typeof WorkerGlobalScope !== "undefined" && self instanceof WorkerGlobalScope) {
      return true;
    } else {
      return false;
    }
  }

  hasHost() {
    if (g !== g.parent) {
      //g !== g.parent
      m.env.embedded = true;
      return true;
    } else {
      m.env.embedded = false;
      return false;
    }
  }

}
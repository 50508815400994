import Item from "./item.js";
import Items from "./items.js";
import Name from "./name.js";

export default class Source extends Item {
	#locator;
	#url;
	#names;
	
	constructor(items, locator, title) {
        super(items);
		this.basing = () => `${this.#locator}`
		
		this.#names = new Items(Name);

		this.update(locator, title)
		this.init()
	}

	update(locator, title) {
		this.#locator = locator
		this.#url = new URL(locator)
		if(title) this.createName(title)
		this.store()
	}

	createName(name) {
		return this.#names.create(name)
	}

	get names() {
		return this.#names;
	}

	get locator() {
		return this.#locator
	}

	get url() {
		return this.#url;
	}

	get name() {
		return this.#names[0]
	}
}
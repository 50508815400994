import ERROR_MESSAGES from '../error/ERROR_MESSAGES.cjs'
export default class Mods {
  constructor() { 
    m.ERROR_MESSAGES = !m.ERROR_MESSAGES ? ERROR_MESSAGES : m.ERROR_MESSAGES;
  }

  setup() {
    //m.talk.wait("All required modules are setting up ...");
    //npmToWindow("rivescript","rivescript/dist", "rivescript.js");

    // loadModule("workflow", "meta");
    // loadModule("run-helper", "meta");
    // loadModule("events", "meta");
    // loadModule("act", "meta");
    // loadModule("statemachine", "meta");

    // loadModule("links", "meta");
    // loadModule("orchestrate", "meta");
    // loadModule("render", "meta");

    // g.draftDocument = await renderExternalTemplate(m.theme.template, "draft");
    // g.draftDocument.addEventListener(
    //   "DOMContentLoaded",
    //   async function () {
    //     m.pub("runDOMLoaded");
    //   },
    //   false
    // );

    // loadModule("initialize-components", "meta");

    // loadModule("require-external", "meta");
    // loadModule("receive", "build");
    // loadModule("build-register", "build");
    // loadModule("drop", "build");
    // loadModule("buttons", "build");
    // loadModule("download", "meta");
    // if (m.externalScripts) registerExternalComponents(document, m.externalScripts);

    // loadModule("head-register", "meta");
    // loadModule("layouts", "meta");

    // loadModule("replace", "meta");
    // loadModule("mapping", "meta");
    // loadModule("merge", "meta");
    // loadModule("uses", "meta");
    // loadModule("overlay", "meta");
    // loadModule("code", "meta");

    // loadModule("guide", "meta");
    // loadModule("api", "meta");
    // loadModule("build-helper", "build");
    // loadModule("templating", "meta");
    // loadModule("blobs", "meta");
    // //loadModule("workers","meta");
    // loadModule("head-charts", "meta");
    // loadModule("navigation", "meta");
    // loadModule("build-head", "build");
    // //loadModule("build-attract","build");
    // //loadModule("mesh","meta");

    // loadModule("texts", "run");
    // loadModule("grids", "run");
    // loadModule("charts", "run");
    // loadModule("graph", "run");
    // loadModule("heatmap", "run");
    // loadModule("radar", "run");
    // loadModule("radialbarchart", "run");
    // //loadModule("attract","run");
    // //loadModule("attract-data","run");
    // loadModule("scroll", "run");
    // loadModule("build-attract", "attract");

    m.pub("onModulesLoaded");
    // if (m.common.isMain()) m.log.report("All modules are ready.");

    return;
  }

  require(path, lib, alias, phase = "meta", paras) {
    //import Mods from "./m.common/module/mods";
    //import {default as Mod} from path
    let Mod = require(path);
    alias = !alias ? lib.toLowerCase() : alias;
    m.mods.instantiate(Mod, lib, alias, phase, paras);
    return;
  }

  initPlugins(plugins) {
    if (m.input && m.input.plugins) {
      let plugins = m.input.plugins;
      let expects = [];
      if (m.env.channel && plugins[m.env.channel]) expects.push(...m.mods.initChannelPlugins(plugins[m.env.channel], m.env.channel));
      if (expects.length >= 0) m.pool("onPlugins", expects);
    }
  }

  initChannelPlugins(plugins, channel) {
    let expects = [];
    
    plugins.forEach((plugin) => {
      let path = m.env.pluginServer + "/m-plugin." + plugin + ".js";
      expects.push("onPlugin" + plugin.capitalize());

      let pluginEle = doc.createElement("script");
      pluginEle.id = "m-plugin."+ channel + "." + plugin;
      pluginEle.type = "module";
      pluginEle.src = path;
      if(m.env.doc) m.env.doc.body.insertBefore(pluginEle, m.env.doc.body.firstChild);
      //m.pub("onPlugin"+ plugin.capitalize());

      //m.plugin[initializedPlugin.name] = initializedPlugin.module;
    });
    if (expects.length >= 0) m.pool("on" + channel.capitalize() + "Plugins", expects);
    return expects;
  }

  initPlugin(path) {
    return require(path);
  }

  deployModule(obj, lib, phase) {
    if (!m.methods) m.methods = {};

    m[phase][lib] = obj;

    for (let key in obj) {
      if (!window[key]) window[key] = obj[key];
      m.methods[key] = obj[key];
    }
  }

  // // m.mods.instantiate(Adjacency, "Adjacency", "adjacency", "run");
  // // m.acts.expectDetailed(this.initAdjacency, ["onInit", "onAuthenticated", "onMarkup", "onMarkupSet", "runReady"]);
  // plugin(obj, lib, paras) {
  //   debugger
  //   m.acts.expectGo(["onInit", "onAuthenticated", "onMarkup", "onMarkupSet", "runReady"]).then(function () {
  //     //m.talk.wait("Your " + lib + " plugin is now instantiating...");

  //     if (!m.plugin) m.plugin = {class: {}, methods: {}};

  //     let alias = lib.toLowerCase();
  //     if (!m.plugin[alias]) {

  //       //m.mods.instantiate(obj, lib, alias, phase, paras);
  //       let usableMethods = ""
  //       if (!m.plugin.class[lib]) m.plugin.class[lib] = obj;
  //       if (!m.plugin[alias]) {
  //         m.plugin[alias] = new obj(paras);
  //         for (let key in obj) {
  //           m.plugin.methods[key] = obj[key];
  //           usableMethods += key + ", "
  //         }
  //       }

  //       m.pub("on" + lib);
  //       if (m.common.isMain()) m.log.done("Your " + lib + " plugin is ready to use with: 'm.plugin." + alias + "' the following methods are avalable: '" + usableMethods + "'");
  //       return m.plugin[alias];
  //     } else {
  //       let message = "This name is already in use.";
  //       let start = Date.now();
  //       m.err.throw(message, null, null, start);
  //     }
  //   });
  // }

  // readyInstatiate() {}

  instantiate(obj, lib, alias, phase, paras) {
    this.addClass(obj, lib, phase);
    m[alias || lib] = new obj(paras);
    this.register(obj, alias, phase);
    return m[lib];
  }

  addClass(obj, lib, phase) {
    if (!m[phase]) m[phase] = {};
    if (!m.class[lib]) m.class[lib] = obj;
    if (!m[phase][lib]) {
      this.deployModule(obj, lib, phase);
    } else {
      //debugger
    }
  }

  register(obj, lib, phase) {
    if (!m[phase]) m[phase] = {};
    if (!m[phase][lib]) {
      this.deployModule(obj, lib, phase);
    } else {
      //debugger
    }
  }

  loadModule(lib, phase) {
    if (!m[phase]) m[phase] = {};
    if (!m[phase][lib]) {
      let obj = require("./" + lib);
      this.deployModule(obj, lib, phase);
    } else {
      //debugger
    }
  }

  loadScript(doc, lib, phase) {
    if (!window[lib]) {
      let obj = require("../scripts/" + lib + ".script");
      //let id = "m.scripts" + hashString(lib);
      this.registerInitScript(doc, lib, obj, "head");
      //g.mesh[phase][lib] = obj;
    } else {
      //debugger
    }
  }

  registerInitScript(doc, hashedKey, innerHTML, bodyOrHead) {
    let key = hashedKey.toString().replace("-", "");

    let existingElements = doc.getElementById(key);
    if (!existingElements) {
      let scriptElm = doc.createElement("script");
      scriptElm.id = key;
      scriptElm.type = "text/javascript";
      scriptElm["data-exec"] = "°m-";
      scriptElm.innerHTML = innerHTML;
      doc[bodyOrHead].appendChild(scriptElm);
    }
  }

  npmToWindow(lib, path, file) {
    if (!window[lib]) {
      let filePath = "../node_modules/" + path + "/" + file;

      //require("../node_modules/rivescript/dist/rivescript")

      let obj = require(filePath);
      m.npm[lib] = obj;
      //window[lib] = obj;
    } else {
      //debugger
    }
  }

  /*
npmToWindow(lib, phase) {

    if(!window[lib]) {

        let obj = require(lib);        
        m[phase][lib]= obj;

    } else {
        //debugger
    }
}
*/
  //npmToWindow("core-js-bundle","core-js-bundle", "minified.js");
  //npmToWindow("jsonpath-plus","jsonpath-plus/dist", "index-browser-umd.js");
}

import Statemachine from "./statemachine";
import Workflow from "./workflow";
import Events from "./events";

export default class Acts {
  constructor() {
    m.mods.instantiate(Events, "Events", "evs", "meta");
    m.mods.instantiate(Workflow, "Workflow", "wf", "meta");
    m.mods.instantiate(Statemachine, "Statemachine", "statemachine", "meta");
    m.pub("onActing");
    m.sub = this.awaitExpect
    m.expect = this.expect
    m.pool = this.awaitExpects
  }

  async awaitExpects(newCause, causes = [], target) {
    let causesString = m.acts.getCausesString(causes);
    return m.acts.awaitExpect(causes).then((result) => {
      m.log.act.wait("Act combine ready with '" + causesString + "' and now raised '" + newCause + "'", result);
      m.pub(newCause, result, target);
    });
  }

  awaitExpect(causes = []) {

    if (typeof causes === "string") causes = [causes]

    let promises = m.acts.getPromises(causes);
    let promName = m.acts.getCausesString(causes, "");
    m.event.promises[promName] = {status: "pending", causes, promises}

    let prom = new Promise((resolve, reject) => {

      Promise.all(promises).then(function (result) { // .allSettled
        delete m.event.promises[promName]
        m.log.act.success("acts.subscribeCauses -> '" + promName + "' done (" + m.acts.getCausesString(causes) + ")");      
        if (result.length > 1) {
          resolve(result.map(res => res.data));
        } else {
          let out = result[0] && result[0].data ? result[0].data : null
          resolve(out);
        }
      }).catch(function(error){
        delete m.event.promises[promName]
        reject(error);
      });

    });
    return prom;

  }

  expectCombine(newCause, causes) {
    let causesString = this.getCausesString(causes);
    let promises = this.getPromises(causes);
    if (newCause && newCause !== "") {
      m.log.act.wait("Act combine '" + newCause + "' subscribed due to '" + causesString + "'");
      Promise.all(promises).then(() => {
        m.log.act.wait("Act combine ready with '" + causesString + "' and now raised '" + newCause + "'");
        m.pub(newCause);
      });
    } else {
      return Promise.all(promises);
    }
  }

  expectCauses(causes = [], shortName = null, message = "", finalStageName = null) {
    return function decorator(target, name, descriptor) {
      const original = descriptor.value;
      if (typeof original === "function") {
        descriptor.value = function (...args) {
          this.expectDetailed(original, causes, shortName, message, finalStageName);

          // m.log.act.report(`Arguments for ${name}: ${args}`);
          try {
            const result = original.apply(this, args);
            // m.log.act.report(`Result from ${name}: ${result}`);
            return result;
          } catch (e) {
            m.log.act.error(`acts.expectCauses -> Error from ${name}: ${e}`);
            throw e;
          }
        };
      }
      return descriptor;
    };
  }

  async response(cause) {
    let callback
    let prom =  new Promise(function (resolve, reject) {
      callback = (cause) => { 
        try {
          let response = m.event.data[cause];
          resolve(response)
        } catch (err) {
          reject(err)
        } 
      }
    }); 
    expectDetailed(callback, [cause], null, null, null, false, cause);
    return prom
  }

  expectNew(Cl, causes = [], shortName = null, message = null, finalStageName = null, runAsWorker = false) {
    fn = new Cl();
    return expectDetailed(fn, (causes = []), (shortName = null), (message = null), (finalStageName = null), (runAsWorker = false));
  }

  expect(fn, causes = [], params) {
    return m.acts.expectDetailed(fn, causes, null, null, null, false, params);
  }

  expectDetailed(fn, causes = [], shortName = null, message = null, finalStageName = null, runAsWorker = false, params) {
    let caller = g;

    if (Object.prototype.toString.call(fn) === "[object Function]") {
      //if (typeof fn === 'function') {

      let methodName = this.getFunctionName(fn);
      let name = shortName ? shortName : methodName;
      //let callerName = caller.m ? "window" : caller.prototype.constructor.name;
      //let cause = fn; //caller[methodName] ? caller[methodName] : m.methods ;

      let onEventName = name;
      let onenterEventName = name + "Processing";
      let onleaveEventName = finalStageName ? finalStageName : name + "Completed";
      message = !message ? name + " processing - final stage will be: " + onleaveEventName : message;

      m.evs.initAndListenEvent(onEventName);
      m.evs.initAndListenEvent(onenterEventName);
      m.evs.initAndListenEvent(onleaveEventName);

      function debug() {
        // debugger;
      }

      let newMethodName = "injected" + m.str.capitalize(name);
      //let newMethod = fn
      // let newMethod = function () {
      //   //debugger
      //   //let workerpool = require("../worker/index");
      //   //if (workerpool) workerpool.workerEmit.call(caller, { status: "processing" });
      //   let output = fn.apply(caller, arguments);
      //   //if (workerpool) workerpool.workerEmit.call(caller, { status: "done" });
      //   return output;
      // };
      m.methods[newMethodName] = fn; //newMethod

      let causesString = this.getCausesString(causes);

      if (m.arr.hasValues(causes)) return this.subscribeCauses(causes, fn, name + ": causes fullfilled: " + causesString, newMethodName, onenterEventName, onleaveEventName, params);
      return this.subscribeCauses([onEventName], fn, onEventName + ": explicitly published", newMethodName, onenterEventName, onleaveEventName, params);

      m.log.act.wait("acts.expect -> '" + name + "' injected by '" + causesString + "' (" + message + ")");
    } else {
      m.log.act.error("acts.expect ->  '" + shortName + "' ended with an error 'is not a valid function, is of type '" + Object.prototype.toString.call(fn) + "': \n" + fn);
    }
  }

  subscribeCauses(causes, fn, message, methodName = null, onenterEventName, onleaveEventName, params) {
    let promises = this.getPromises(causes);
    let causesString = this.getCausesString(causes);
    methodName = methodName ? methodName : this.getFunctionName(fn);
    m.event.promises[methodName] = {status: "pending", causes, fn, message, methodName, onenterEventName, onleaveEventName, params, promises}
    // m.log.act.wait("Act '" + methodName + "' subscribed due to '" + causesString + "' (" + message + ")");

    let prom = new Promise((resolve, reject) => {

    Promise.all(promises).then(function () {
      // m.event.promises = m.event.promises.filter(promise => promise !== this);
      if (Object.prototype.toString.call(fn) === "[object Function]") {
        //if (typeof fn === 'function') {

        //let methodName = [0].methodName; //fn.prototype.constructor.name;
        //let callerName = caller.m ? "window" : caller.prototype.constructor.name;
        //let originalMethod = window[methodName] ? window[methodName] : m.methods ;
        methodName = methodName ? methodName : this.getFunctionName(fn);
        delete m.event.promises[methodName]

        // m.log.act.success("Act '" + methodName + "' matched by '" + causesString + "' (" + message + ")");
        //let completion = 0;

        m.pub(onenterEventName, null, methodName);

        // try {
          // m.log.act.processing("Act '" + methodName + "' processing... (" + message + ")");
          let response = fn(params);
          m.pub(onleaveEventName, null, methodName, response);
          m.log.act.success("acts.subscribeCauses -> '" + methodName + "' done (" + causesString + ")");          
          resolve(response);
          //return response;
        // } catch (e) {
        //   m.log.act.error("acts.subscribeCauses -> '" + methodName + "' after the retry it again failed with '" + e.message + "'");
        // //   debugger
        // }

        //fn();
        //fn.call(originalMethod, message, completion);
      } else {
        // m.log.act.warn("Act '" + methodName + "' is no valid function for '" + causesString + "' (" + message + ")");
      }
    })
    // .catch(function(error){
    //   reject(error);
    // });
    
  })
  return prom;
  }

  subscribeWorkerCauses(causes, fn, message, methodName = null, onenterEventName, onleaveEventName) {
    let promises = this.getPromises(causes);
    let causesString = this.getCausesString(causes);
    methodName = methodName ? methodName : this.getFunctionName(fn);
    // m.log.act.wait("Worker '" + methodName + "' subscribed due to '" + causesString + "' (" + message + ")");

    let workerpool = require("../worker/index");
    let pool = workerpool.pool();

    let worker = {};
    for (let method in m.methods) {
      if (m.methods.hasOwnProperty(method)) {
        worker[method] = fn; //m.methods[method];
        // m.log.act.report("Worker '" + method + "' worker method registered: " + methodName); // +  m.methods[method] fn
      }
    }
    workerpool.worker(worker);
    //worker[methodName] = fn;

    Promise.all(promises).then(function () {
      if (Object.prototype.toString.call(fn) === "[object Function]") {
        //if (typeof fn === 'function') {

        //let methodName = [0].methodName; //fn.prototype.constructor.name;
        //let callerName = caller.m ? "window" : caller.prototype.constructor.name;
        //let originalMethod = window[methodName] ? window[methodName] : m.methods ;
        methodName = methodName ? methodName : this.getFunctionName(fn);

        // m.log.act.success("Worker '" + methodName + "' matched by '" + causesString + "' (" + message + ")");
        //let completion = 0;

        //workerpool.worker(worker);

        m.pub(onenterEventName, null, methodName);
        pool
          .exec(fn, [], {
            on: function (payload) {
              if (payload.status === "processing") {
                m.evs.triggerEvent.call(caller, onenterEventName, null, newMethodName);
                // m.log.act.processing("Worker '" + methodName + "' processing... (" + message + ")");
              } else if (payload.status === "done") {
                m.pub(onleaveEventName, null, newMethodName);
                // m.log.act.success("Worker '" + methodName + "' done (" + message + ")");
              }
            },
          })
          .then(function (result) {
            // m.log.act.success("Worker '" + methodName + "' ended with the result '" + result + "'");
          })
          .catch(function (err) {
            m.log.act.error("Worker '" + methodName + "' ended with an error '" + err + "', we now try it again with: \n" + fn);
            try {
              // m.log.act.processing("Act '" + methodName + "' processing... (" + message + ")");
              let response = fn();
              m.pub(onleaveEventName, response, methodName);
              // m.log.act.success("Act '" + methodName + "' done (" + message + ")");
              return response;
            } catch (e) {
              m.log.act.error("Act '" + methodName + "' after the retry it again failed with '" + e.message + "'");
            }
          })
          .then(function () {
            pool.terminate(); // terminate all workers when done
          });
        //fn();
        //fn.call(originalMethod, message, completion);
      } else {
        // m.log.act.warn("Worker '" + methodName + "' is no valid function for '" + causesString + "' (" + message + ")");
      }
    });
  }

  request(msg, target) {
    return new Promise(function (resolve, reject) {
      let seq = ++m.bridge.__seq;

      let event_ref = g.addEventListener(
        "message",
        function (resp) {
          if (resp && resp.data && resp.data.__seq && resp.data.__seq == seq && resp.data.response && resp.data.mesh && resp.data.source.channel === m.env.channel) {
            g.removeEventListener("message", event_ref);
            //if (msg.source.channel === "host") debugger
            resolve(resp.data);
          }
        },
        false
      );

      let text = { ...msg, __seq: seq };
      try {
        if (msg.source.channel === "lab" || msg.target.channel === "lab") {
          target.postMessage(text); //text, "*"   // {type: text, action: {}}
        } else {
          target.postMessage(text, "*");
        }
      } catch (err) {
        // debugger
      }
    });
  }

  await(causes) {
    //debugger
    (async () => {
      let promises = this.getPromises(causes);
      await Promise.all(promises)
      //debugger
    })();
    //debugger
  }

  expectGo(causes, newCause = "") {
    let causesString = this.getCausesString(causes);
    let promises = this.getPromises(causes);
    // m.log.act.wait("Act async subscribed due to '" + causesString + "'");
    return Promise.all(promises);
  }

  reportCauses(causes, message = "") {
    let promises = this.getPromises(causes);
    let causesString = this.getCausesString(causes);

    causesString = message === "" ? causesString : causesString + ": " + message;

    Promise.all(promises).then(function () {
      // m.log.act.list("Act '" + causesString + "'");
    });
  }

  getPromises(causes) {
    let promises = [];
    causes.forEach((ev) => {
      let eve = m.promises[ev];
      if (!eve) {
        m.evs.initEvent(ev);
        m.evs.listenEvent(ev);
        eve = m.promises[ev];
      }
      promises.push(eve);
    });

    return promises;
  }

  getCausesString(causes, splitter = ", ") {
    let causesString = "";
    causes.forEach((ev) => {
      causesString += ev + splitter;
    });

    let last2char = causesString.substring(causesString.length - 2, causesString.length);
    causesString = last2char !== splitter ? causesString : causesString.substring(0, causesString.length - 2);
    return causesString;
  }

  poolCauses(causes, eventName) {
    m.evs.initAndListenEvent(eventName);

    let promises = this.getPromises(causes);
    let causesString = this.getCausesString(causes);
    // m.log.act.wait("Act '" + eventName + "' subscribed by '" + causesString + "'");

    Promise.all(promises).then(function () {
      // m.log.act.success("Act '" + eventName + "' matched by '" + causesString + "'");
      m.pub(eventName, null, " matched => " + causesString);
    });
  }

  actOnAction(id, elementAction) {
    let ele = $.m.get(document, id);
    if (ele && ele[elementAction])
      ele[elementAction](function () {
        //subscrirbeEvents(["buildApp"], progress, "building your app");
        m.evs.initAndListenEvent(id + m.str.capitalize(elementAction));
        //buildApp();
      });

    return id + m.str.capitalize(elementAction);
  }

  actOnInsert(elementId, eventName) {
    m.evs.initAndListenEvent(eventName);

    // m.log.act.wait("Act '" + eventName + "' listening for '" + elementId + "'");
    $("head").on("DOMNodeInserted", function (e) {
      let scriptTag = $.m.get(document, elementId);
      if (scriptTag) {
        // m.log.act.wait("Act '" + elementId + "' inserted '" + eventName + "'");
        m.pub(eventName, null, elementId + " -> inserted => " + eventName);
      }
    });
  }

  getFunctionName(fn) {
    let methodName;
    methodName = fn.name ? fn.name : fn.prototype.constructor.name;
    return methodName;
  }
}

export class Act {
  constructor(fn, causes = [], shortName = null, message = "", finalStageName = null) {
    this.fn = fn;
    this.shortName = shortName;
    this.message = message;
    this.finalStageName = finalStageName;
    this.causes = causes;
    this.name = m.acts.getFunctionName(fn);
    this.expect(causes);
  }

  process() {
    return this.fn.apply(this, arguments);
  }

  expect(causes = []) {
    this.causes = this.causes.push(...causes);
    return m.acts.expectDetailed(this.fn, this.causes, this.shortName, this.message, this.finalStageName);
  }

  getPromises() {
    return m.acts.getPromises(this.causes);
  }
}

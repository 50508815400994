import BaseClass from "./baseClass.mjs";

class ChildClass extends BaseClass {

    constructor(ele, parent, tag) {

        super(ele, tag);

        this.parent = parent;
        this.parentId = parent.id;
        this.parentLocalId = parent.localId;
        this._setId();
        this.schema.parentId = true;

    }

    toMarkup() {

        let outElement = super.toMarkup();
        outElement.dataset.parentId = this.parent.localId;

        return outElement;
    }

    _setId () {
        this.id = this.parent.id + "." + this.localId;
    }

};

//module.exports = ChildClass
export default ChildClass

import Graph from "./source/graph"
import Items from "./source/items.js";
import Unit from "./source/unit.js";
import Measurable from "./source/measurable";
import Mention from "./source/mention.js";
import Name from "./source/name.js";
import Scope from "./source/scope.js";
import Source from "./source/source.js";
    
(function() { 
    window.m = window.m ? window.m : {}
    m.class = m.class ? m.class : {}

    //m.plugin.add(Adjacency, "Adjacency") 
    if (m.mods) m.mods.addClass(Graph, "Graph", "graph"); else m.class.Graph = Graph
    m.graph = m.graph ? m.graph : Graph
    
    m.measurables = m.measurables ? m.measurables : new Items(Measurable)
    m.units = m.units ? m.units : new Items(Unit)
    m.mentions = m.mentions ? m.mentions : new Items(Mention)
    m.names = m.names ? m.names : new Items(Name)
    m.scopes = m.scopes ? m.scopes : new Items(Scope)
    m.sources = m.sources ? m.sources : new Items(Source)

    m.graphs = m.graphs ? m.graphs : new Items(Graph)
    
    //m.mods.addClass(Graph, "Graph", "graph"); 
})();

import Items from "./items.js";
import Item from "./item.js";
import Topic from "./topic.js";
import Link from "./link.js";

export default class Graph extends Item {
	#name;
	#settings;

	#topics;
	#links;

	#default;

	#nodes;
	#edges;

	constructor(items, name, settings, topics, links, sourceHref) {
		super(items);
		this.basing = () => `${this.#name}`;

		this.#default = {};
		this.#default.topic = {};
		this.#default.link = {};

		this.#default.source = m.sources.create(sourceHref);

		// this.#default.classification.factor = settings.defaultClassificationFactor
		// this.#default.classification.value = settings.defaultClassificationValue
		// this.#default.classification.unit = m.units.create(settings.defaultClassificationUnitName)
		// this.#default.classification.measurable = m.measurables.create(settings.defaultClassificationMeasurableName)
		// this.#default.classification.mention = m.mentions.create(this.#default.source)

		this.#default.topic.factor = settings.defaultTopicFactor;
		this.#default.topic.value = settings.defaultTopicValue;
		this.#default.topic.unit = m.units.create(settings.defaultTopicUnitName);
		this.#default.topic.measurable = m.measurables.create(settings.defaultTopicMeasurableName);
		this.#default.topic.mention = m.mentions.create(this.#default.source);

		this.#default.topic.factor = settings.defaultLinkFactor;
		this.#default.link.value = settings.defaultlinkValue;
		this.#default.link.unit = m.units.create(settings.defaultLinkUnitName);
		this.#default.link.measurable = m.measurables.create(settings.defaultLinkMeasurableName);
		this.#default.link.mention = m.mentions.create(this.#default.source);

		this.#topics = topics || new Items(Topic);
		this.#links = links || new Items(Link);

		this.update(name, settings, topics, links);
		this.init();
	}

	update(name, settings, topics, links) {
		if (name) this.#name = name;
		if (settings) this.#settings = settings;
		// topics.forEach(topic => this.addTopic(topic.name))
		// links.forEach(link => this.addLink(link.source, link.target, link.weight, unit, measurable, mention))

		this.calculate();
		this.calculateNodesEdges();
		this.store();
	}

	get default() {
		return this.#default;
	}

	get nodes() {
		return this.#nodes;
	}

	get edges() {
		return this.#edges;
	}

	set topics(newTopics) {
		this.#topics = newTopics;
		this.update();
	}

	set links(newLinks) {
		this.#links = newLinks;
		this.update();
	}

	get topics() {
		return this.#topics;
	}

	get settings() {
		return this.#settings;
	}

	set settings(options) {
		this.#settings = options;
	}

	get links() {
		return this.#links;
	}

	calculateNodesEdges() {
		this.#nodes = new Array();
		this.#topics.forEach((topic) => {
			this.addNode(topic);
		});
		this.#edges = new Array();
		this.#links.forEach((link) => {
			this.addEdge(link);
		});
	}

	addTopic(name) {
		let topic = this.topics.create(name);

		// this.addNode(topic);
		// this.calculateNodesEdges()
		// this.calculate()
	}

	addLink(source, target, value, unit, measurable, mention) {
		let link = this.links.create(source, target);
		let weight = link.createWeight(value, unit, measurable, mention);

		link.target.addWeight(weight);
		link.source.addWeight(weight);

		// this.addEdge(link);
		// this.calculateNodesEdges()
		// this.calculate()
	}

	static createFromMatrix(matrix, name, settings, sourceHref) {
		let graph = m.graphs.create(name, settings, undefined, undefined, sourceHref);

		let topics = [...new Set([...matrix.rowLabels, ...matrix.columnLabels])];
		let outTopics = graph.importTopics(topics);

		let links = d3.merge(
			matrix.rows.map((row, i) =>
				row
					.map((item, j) => ({
						source: graph.topics.readByName(matrix.rowLabels[i].id), // entity's indexes within topics
						target: graph.topics.readByName(matrix.columnLabels[j].id),
						value: item.isTop ? item.normalizedValue / 2 : 1,
					}))
					.filter((d) => d.value > 0)
			)
		);

		// let links = d3.merge(
		// 	matrix.rows.forEach((row, rowIndex) => {
		// 		row.forEach((item, colIndex) => {
		// 			let link = {};
		// 			if (item.normalizedValue > 0) {
		// 				if (item.isTop) {
		// 					link = {
		// 						source: graph.topics.readByName(matrix.rowLabels[rowIndex].id), // entity's indexes within topics
		// 						target: graph.topics.readByName(matrix.columnLabels[colIndex].id),
		// 						value: matrix.get(rowIndex, colIndex),
		// 					}
		// 				} else {
		// 					link = {
		// 						source: graph.topics.readByName(matrix.rowLabels[rowIndex].id), // entity's indexes within topics
		// 						target: graph.topics.readByName(matrix.columnLabels[colIndex].id),
		// 						value: 1,
		// 					}
		// 				}
		// 			}
		// 			return link;
		// 		  }).filter((d) => d.value > 0);
		// 	  }
		// 	)
		// );

		let outLinks = graph.importLinks(links);
		graph.update();
		// let graphs = new Items(Graph)
		return graph;
	}

	importTopics(inTopics) {
		//let topics = new Items(Topic)
		let tempTopics = [...inTopics] || [];

		tempTopics.forEach((tempTopic, i) => {
			let topic = this.topics.create(this, tempTopic.id, tempTopic.type, tempTopic.weight); //, this.default.topic.unit, this.default.topic.measurable, this.default.topic.mention
			topic.id = i;
		});

		return this.topics;
	}

	importLinks(inLinks) {
		//let links = new Items(Link)
		let tempLinks = [...inLinks] || [];

		tempLinks.forEach((tempLink) => {
			let link = this.links.create(this, tempLink.source, tempLink.target);
			link.id = link.source.id + "|" + link.target.id;
			let weight = link.createWeight(tempLink.value);

			link.target.addWeight(weight);
			link.source.addWeight(weight);
		});
		return this.links;
	}

	addNode(topic) {
		// initialize the adjacent list with a
		// null array
		let node = { id: topic.id, name: topic.name, neighbors: topic.neighbors.array, links: topic.links.array, size: topic.size, type: topic.topicType, fill: topic.fill, significance: topic.significance, topic, ...topic.metrics };
		//if (!this.nodes.get(node)) this.nodes.set(node, []);
		this.nodes.push(node);
	}

	addEdge(link) {
		// get the list for topic v and put the
		// topic w denoting link between v and w
		//this.nodes.get(fromNode).push(toNode);

		// Since graph is undirected,
		// add an link from w to v also
		//this.nodes.get(w).push(v);
		let edge = { id: link.id, source: link.source.id, target: link.target.id, opacity: link.opacity, width: link.width, distance: link.distance, significance: link.significance, ...link.metrics };
		this.edges.push(edge);
	}

	calculate(filterFn) {
		if (this.topics.length > 0) this.calculateShares(this.topics, filterFn);
		if (this.links.length > 0) this.calculateShares(this.links, filterFn);
	}

	calculateShares(items, filterFn) {
		items.metrics = items.metrics ? items.metrics : {};
		items.metrics.min = this.weight(items, filterFn, math.min);
		items.metrics.max = this.weight(items, filterFn, math.max);
		items.metrics.mean = this.weight(items, filterFn, math.mean);
		items.metrics.sum = this.weight(items, filterFn, math.sum);
		items.metrics.std = this.weight(items, filterFn, math.std);
		items.metrics.variance = this.weight(items, filterFn, math.variance);
		items.metrics.median = this.weight(items, filterFn, math.median);

		items.forEach((item) => {
			item.metrics = item.metrics ? item.metrics : {};
			let weight = item.weight();
			item.metrics.shareSum = weight / items.metrics.sum;
			item.metrics.shareMean = weight / items.metrics.mean;
			item.metrics.shareMax = weight / items.metrics.max;
			item.metrics.weight = weight;
		});
	}

	calculateWeight(items, calculateFn) {
		let arr = items.map((item) => item.weight());
		return calculateFn(...arr);
	}

	weight(items, filterFn, calculateFn) {
		let result = items.map(function (item) {
			let temp = item.weight(filterFn);
			return temp;
		});
		let calculatedResult = calculateFn(result);

		// let result =  items.reduce((accumulator, item, total, array) => {
		// 	let temp = item.weight(filterFn, calculateFn) + total
		// 	return temp
		// }, 0)
		// let calculatedResult = calculateFn(result);

		return calculatedResult;
	}

	print() {
		// get all the topics
		var get_keys = this.nodes.keys();

		// iterate over the topics
		for (var i of get_keys) {
			// great the corresponding adjacency list
			// for the topic
			var get_values = this.nodes.get(i);
			var conc = "";

			// iterate over the adjacency list
			// concatenate the values into a string
			for (var j of get_values) conc += j + " ";

			// print the topic and its adjacency list
			//m.log.report(i + " -> " + conc);
		}
	}

	bfs(startingTopic) {
		// create a visited object
		var visited = {};

		// Create an object for queue
		var q = new Queue();

		// add the starting topic to the queue
		visited[startingTopic] = true;
		q.enqueue(startingTopic);

		// loop until queue is element
		while (!q.isEmpty()) {
			// get the element from the queue
			var getQueueElement = q.dequeue();

			// passing the current topic to callback function
			//m.log.report(getQueueElement);

			// get the adjacent list for current topic
			var get_List = this.nodes.get(getQueueElement);

			// loop through the list and add the element to the
			// queue if it is not processed yet
			for (var i in get_List) {
				var neigh = get_List[i];

				if (!visited[neigh]) {
					visited[neigh] = true;
					q.enqueue(neigh);
				}
			}
		}
	}

	dfs(startingTopic) {
		var visited = {};

		this.DFSUtil(startingTopic, visited);
	}

	DFSUtil(vert, visited) {
		// Recursive function which process and explore
		// all the adjacent topic of the topic with which it is called
		visited[vert] = true;
		//log.info(vert);

		var get_neighbours = this.nodes.get(vert);

		for (var i in get_neighbours) {
			var get_elem = get_neighbours[i];
			if (!visited[get_elem]) this.DFSUtil(get_elem, visited);
		}
	}
}

export { Graph };

import Item from "./item.js";

export default class Mention extends Item {
	#char;
	#phrase;
	
	constructor(items, char, phrase) {
        super(items);
		this.basing = () => `${this.#phrase}`
		this.#char = char
		this.#phrase = phrase
		this.init()
	}

	update(char, phrase) {
		this.#char = char
		this.#phrase = phrase
		this.store()
	}

	get char() {
		return this.#char;
	}

	get phrase() {
		return this.#phrase;
	}

}
// import sha256 from 'crypto-js/sha256';
const CryptoJS = require("crypto-js");

export default class Id {
  constructor() {
    // //m.talk.wait("Id handling is preparing...");

    String.prototype.hash = function () {
      return this ? m.id.getHash(this) : null;
    };

    String.prototype.id = function () {
      return this ?this.slug() + "-" + Date.now() : m.id.getRandomCode() + "-" + Date.now();
    };

    String.prototype.encrypt = function (pw) {
      return this ? m.id.encrypt(this, pw) : null;
    };

    String.prototype.decrypt = function (pw) {
      return this ? m.id.decrypt(this, pw) : null;
    };

    // m.talk.message("Id handling is ready.");
    // m.pub("onInitializedIdHandling");
  }

  getHash(text) {
    let hash = CryptoJS.SHA256(text);
    let str = hash.toString(CryptoJS.enc.Base64)
    return str //sha256.hmac(m.user.salt, text);
  }

  decrypt(encryptedData, pw) {  
    var bytes  = CryptoJS.AES.decrypt(encryptedData, pw);
    let decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted.toString()
  }
  
  encrypt(data, pw) {
    var encrypted = CryptoJS.AES.encrypt(data, pw).toString();  
    return encrypted
  }

  getRandomCode() {
    return Math.random().toString(36).substr(2, 9);
  }

  getContentId(element) {
    let contentId = "";
    element.classList.forEach((c) => {
      if (c.toString().includes("content")) {
        contentId = c;
      }
    });
    //contentId = contentId ? contentId :  this.setContentIdByElement(element, self, parent, type);
    return contentId;
  }

  setContentIdByString(str, selfitem, parent, type) {
    let code;
    if (str) {
      code = this.getHash(str);
    }

    let structureId = this._setContentId(code, selfitem, parent, type);
    return structureId;
  }

  setContentIdByStructure(selfitem, parent, type) {
    let code;
    if (selfitem && selfitem.text && selfitem.text.length >= 50) {
      code = this.getHash(selfitem.text);
    } else if (selfitem.excerpt && selfitem.excerpt.length >= 50) {
      code = this.getHash(selfitem.excerpt);
    } else {
      code = this.getRandomCode();
    }

    let structureId = this._setContentId(code, selfitem, parent, type);
    return structureId;
  }

  _setContentId(code, that, parent, type) {
    let parentId = parent ? parent.id : "mesh";
    let contentId = parentId + "." + type.toLocaleLowerCase() + code; //.substr(0, 3)

    /*
    if (type === "page") {

      //self.index[contentId] = self;
    } else if (type === "article") {
      contentId = contentId + base.getRandomCode();
      that.id = contentId;
    } else if (typeof(parent) === "Page" || !parent) {
      if (!that.index) that.index = [];
      that.index[contentId] = that;
      that.id = contentId;
    } else {
      if (parent.page.index[contentId]) {
        parent.page.index[contentId] = that;
        that.id = contentId;
      } else {
        contentId = contentId + base.getRandomCode();
        parent.page.index[contentId] = that;
        that.id = contentId;
      }
    }
    */

    return contentId;
  }

  makeIdentifiableObject(source) {
    source.ID = source.id || source.key || source._id || source._key || source.ID || this.getRandomCode();
    /*
     for (var property in source) {
     if (source.hasOwnProperty(property)) {
     switch (property) {
     case 'id':
     source.ID = source.id;
     break;
     case 'key':
     source.ID = source.key;
     break;
     case '_id':
     source.ID = source._id;
     break;
     case '_key':
     source.ID = source._key;
     break;
     case 'slug':
     source.ID = source.slug;
     break;
     default:
     source.ID = base.getRandomCode();
     break;
     };
     // do stuff
     }
     }
     */

    return source;
  }

  isIdentifiableObject(source) {
    if (!source || source === null) {
      return false;
    } else {
      if (source.id || source.key || source._id || source._key) {
        source = this.makeIdentifiableObject(source);
        return true;
      }
      return false;
    }
  }

  isIdentifiableArray(source) {
    if (!source || source === null) {
      return false;
    } else {
      if (Array.isArray(source) && source.length > 0) {
        source.forEach((item) => {
          if (!this.isIdentifiableObject(item)) return false;
        });
        return true;
      }
      return false;
    }
  }
}

import ChildClass from "./childClass.mjs";

class MidClass extends ChildClass {

    constructor(ele, parent, childType, tag) {

        super(ele, parent, childType, tag);

        this._childType = childType;
        this.children = [];
        let types = (childType + "s").toLowerCase()
        this[types] = this.children;

        this.schema[types] = true;

    }

    getSentences() {

        let sentences = [];

        this.children.forEach(child => {
            if (child.sentences) {
                sentences.push(...child.sentences);
            } else {
                sentences.push(...child.getSentences());
            }
        });

        return sentences;
    }

    toMarkup() {

        let outElement = super.toMarkup();

        this.children.forEach(child => {
            let markup = child.toMarkup();
            outElement.append(markup)
        });

        return outElement;
    }

    toText() {
        let text = "";
        this.children.forEach(child => {
            text = text.trim() + " " + child.toText();
        });
        return text;
    }

};

//module.exports = ChildClass;
export default MidClass

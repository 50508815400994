import MeshClass from "./meshClass.mjs";
//import id from '../@mesh./m.common/source/id.mjs';
// import base from '../@mesh./m.common/source/base.mjs';

class BaseClass extends MeshClass{

    constructor(ele, tag) {

        super();

        this.element = ele;

        if (ele.getAttribute("m-uuid")) this.uuid = ele.getAttribute("m-uuid");
        if(ele.getAttribute("m-contentScore")) this.contentScore = ele.getAttribute("m-contentScore");
        if(ele.getAttribute("m-contentBonus")) this.contentBonus = ele.getAttribute("m-contentBonus");
        if(ele.getAttribute("m-linkDensity")) this.linkDensity = ele.getAttribute("m-linkDensity");
        if(ele.getAttribute("m-siblingScore")) this.siblingScore = ele.getAttribute("m-siblingScore");
        if(ele.getAttribute("m-nodeLength")) this.nodeLength = ele.getAttribute("m-nodeLength");

        this._setLocalId(this.element.textContent);
        this.element.setAttribute("m-id", this.localId);

        this.schema.localId = true;
        if (this.uuid) this.schema.uuid = true;
        if (this.contentScore) this.schema.contentScore = true;
        if (this.contentBonus) this.schema.contentBonus = true;
        if (this.linkDensity) this.schema.linkDensity = true;
        if (this.siblingScore) this.schema.siblingScore = true;
        if (this.nodeLength) this.schema.nodeLength = true;

    }

    get originalElement () {
        let originalElement =  this.getOriginalElement(this.element) || this.getLocalElement (this.localId)
        return originalElement
    }

    getLocalElement (localId) {
        let dom = m.source.dom
        let originalEle = m.html.getElementByUuid(dom, localId)
        return originalEle
    }

    getOriginalElement (ele) {
        if (!this.origElement) {
            let dom = m.source.dom
            let uuid = this.uuid ? this.uuid : m.html.getElementUuid(ele)
            this.origElement = m.html.getElementByUuid(dom, uuid)
        } 
        return this.origElement
    }

    get getTopicElements () {
        let elements  = this.originalElement.getElementsByTagName("topic")
        return elements
    }

    get tempContent () {
        const str =  this.originalElement ? this.originalElement.innerHTML : "<!-- no html -->"    

        const regex = /(?<tag>\<[\/]{0,1}.+?\>)/gm; 
        m.i = 0
        let items = [];
        const result = str.replace(regex, function(match, group) {
            items.push(match)
            let placeholder = ` §§§${m.i}§§§ `
            m.i++
            return placeholder
        });

        m.log.read.report('Substitution text: ', result);
        m.log.read.report('Substituted items: ', items);
        return this.originalElement ? {text: result, items: items} : "<!-- no html -->"
    }

    set tempContent (exchanger) {
        let localId = this.localId; 
        const str =  exchanger.text 

        let templateFn = exchanger.template ? exchanger.template : groups => `<topic name="${groups.group1}" mentionId="${groups.i}" description="http://dbpedia.org/resource/${groups.group1}">${groups.group2}</topic>`;
        let regex2 = exchanger.reg ? exchanger.reg : /\<a href\=\"http\:\/\/dbpedia\.org\/resource\/([A-Za-z_]*)\".+?\>(.+?)<\/a>/gm;
        m.i = 0
        const result2 = str.replace(regex2, function(match, group1, group2) {
            let groups = {group1, group2, i: localId + "." + m.i}
            let item = templateFn(groups)
            m.i++
            return item
        });

        const regex = /(\ \§\§\§(?<ind>[0-9]*)\§\§\§\ )/gm;
        m.i = 0
        const result = result2.replace(regex, function(match, group) {
            let item = `${exchanger.items[m.i]}`
            m.i++
            return item
        });   

        this.innerHTML = result;

        m.log.read.report('Restored text: ', this.innerHTML);
    }

    get innerHTML () {
        return this.originalElement ? this.originalElement.innerHTML : "<!-- no html -->"
    }

    set innerHTML (inHtml) {
        if (this.innerHTML !== "<!-- no html -->") this.originalElement.innerHTML = inHtml
    }

    toJson() {
        let outElement = m.schema.objectToJsonMapping(this);
        return outElement;
    }

    toMarkup() {
        let outElement = super.toMarkup();
        outElement.id = this.localId;
        if (this.uuid) outElement.setAttribute("m-uuid", this.uuid);
        if (this.contentScore) outElement.setAttribute("m-contentScore", this.contentScore);
        if (this.contentBonus) outElement.setAttribute("m-contentBonus", this.contentBonus);
        if (this.linkDensity) outElement.setAttribute("m-linkDensity", this.linkDensity);
        if (this.siblingScore) outElement.setAttribute("m-siblingScore", this.siblingScore);
        if (this.nodeLength) outElement.setAttribute("m-nodeLength", this.nodeLength);
        return outElement;
    }    

	setTfidf() {
        this.tfidf = m.index.tfidf(this.localId)
        this.records = this.tfidf.records
        this.corpus = this.tfidf.corpus
        this.texts = this.tfidf.texts
        this.text = this.tfidf.text
	}

	topTerms() {
        this.terms = this.tfidf.corpus.getTopTermsForDocument(this.localId)
        return this.terms
	}

	similarity() {
        const similarity = new Similarity(this.tfidf.corpus);
        const distanceMatrix = similarity.getDistanceMatrix();
        return distanceMatrix
	}

    toText() {       
        return this.element.textContent;
    }

    _setLocalId (toBeHashed) {
        toBeHashed = toBeHashed.trim();
        let hash =  m.id.getHash(toBeHashed);
        let type = this.class;
        this.localId = type + ":" + hash;
    }

};

//module.exports = BaseClass
export default BaseClass

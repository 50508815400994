import Item from "./item.js";
import Position from "./position.js";
import Items from "./items.js";

export default class Mention extends Item {
	#source;
	#positions;
	
	constructor(items, source) {
        super(items);
		this.basing = () => `${this.#source.name}`
		this.#source = source
		this.#positions = new Items(Position)
		this.init()
	}

	update(source) {
		this.#source = source
		this.store()
	}

	get source() {
		return this.#source;
	}

	get positions() {
		return this.#positions;
	}

	addPosition(char, phrase) {
		this.#positions.create(char, phrase)
	}

}
export class Change {

	#datetime;
	#base;
    hash;
    prevHash;
    #nonce;

	constructor(base) {	
		this.#datetime = Date.now();
		this.#base = base;
        //this.hash = this.getHash()
        this.#nonce = 0;        
        this.prevHash = "";
    }

	get nonce() {
		return this.#nonce;
	}

    mine(difficulty) {
        while (!this.hash.startsWith(Array(difficulty + 1).join("0"))) {
            this.#nonce++;
            this.hash = this.getHash();
        }
    }

	get datetime() {
		return this.#datetime;
	}

	get base() {
		return this.#base;
	}

	getHash() {
		return (this.prevHash + "|" + this.#datetime.toString() + "|" + this.#base + "|" + this.#nonce).hash();
	}
}

export default class Changes {
    constructor() {
        this.chain = [new Change("init")];
        this.difficulty = 1;
        this.changeTime = 30000;
    }

    getLastChange() {
        return this.chain[this.chain.length - 1];
    }

    add(base) {
        let change = new Change(base)
        change.prevHash = this.getLastChange().hash;
        change.hash = change.getHash();
        //change.mine(this.difficulty);
        this.chain.push(Object.freeze(change));

        this.difficulty += Date.now() - parseInt(this.getLastChange().timestamp) < this.changeTime ? 1 : -1;
        return change
    }

    isValid() {
        for (let i = 1; i < this.chain.length; i++) {
            const currentChange = this.chain[i];
            const prevChange = this.chain[i-1];

            if (currentChange.hash !== currentChange.getHash() || prevChange.hash !== currentChange.prevHash) {
                return false;
            }
        }

        return true;
    }
}
//import HtmlSanitizer from '../components/HtmlSanitizer.mjs';
// import { createRequire } from 'module'
// const require = createRequire(import.meta.url);
// import texting from "./texting.mjs";
//import $ from "jquery";
//import { isBrowser } from "browser-or-node";

// import("../worker/controller/object.js")
// import("../worker/controller/property.js")
// import("../worker/controller/controller.js");

//import 'jsdom-global/register'
//import 'jsdom-worker'

export default class Ui {
  constructor() {
  }

  showLoading() {    
    m.cast("onLoading")
  }

  hideLoading() {    
    m.cast("onNotLoading")
  }

  toggleLoading() {    
    m.cast("onToggleLoading")
  }

  showOverlay() {    
    m.cast("onOverlay")
  }

  hideOverlay() {    
    m.cast("onNotOverlay")
  }

  toggleOverlay() {
    this.toggleBackground()
    this.toggleClosable()
  }  
  
  // toggleOverlay() {    
  //   m.cast("onToggleOverlay")
  // }

  isClosable() {    
    m.cast("onClosable")
  }

  isNotClosable() {    
    m.cast("onNotClosable")
  }

  toggleClosable() {    
    m.cast("onToggleClosable")
  }

  showBackground() {    
    m.cast("onBackground")
  }

  hideBackground() {    
    m.cast("onNotBackground")
  }

  toggleBackground() {    
    m.cast("onToggleBackground")
  }

  showMenu() {    
    m.cast("onMenu")
  }

  hideMenu() {    
    m.cast("onNotMenu")
  }

  toggleMenu() {    
    m.cast("onToggleMenu")
  }

  showLogo() {    
    m.cast("onLogo")
  }

  hideLogo() {    
    m.cast("onNotLogo")
  }

  toggleLogo() {    
    m.cast("onToggleLogo")
  }

  // toggleBackground(stage) {
  //   // g.Alpine.store('status').toggleOverlay()
  //   m.ui.toggleBackgroundBySelector();
  //   //m.ui.toggleVisibilityBySelector(".m-app");
  //   // let overlayEvent = ((stage && stage === true) || !m.status.overlay) ? "onOverlay" : "onNotOverlay"
  //   //let mainOverlayEvent = ((stage && stage === true) || !m.status.overlay) ? "onMainOverlay" : "onMainNotOverlay"
  //   // m.cast("onToggleOverlay")
  //   //m.ui.toggleClosable()
  //   // m.pub("onToggleOverlay", null, "host")
  // }  
  
  // toggleClosable(stage) {
  //   //g.Alpine.store('status').toggleClosable()
  //   // m.status.closable = !m.status.closable;
  //   m.ui.toggleClosableBySelector(".m-tools");
  //   // let closableEvent = ((stage && stage === true) || !m.status.closable) ? "onClosable" : "onNotClosable"
  //   //m.pub(closableEvent, null, "main")
  //   //m.cast(closableEvent)
  // }
  
  // toggleLoading(stage) {
  //   // g.Alpine.store('status').toggleLoading()
  //   m.ui.toggleLoadingBySelector(".m-loader");
  //   // m.status.loading = !m.status.loading;
  //   // let loadingEvent = ((stage && stage === true) || !m.status.loading) ? "onLoading" : "onNotLoading"
  //   // m.pub(loadingEvent)
  //   // m.pub(loadingEvent, null, "main")
  //   // m.cast(loadingEvent)
  // }  

  // toggleOverlay() {  
  //   this.toggleClass(document.querySelector("m-app"), "hidden");
  //   this.toggleClass(document.querySelector("m-logo"), "hidden");
  //   m.ui.toggleClosableBySelector(".m-tools");
  // }

  envToggleApp(stage) {
    this.envToggleHiddenBySelector("m-app", stage);
  }

  envToggleBackground(stage) {
    this.envToggleHiddenBySelector(".m-background", stage);
  }

  envToggleLogo(stage) {
    this.envToggleHiddenBySelector("m-logo", stage);
  }

  envToggleMenu(stage) {
    this.envToggleHiddenBySelector("m-menu", stage);
  }

  envToggleClosable(stage) {
    let element = document.querySelector(".m-tools");
    this.envToggleClass(element, "close", stage);
  }

  envToggleLoading(stage) {
    let element = document.querySelector(".m-loader");
    this.envToggleClass(element, "loading", stage);
  }

  envToggleHiddenBySelector(selector, stage) {
    let element = document.querySelector(selector);    
    if (element) {
      this.envToggleClass(element, "hidden", stage);
    } else {
      console.log("element not found")
    }
  }

  envToggleClass (element, stringClass, stage) {
    if (element) {
      if (element.classList.contains(stringClass) || stage === true) element.classList.remove(stringClass);
      else element.classList.add(stringClass);
    } else {
      console.log("element not found")
    }
  }
}

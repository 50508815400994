import Changes from "./change.js";
export default class Items {
	// extends Set

	#meta;

	constructor(ItemClass, basing = () => "", input) {
		this.#meta = {};
		this.#checkCallback(basing, "constructor");
		this.#meta.array = input || [];
		this.#meta.ItemClass = ItemClass;
		this.#meta.basing = ItemClass.baseline || basing;
		// if (input) this.#meta.itemSet = new Set(input)
		// if (!input) this.#meta.itemSet = new Set()

		// this[Symbol.iterator] = function() {
		// 	let n = 0;
		// 	done = false;
		// 	return {
		// 	next() {
		// 		n += 10;
		// 		if (n == 100) {done = true}
		// 		return {value:n, done:done};
		// 	}
		// 	};
		// }

		this.#meta.changes = new Changes();
	}

	get list() {
		return this.#meta.array;
	}

	get set() {
		return new Set(this.#meta.array);
	}

	get Item() {
		return this.#meta.ItemClass;
	}

	get changes() {
		return this.#meta.changes;
	}

	get basing() {
		return this.#meta.basing;
	}

	get base() {
		let base = this.actives.reduce((totalBase, item) => totalBase + " " + item.base);
		return base;
	}

	get array() {
		return this.#meta.array;
	}

	read(uuid) {
		return this.readByUuid(uuid);
	}

	readByUuid(uuid) {
		let result = this.actives.find((item) => item.uuid === uuid);
		return result;
	}

	readById(id) {
		let result = this.actives.find((item) => item.id === id);
		return result;
	}

	readByHash(hash) {
		let result = this.actives.find((item) => item.hash === hash);
		return result;
	}

	readByBase(base) {
		let result = this.actives.find((item) => item.base === base);
		return result;
	}

	readByName(name) {
		let result = this.actives.find((item) => item.name === name);
		return result;
	}

	existing(item) {
		let existingItem = this.actives.find((current) => {
			let isSame = current.base === item.base && item.base !== "undefined";
			if (isSame) {
				return current;
			} else {
				return;
			}
		});
		if (existingItem) {
			return existingItem;
		} else {
			return;
		}
	}

	push(item) {
		return add(newItem);
	}

	add(newItem) {
		let existingItem = this.existing(newItem);
		if (!existingItem) {
			this.array.push(newItem);
			return newItem;
		} else {
			let params = Array.from(arguments);
			params.splice(0, 1);
			if (params.length > 0) existingItem.update(...params);
			return existingItem;
		}
	}

	create() {
		let paras = [this, ...arguments];
		let newItem = new this.Item(...paras);
		return this.add(...[newItem, ...arguments]);
	}

	remove(item, consent) {
		// TODO
	}

	#checkCallback(cb, funcName) {
		if (typeof cb !== "function") {
			throw new Error(`.${funcName} expects a callback`);
		}
	}

	#checkItems(items, funcName) {
		if (!(items instanceof Items)) {
			throw new Error(`.${funcName} expects a items`);
		}
	}

	active(items) {
		if (!items) items = this.array;
		let filteredArr = items.filter((item) => !item.archived);
		const actives = new Items(this.#meta.ItemClass, this.#meta.basing, filteredArr);
		return actives;
	}

	get actives() {
		return this.active();
	}

	derive(items) {
		let input = items || this.actives;
		let result = new Items(this.#meta.ItemClass, this.#meta.basing, input);
		return result;
	}

	#lister(fnName, cb) {
		this.#checkCallback(cb, "lister");
		let actives = this.actives;
		let arr = actives.array;
		let fn = arr[fnName];
		// let result = fn(cb)
		let result = arr[fnName](cb);
		if (!result) return;
		return result;
	}

	first() {
		const result = this.actives.array[0];
		return result;
	}

	item(index) {
		const result = this.actives.array[index];
		return result;
	}

	get length() {
		const result = this.array.length;
		return result;
	}

	last() {
		const result = this.actives.array[actives.length - 1];
		return result;
	}

	find(cb) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.find(cb);
		// let found = this.#lister("find", cb)
		return found;
	}

	forEach(cb) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.forEach(cb);
		// let found = this.#lister("find", cb)
		return found;
	}

	filter(cb) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.filter(cb);
		// let found = this.#lister("find", cb)
		return found;
	}

	map(cb) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.map(cb);
		// let found = this.#lister("find", cb)
		return found;
	}

	sort(cb) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.sort(cb);
		// let found = this.#lister("find", cb)
		return found;
	}

	reduce(cb, initialValue) {
		let actives = this.actives;
		let arr = actives.array;
		let found = arr.reduce(cb, initialValue);
		// let found = this.#lister("find", cb)
		return found;
	}

	union(items) {
		this.#checkItems(items, "union");
		const result = [];
		this.actives.forEach((item) => result.push(item));
		items.forEach((item) => result.push(item));
		return derive(result);
	}

	intersect(items) {
		this.#checkItems(items, "intersect");
		const result = [];
		this.actives.forEach((item) => {
			if (items.has(item)) {
				result.push(item);
			}
		});
		return derive(result);
	}

	complement(items) {
		this.#checkItems(items, "complement");

		const result = [];
		this.actives.forEach((item) => {
			if (!items.has(item)) {
				result.push(item);
			}
		});
		return derive(result);
	}

	isSuperset(items) {
		this.#checkItems(items, "isSuperset");
		for (let elem of items) {
			if (!this.has(elem)) {
				return false;
			}
		}
		return true;
	}

	symmetricDifference(items) {
		this.#checkItems(items, "symmetricDifference");
		let result = new Set(this.actives);
		for (let elem of items) {
			if (result.has(elem)) {
				result.delete(elem);
			} else {
				result.push(elem);
			}
		}
		return derive(result);
	}

	difference(items) {
		this.#checkItems(items, "difference");
		let result = new Set(this.actives);
		for (let elem of items) {
			result.delete(elem);
		}
		return derive(result);
	}

	isSubsetOf(items) {
		this.#checkItems(items, "isSubsetOf");

		let count = 0;
		this.actives.forEach((item) => {
			if (items.has(item)) {
				count += 1;
			}
		});

		return count === this.size;
	}

	isSupersetOf(items) {
		this.#checkItems(items, "isSupersetOf");

		let count = 0;
		items.forEach((item) => {
			if (this.actives.has(item)) {
				count += 1;
			}
		});

		return count === items.size;
	}

	product(items, seprator = "") {
		this.#checkItems(items, "product");

		const result = [];
		this.forEach((item1) => {
			items.forEach((item2) => {
				result.push([item1, item2]);
			});
		});
		return derive(result);
	}

	power(m, seprator = "") {
		if (Number.isNaN(+m) || +m < 0) {
			throw new Error(".power expects a positive number");
		}

		if (+m === 0) return [];

		let result = this.clone();
		for (let i = 0; i < +m - 1; i += 1) {
			result = result.product(this.#meta.itemSet, seprator);
		}
		return derive(result);
	}

	permutations(m, separator = "") {
		if (Number.isNaN(+m) || +m < 0) {
			throw new Error(".permutations expects a positive number");
		}

		if (m > this.actives.size) {
			throw new Error(".permutations expects a number less or euqal items size");
		}

		const result = this.actives;

		const generatePermutation = (currentSet, i = 0, prefix = "") => {
			if (i === m && prefix.length > 0) {
				result.push(prefix);
				return;
			}

			currentSet.forEach((el) => {
				const nextSet = currentSet.clone();
				nextSet.delete(el);
				const acc = prefix.length ? `${prefix}${separator}${el}` : `${el}`;
				generatePermutation(nextSet, i + 1, acc);
			});
		};

		generatePermutation(this.clone());
		return derive(result);
	}

	equals(items) {
		this.#checkItems(items, "equals");

		return this.isSubsetOf(items) && this.size === items.size;
	}

	clone() {
		let result = this.actives;
		return result;
	}
}
